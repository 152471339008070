<template>
  <div v-if="error">
    <h3>Error Loading Events: {{ error }}</h3>
  </div>

  <section v-if="!error" class="player-section">
    <!-- <eventSectionTitle :title="headTitle" :description="headDescription" /> -->
    <!-- <div class="container"> -->
    <div class="row">
      <div class="col-lg-3">
        <div class="left-search-sec">
          <div class="search_bar-players">
            <input
              type="text"
              placeholder="Search.."
              class="form-control"
              v-model="searchField"
            />
            <button class="btn"><i class="fas fa-search"></i></button>
          </div>
          <h3 class="filter-title">Filter by</h3>
          <h5 class="filter-sub-title">Coach</h5>
          <div class="filter-search-list">
            <ul>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      value="Deemer Class"
                      v-model="selectedCoach"
                    />Deemer Class</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      value="Matt Dunn"
                      v-model="selectedCoach"
                    />Matt Dunn</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      value="Matt McMahon"
                      v-model="selectedCoach"
                    />Matt McMahon</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      value="Christian Cuccinello"
                      v-model="selectedCoach"
                    />Christian Cuccinello</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      value="Tim Muller"
                      v-model="selectedCoach"
                    />Tim Muller</label
                  >
                </div>
              </li>
            </ul>
          </div>

          <h5 class="filter-sub-title">Position</h5>
          <div class="filter-search-list">
            <ul>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      value="Offense"
                      v-model="selectedPosition"
                    />Offense</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      value="Defense"
                      v-model="selectedPosition"
                    />Defense</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      value="Footwork"
                      v-model="selectedPosition"
                    />Footwork</label
                  >
                </div>
              </li>
            </ul>
          </div>

          <h5 class="filter-sub-title">Gender Focus</h5>
          <div class="filter-search-list">
            <ul>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGender"
                      value="Boys"
                    />Boys</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGender"
                      value="Girls"
                    />Girls</label
                  >
                </div>
              </li>
            </ul>
          </div>

          <h5 class="filter-sub-title">Player Grade</h5>
          <div class="filter-search-list">
            <ul>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="4th"
                    />4th</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="5th"
                    />5th</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="6th"
                    />6th</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="7th"
                    />7th</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="8th"
                    />8th</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="9th"
                    />9th</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="10th"
                    />10th</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="11th"
                    />11th</label
                  >
                </div>
              </li>
              <li>
                <div class="checkbox">
                  <label
                    ><input
                      type="checkbox"
                      v-model="selectedGrade"
                      value="12th"
                    />12th</label
                  >
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-9">
        <div v-if="eventsDisplay.length == 0">
          <p>Sorry there are no training sessions matching those filters...</p>
        </div>
        <eventCard
          v-for="event in eventsDisplay"
          :key="event.id"
          :eventTitle="event.eventName"
          :price="event.eventPrice"
          :description="event.eventDescription"
          :startDate="event.startDate"
          :endDate="event.endDate"
          :address="event.eventAddress"
          :coaches="event.eventCoaches"
          :position="event.eventPosition"
          :gender="event.genderFocus"
          :time="event.eventTime"
          :ages="event.eventAges"
          :id="event.id"
        />
      </div>
    </div>
    <!-- </div> -->
  </section>
  <div class="clearfix"></div>
</template>

<script>
import getEvents from "../composables/getEvents";
import eventCard from "../components/eventCard.vue";
import eventSectionTitle from "../components/eventSectionTitle";
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { computed, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  components: { eventCard, eventSectionTitle },
  setup() {
    //Get Current Events
    const route = useRoute();

    const { events, error, load } = getEvents("liveReps");
    load();

    const searchField = ref("");
    const selectedGrade = ref([]);
    const selectedCoach = ref([]);
    const selectedPosition = ref([]);
    const selectedGender = ref([]);
    const today = new Date();
    const headTitle = ref("IN PERSON TRAINING");
    const headDescription = ref(
      "FCL in person trainings are an incredible opportunity to get on the field with our high level coaching stuff. Players will receive personalized feedback, learn in a small group environment and build relationships with our staff. Walk away with key concepts you can immediately apply to your game, and continue to to develop and grow under our staff’s guidance."
    );
    const eventsDisplay = computed(() => {
      if (events.value.length > 0) {
        for (let k = 0; k < events.value.length; k++) {
          const tempDate = Date.parse(events.value[k].endDate);
          if (tempDate < today) {
            console.log(events.value[k].eventName);
          }
        }

        //if there are no filters return full list
        if (
          searchField.value == "" &&
          selectedGender.value.length +
            selectedCoach.value.length +
            selectedPosition.value.length +
            selectedGrade.value.length ==
            0
        ) {
          return events.value;
        }
        let tempArray = events.value;

        //Check for Gender
        if (selectedGender.value.length > 0) {
          tempArray = tempArray.filter(function (blg) {
            for (let i = 0; i < selectedGender.value.length; i++) {
              if (blg.genderFocus == selectedGender.value[i]) {
                return true;
              }
            }
            return false;
          });
        }

        //Check for position
        if (selectedPosition.value.length > 0) {
          tempArray = tempArray.filter(function (blg) {
            for (let i = 0; i < selectedPosition.value.length; i++) {
              if (blg.eventPosition == selectedPosition.value[i]) {
                return true;
              }
            }
            return false;
          });
        }

        //Check for age
        if (selectedGrade.value.length > 0) {
          console.log("KURT");
          console.log(selectedGrade.value);
          tempArray = tempArray.filter(function (blg) {
            for (let i = 0; i < selectedGrade.value.length; i++) {
              if (blg.eventAges.includes(selectedGrade.value[i])) {
                return true;
              }
            }
            return false;
          });
        }

        //Check for Coach
        if (selectedCoach.value.length > 0) {
          tempArray = tempArray.filter(function (blg) {
            for (let i = 0; i < selectedCoach.value.length; i++) {
              if (blg.eventCoaches.includes(selectedCoach.value[i])) {
                return true;
              }
            }
            return false;
          });
        }

        watch(curVidType, () => {});
        tempArray = tempArray.filter(function (event) {
          if (
            event.eventName
              .toLowerCase()
              .includes(searchField.value.toLowerCase()) ||
            event.eventDescription
              .toLowerCase()
              .includes(searchField.value.toLowerCase())
          ) {
            return true;
          }
          return false;
        });
        return tempArray;
      }
      return [];
    });

    return {
      error,
      events,
      searchField,
      eventsDisplay,
      selectedCoach,
      selectedPosition,
      selectedGender,
      selectedGrade,
      headTitle,
      headDescription,
    };
  },
};
</script>

<style scoped>
.player-section {
  min-height: 322px;
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px 30px;
}

.player-text {
  max-width: 1000px;
  margin: 0 auto;
}

.player-text h5 {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.player-text h3 {
  font-family: "Hemi Head Bold Italic";
  font-size: 64px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #db3a34;
  margin: 0 0 8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.player-text p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin: 0 0 20px;
  position: relative;
}

.clearfix {
  clear: both;
}

.plan-box-outer {
  width: 100%;
  float: left;
  background: #fff;
  display: flex;
  margin: 10px 0;
}

.plan-left-title {
  width: 260px;
  background: rgba(0, 0, 0, 1);
  padding: 20px 10px;
  display: flex;
  flex-flow: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/img/cbb.jpg");
}

.plan-left-title h4 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 2px;
  position: relative;
  z-index: 9;
  /* word-break: break-all !important; */
}

.plan-right-disciption {
  width: 100%;
  display: flex;
}

.plan-detail {
  padding: 15px 15px;
}

.plan-detail-top p {
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.plan-detail-top {
  width: 100%;
  float: left;
  margin: 0 0 18px;
}

.plan-timing-list {
  width: 100%;
  float: left;
}

.plan-timing-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.plan-timing-list ul li {
  list-style: none;
  margin: 0;
  padding: 10px 25px 10px 0;
  width: 50%;
  text-align: left;
}

.plan-timing-list ul li .playermiddle-text {
  width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  text-align: left;
  padding: 0px 5px 0px 37px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.plan-timing-list ul li .playermiddle-text span {
  position: absolute;
  font-size: 17px;
  top: -1px;
  left: 12px;
  color: #000000;
}

.light-grey {
  background: #fafafa;
}

.plan-price-btn {
  background: #db3a34;
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 3px 15px;
  text-align: unset;
  flex-flow: column;
  width: 360px;
}

.player-price-in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.player-curency {
  font-family: "Montserrat", sans-serif;
  align-self: flex-start;
  font-size: 17px;
  color: #f7f7f7;
  font-weight: 700;
  margin: 0;
}

.player-pack-price {
  font-family: "Montserrat", sans-serif;
  color: #f7f7f7;
  font-size: 44px;
  font-weight: 700;
  margin: 0;
  line-height: 40px;
  padding: 0 0 0 6px;
}

.player-pack-timing {
  color: #f7f7f7;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 22px 0 0 6px;
  padding: 0;
}

.register-pack-btn {
  width: 100%;
  float: left;
  padding: 25px 0 0;
}

.register-slct-btn {
  padding: 10px 15px !important;
  background-color: #326fb5 !important;
  color: #fff !important;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem !important;
  background-color: rgba(196, 181, 131, 0);

  border-radius: 100px 100px 100px 100px !important;
  text-align: center;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 155px;
}

.register-slct-btn:hover {
  border-color: #fff !important;

  color: #ffffff !important;
  background-color: #db3a34 !important;
  transform: scale(1.1) !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
}

.left-search-sec {
  width: 100%;
  background: #fff;
  margin: 10px 0 0;
  height: 98%;
  padding: 20px 15px;
}

.search_bar-players {
  width: 100%;
  float: left;
  position: relative;
}

.search_bar-players .form-control {
  border: 1px solid #c4c4c4;
  background: #fafafa;
  border-radius: 0;
  padding: 9px 14px;
  font-size: 13px;
}

.search_bar-players .btn {
  position: absolute;
  right: 0;
  top: 0;
  background: #db3a34;
  border-radius: 0;
  color: #fff;
}

.filter-title {
  width: 100%;
  float: left;
  text-align: left;
  margin: 30px 0 5px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 0 10px;
}

.filter-sub-title {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin: 18px 0 10px;
  width: 100%;
  float: left;
}

.filter-search-list {
  width: 100%;
  float: left;
}

.filter-search-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.filter-search-list ul li {
  list-style: none;
  margin: 0;
  padding: 0 0 8px 0;
  width: 100%;
  float: left;
}

.filter-search-list ul li .checkbox {
  width: 100%;
  float: left;
  text-align: left;
}

.filter-search-list ul li label {
  position: relative;
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  padding: 0 0 0 20px;
}

.filter-search-list ul li .checkbox input {
  margin: 0;
  position: absolute;
  left: 0;
  top: 3.8px;
}

@media (max-width: 1200px) {
  .plan-left-title h4 {
    font-size: 17px;
  }

  .player-pack-timing {
    margin: 0px 0 0 6px;
  }

  .register-slct-btn {
    min-width: 120px;
  }

  .plan-detail-top p {
    font-size: 12px;
    line-height: 15px;
  }

  .plan-timing-list ul li .playermiddle-text {
    font-size: 11px;
    line-height: 21px;
  }

  .plan-timing-list ul li {
    padding: 10px 8px 10px 0;
  }
}

@media (max-width: 992px) {
  .left-search-sec {
    height: auto;
    float: left;
  }
}

@media (max-width: 767px) {
  .plan-box-outer {
    display: inline-block;
  }

  .plan-left-title {
    width: 100%;
  }

  .plan-right-disciption {
    width: 100%;
    display: inline-block;
  }

  .plan-price-btn {
    width: 100%;
    padding: 10px 10px;
  }

  .register-pack-btn {
    padding: 10px 0 0;
  }

  .register-slct-btn {
    min-width: 140px;
  }
}

@media (max-width: 450px) {
  .plan-timing-list ul li {
    width: 100%;
  }

  .light-grey {
    background: transparent;
  }

  .plan-timing-list ul li:nth-child(2n + 1) {
    background: #fafafa;
  }
}
</style>