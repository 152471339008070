<template>
  <loadingSpinner v-if="isLoading" />
  <div v-if="errorUp" class="alert alert-warning" role="alert">
    Error Updating that: {{ errorUp }}
  </div>
  <div v-if="userLoaded">
    <div class="row" v-if="userData.usrType != 'program'">
      <!-- Profile Information Start -->
      <div class="col-12 col-xl-8 mb-4">
        <div class="card card-body bg-white border-light shadow-sm mb-4">
          <h2 class="h5 mb-4">Account Owner Information</h2>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div>
                <label for="first_name">First Name</label>
                <input
                  class="form-control"
                  id="first_name"
                  type="text"
                  placeholder="First Name"
                  v-model="accFirstName"
                  required
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div>
                <label for="last_name">Last Name</label>
                <input
                  class="form-control"
                  id="last_name"
                  type="text"
                  placeholder="Last Name"
                  v-model="acclastName"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6 mb-3">
              <label for="birthday">Birthday</label>
              <div class="input-group">
                <span class="input-group-text"
                  ><span class="far fa-calendar-alt"></span
                ></span>
                <input
                  data-datepicker=""
                  class="form-control"
                  id="birthday"
                  type="text"
                  placeholder="dd/mm/yyyy"
                  v-model="accBirthday"
                  required
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="gender">Gender Focus</label>
              <select
                class="form-select mb-0"
                id="gender"
                aria-label="Gender select example"
                v-model="accGenderF"
              >
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  class="form-control"
                  id="email"
                  type="email"
                  placeholder="name@company.com"
                  v-model="accEmail"
                  disabled
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-group">
                <label for="phone">Phone</label>
                <input
                  class="form-control"
                  id="phone"
                  type="tel"
                  placeholder="xxx-xxx-xxxx"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  v-model="accPhone"
                />
              </div>
            </div>
            <button
              class="btn btn-primary btn-dark mt-2 animate-up-2"
              @click="updateAccInfo"
            >
              Update Account Information
            </button>
          </div>
        </div>
      </div>
      <!-- Profile Information End  -->

      <!-- FCL Online Subscription Start -->
      <div class="col-12 col-xl-4 mb-4">
        <div class="card border-light shadow-sm text-center py-4 mb-4 mb-lg-0">
          <div class="card-body">
            <h2 class="display-4 mb-3">FCL Online Subscription</h2>
            <p v-if="userData.usrType == 'pm'">
              As a program manager you automatically have access to FCL Online
              there is no reason to to subscribe individually.
            </p>
            <p v-if="isPremium && !(userData.usrType == 'pm')">
              Switch your subscription to a different type, cancel, and view
              billing history. Also see a list of subscription plans that FCL
              Offers.
            </p>
            <p v-if="!isPremium && !(userData.usrType == 'pm')">
              FCL Online is your one-stop resource for every aspect of lacrosse
              improvement. With over 100 videos breaking down techniques,
              schemes, concepts and drills in lacrosse, FCL Online is perfect
              for anyone in the lacrosse world - education for beginners through
              experts
            </p>
            <router-link
              to="/videos/trainingvideos/offense"
              tag="button"
              class="btn btn-sm btn-dark mr-2"
              v-if="!isPremium && !(userData.usrType == 'pm')"
            >
              Subscribe to FCL Online
            </router-link>
            <button
              v-if="isPremium && !(userData.usrType == 'pm')"
              class="btn btn-sm btn-dark mr-2"
              @click="cancelSub"
            >
              Cancel Subscription
            </button>
          </div>
        </div>
        <!-- Join a Program -->
        <br />
        <div class="card border-light shadow-sm">
          <div class="card-header border-bottom border-light">
            <h2 class="h5 mb-0" v-if="userData.usrType != 'program-player'">
              Join a Program
            </h2>
            <h2 class="h5 mb-0" v-if="userData.usrType == 'program-player'">
              Switch Programs
            </h2>
          </div>
          <div class="card-body">
            <div class="col-md-12 mb-3">
              <div v-if="userData.usrType == 'pm'">
                <p>
                  As a program manager you do not need to request access to your
                  own program. You are a member by default.
                </p>
              </div>
              <div class="form-group" v-if="userData.usrType != 'pm'">
                <p v-if="userData.usrType == 'program-player'">
                  You are already a member of a program. You can switch programs
                  by requesting access to that one below and if approved your
                  account will be switched. You cannot be a member of two
                  programs.
                </p>
                <label for="phone">Program Access Token</label>
                <input
                  class="form-control"
                  id="phone"
                  type="tel"
                  placeholder="Enter Access Token..."
                  v-model="accessToken"
                />
                <button
                  class="btn btn-primary btn-dark mt-2 animate-up-2 center"
                  @click="joinProgram"
                >
                  Request Access To Program
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- FCL Online Subsctiption Information End -->
      <!-- Add event registration profile Start -->
      <div class="col-12 col-xl-8 mb-4">
        <div class="card card-body bg-white border-light shadow-sm mb-4">
          <h2 class="h5 mb-4">Add Event Registration Profile</h2>
          <p>
            Event registration profiles provide an easy way for you to checkout
            multiple players for events within one account. Add profiles here
            and then quickly register any of your saved profiles for our events.
          </p>
          <form @submit.prevent="addSubProfile" class="mt-4">
            <div class="row">
              <div class="col-md-6 mb-3">
                <div>
                  <label for="first_name">First Name *</label>
                  <input
                    class="form-control"
                    id="pfirst_name"
                    type="text"
                    placeholder="John"
                    required
                    v-model="firstNameProf"
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div>
                  <label for="last_name">Last Name *</label>
                  <input
                    class="form-control"
                    id="plast_name"
                    type="text"
                    placeholder="Smith"
                    v-model="lastNameProf"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <label for="email">Player Email *</label>
                  <input
                    class="form-control"
                    id="pemail"
                    type="email"
                    placeholder="name@company.com"
                    v-model="emailProf"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <label for="phone">Player Phone *</label>
                  <input
                    class="form-control"
                    id="phone"
                    type="tel"
                    placeholder="xxx-xxx-xxxx"
                    v-model="phoneProf"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <label for="email">Emergency Contact Name *</label>
                  <input
                    class="form-control"
                    id="emergencyName"
                    type="text"
                    placeholder="John Smith"
                    v-model="emergencyName"
                    required
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div class="form-group">
                  <label for="phone">Emergency Contact Phone *</label>
                  <input
                    class="form-control"
                    id="emergencyPhone"
                    type="tel"
                    placeholder="xxx-xxx-xxxx"
                    v-model="emergencyPhone"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-6 mb-3">
                <label for="gender">Graduation Year *</label>
                <select
                  class="form-select mb-0"
                  id="gradYear"
                  v-model="gradYearProf"
                  required
                >
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                  <option value="2028">2028</option>
                  <option value="2029">2029</option>
                  <option value="2030">2030</option>
                  <option value="2031">2031</option>
                </select>
              </div>
              <div class="col-md-6 mb-3">
                <label for="gender">Gender Focus *</label>
                <select
                  class="form-select mb-0"
                  id="gender"
                  aria-label="Gender select example"
                  required
                  v-model="genderProf"
                >
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                </select>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-6 mb-3">
                <label for="gender">State *</label>
                <select
                  class="form-select mb-0"
                  id="state"
                  aria-label="Gender select example"
                  v-model="stateProf"
                  required
                >
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
              <div class="col-md-6 mb-3">
                <div>
                  <label for="first_name">City Name *</label>
                  <input
                    class="form-control"
                    id="city_name"
                    type="text"
                    placeholder="New York"
                    v-model="cityProf"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="gender">Player Position *</label>
                <select
                  class="form-select mb-0"
                  id="position"
                  aria-label="Gender select example"
                  required
                  v-model="positionProf"
                >
                  <option value="Attack">Attack</option>
                  <option value="Defense">Defense</option>
                  <option value="Face Off">Face Off</option>
                  <option value="Goalie">Goalie</option>
                  <option value="Long Stick Midfielder">
                    Long Stick Midfielder
                  </option>
                  <option value="Midfield">Midfield</option>
                </select>
              </div>
              <div class="col-md-6 mb-3">
                <div>
                  <label for="first_name">Player School Name *</label>
                  <input
                    class="form-control"
                    id="school_name"
                    type="text"
                    placeholder="Briarcliff Middle School"
                    v-model="schoolProf"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-md-6 mb-3">
                <label for="gender">T Shirt Size *</label>
                <select
                  class="form-select mb-0"
                  id="tshirtsize"
                  v-model="tshirtSizeProf"
                  required
                >
                  <option value="XS">XS</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                </select>
              </div>
              <div class="col-md-6 mb-3">
                <label for="gender">Shorts Size *</label>
                <select
                  class="form-select mb-0"
                  id="shortSize"
                  required
                  v-model="shortSizeProf"
                >
                  <option value="XS">XS</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-3">
                <div>
                  <label for="first_name">Player Club Program Name</label>
                  <input
                    class="form-control"
                    id="program_name"
                    type="text"
                    placeholder="FCL Club"
                    v-model="clubProf"
                  />
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <div>
                  <label for="last_name">Instagram Handle</label>
                  <input
                    class="form-control"
                    id="insta"
                    type="text"
                    placeholder="@22Classy"
                    v-model="instaProf"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mb-3">
                <div class="form-group">
                  <label for="address">Highlight Video Link</label>
                  <input
                    class="form-control"
                    id="address"
                    type="text"
                    placeholder="https://www.youtube.com/"
                    v-model="highlightVideoProf"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <button
                class="btn btn-primary btn-dark mt-2 animate-up-2"
                type="submit"
              >
                Add Player Profile
              </button>
            </div>
          </form>
        </div>
      </div>
      <!-- Add Event Registration Profile End -->
      <!-- FCL Online Subscription Start -->
      <div class="col-12 col-xl-4 mb-4">
        <div class="card border-light shadow-sm">
          <div class="card-header border-bottom border-light">
            <h2 class="h5 mb-0">Your Player Event Registration Profiles</h2>
          </div>
          <div class="card-body">
            <ul class="list-group list-group-flush list my--3">
              <div v-if="regProfs">
                <li
                  class="list-group-item px-0"
                  v-for="profile in regProfs"
                  :key="profile"
                >
                  <div class="row align-items-center">
                    <div class="col ml--2">
                      <h4 class="h6 mb-0">
                        <a>{{ profile.firstName }} {{ profile.lastName }}</a>
                      </h4>
                      <small>{{ profile.position }}</small>
                    </div>
                    <div class="col-auto">
                      <!-- <button type="button" class="btn btn-sm btn-primary">
                      Edit
                    </button> -->
                    </div>
                  </div>
                </li>
              </div>
              <div v-if="!regProfs">
                You do not have any registration profiles yet...
              </div>
            </ul>
          </div>
        </div>
      </div>
      <!-- FCL Online Subsctiption Information End -->
      <!-- FCL Online Subscription Start -->
      <!-- FCL Online Subsctiption Information End -->
      <!-- {{ posts }} -->
    </div>
    <div v-if="userData.usrType == 'program'">
      <h3>Access Denied</h3>
      <p>
        You are logged in as a program. You can't change profile data from this
        screen. Please contact us to change profile data for a program. Please
        log out and log in as an individual account to edit profile data on this
        screen.
      </p>
    </div>
  </div>
</template>

<script>
import getDocument from "../../composables/getDocument";
import { computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import loadingSpinner from "../../components/loadingSpinner.vue";
import { firebaseFunctions } from "../../firebase/config";
import updateCollection from "../../composables/updateDoc";
import useCollection from "../../composables/useCollectionSubProf";
import getData from "../../composables/getData";
import axios from "axios";
import { useRoute } from "vue-router";

export default {
  components: { loadingSpinner },

  setup() {
    const route = useRoute();

    const store = useStore();
    const programID = route.params.id;
    console.log(programID);
    const userSubData = computed(() => store.state.subscriptions);
    let checkPrem = ref(null);
    const isLoading = ref(false);
    const { errorUp, updateDoc, isPending } = updateCollection("fclUsers");
    const { error2, addDoc, isPending2 } = useCollection();
    const regProfs = ref("");

    //user data
    const userLoaded = computed(() => store.state.userDataLoaded);
    const userData = computed(() => store.state.userData);

    // other
    const accessToken = ref("");

    //account settings
    const accFirstName = ref("");
    const acclastName = ref("");
    const accBirthday = ref("");
    const accGenderF = ref("");
    const accEmail = ref("");
    const accPhone = ref("");

    //Player event profile variables
    const firstNameProf = ref("");
    const lastNameProf = ref("");
    const emailProf = ref("");
    const phoneProf = ref("");
    const gradYearProf = ref("");
    const genderProf = ref("");
    const stateProf = ref("");
    const tshirtSizeProf = ref("");
    const shortSizeProf = ref("");
    const cityProf = ref("");
    const positionProf = ref("");
    const schoolProf = ref("");
    const clubProf = ref("");
    const instaProf = ref("");
    const highlightVideoProf = ref("");
    const emergencyName = ref("");
    const emergencyPhone = ref("");

    let sessionID = "";
    let stripe = null;
    let functionURL = null;
    if (process.env.VUE_APP_ENV == "test") {
      stripe = Stripe(
        "pk_test_51IqpudINbM8Ghj39JofFXCMtsRwKaDSCT8oIENe7GfBM2W0snnzbJgVtEShhL4KnRtwfcHSgzTBhd5m4WfzfhYvy00XEXWd9H1"
      );
      functionURL =
        "https://us-central1-fcl-online-dev.cloudfunctions.net/programAccessRequest";
    } else {
      stripe = Stripe(
        "pk_live_51IqpudINbM8Ghj39Bg3LwWn3JyCEKocMuGysh8I9iewXNPRrz7NbtH7ZsG2K3gpC5Su7o3AtbHpH4vIoVOcRYKtn00xPMXB0AP"
      );
      functionURL =
        "https://us-central1-first-class-lax-c0894.cloudfunctions.net/programAccessRequest";
    }
    const isPremium = computed(() => {
      if (checkPrem.value == null) {
        return false;
      } else {
        return checkPrem.value;
      }
    });
    const addRegProf = () => {};

    const cancelSub = async () => {
      isLoading.value = true;
      const functionRef = firebaseFunctions.httpsCallable(
        "ext-firestore-stripe-subscriptions-createPortalLink"
      );
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url);
      isLoading.value = false;
    };

    const updateAccInfo = async () => {
      if (userData.value.id) {
        if (accFirstName.value == "") {
          alert("A first Name Must Be Entered.");
          return;
        }
        if (acclastName.value == "") {
          alert("A last Name Must Be Entered.");
          return;
        }
        if (accGenderF.value == "") {
          alert("A gender focus must be selected.");
          return;
        }

        if (accPhone.value == "") {
          alert("A phone number must be entered.");
          return;
        }
        if (accBirthday.value == "") {
          alert("A birthday must be entered.");
          return;
        }
        isLoading.value = true;
        const accObj = {
          birthday: accBirthday.value,
          phoneNum: accPhone.value,
          firstName: accFirstName.value,
          lastName: acclastName.value,
          gender: accGenderF.value,
        };
        await updateDoc(userData.value.id, accObj);
        isLoading.value = false;
        if (!errorUp.value) {
          window.location.reload();
          alert("SUCCESS! Your account info has been updated.");
        }
      } else {
        alert("Error updating profile. Please refresh the page and try again.");
      }
    };

    const addSubProfile = async () => {
      if (firstNameProf.value == "") {
        alert("Please Enter a First Name");
        return;
      }
      if (lastNameProf.value == "") {
        alert("Please Enter a Last Name");
        return;
      }
      if (emailProf.value == "") {
        alert("Please Enter an Email");
        return;
      }
      if (emergencyName.value == "") {
        alert("An Emergency contact Name Must be added.");
        return;
      }
      if (emergencyPhone.value == "") {
        alert("An Emergency contact Name Must be added.");
        return;
      }
      const subProf = {
        firstName: firstNameProf.value,
        lastName: lastNameProf.value,
        email: emailProf.value,
        phoneNum: phoneProf.value,
        gradYear: gradYearProf.value,
        genderFocus: genderProf.value,
        state: stateProf.value,
        city: cityProf.value,
        position: positionProf.value,
        tshirtSize: tshirtSizeProf.value,
        shortSize: shortSizeProf.value,
        schoolName: schoolProf.value,
        clubName: clubProf.value,
        instaHandle: instaProf.value,
        highlightVideoLink: highlightVideoProf.value,
        emergencyName: emergencyName.value,
        emergencyPhone: emergencyPhone.value,
        accountLevelEmail: userData.value.email,
        accountLevelUID: userData.value.id,
      };
      isLoading.value = isPending2.value;
      await addDoc(userData.value.id, subProf);
      isLoading.value = isPending2.value;
      if (!error2.value) {
        window.location.reload();
        alert("User Registration Profile Has Been Added!");
      } else {
        isLoading.value = isPending2.value;
        console.log(error2.value);
      }
      isLoading.value = isPending2.value;
    };
    let ifChecked = false;

    const joinProgram = () => {
      console.log("THIS: " + userData.value.stripeId);
      if (!(accessToken.value == "")) {
        isLoading.value = true;
        const tokenToSend = accessToken.value.replace(/\s+/g, "");
        axios
          .get(functionURL, {
            params: {
              program: tokenToSend,
              user: userData.value.id,
              firstName: userData.value.firstName,
              lastName: userData.value.lastName,
              email: userData.value.email,
              id: userData.value.stripeId,
              item: "price_1IqrPTINbM8Ghj39qSrR8gcc",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data != false) {
              //if program is a connect program
              if (typeof response.data.id != "undefined") {
                sessionID = response.data.id;
                stripe
                  .redirectToCheckout({
                    sessionId: sessionID,
                  })
                  .then(function (result) {
                    isLoading.value = false;
                    if (result.error) {
                      alert(result.error);
                    }
                  });
              }
              //else send a request to join a pre-paid program
              else {
                alert(
                  "Success! A notification to the owner of that program has been sent for approval."
                );
              }
              accessToken.value = "";
            } else if (response.data == false) {
              alert(
                "Either the program does not exist or a different error occured. Please try again and if problem persists please email kurt@1stclasslax.com for help."
              );
            } else {
              alert(response.data);
            }
          })
          .catch((error) => {
            console.error("Error registering for program: " + error);
          });

        accessToken.value = "";
        isLoading.value = false;
      } else {
        alert("Please enter an access token");
      }
    };

    watchEffect(() => {
      if (userLoaded.value) {
        if (typeof programID != "undefined" && ifChecked == false) {
          if (typeof userData.value.stripeId != "undefined") {
            console.log("Stripe ID: " + userData.value.stripeId);
            ifChecked = true;
            accessToken.value = programID;

            joinProgram();
          } else {
            if (
              confirm(
                "Account is still propagating. This should only take a few seconds. Refresh page? If it has been more than 60 seconds since you created your FCL Online account and are still seeing this please email kurt@1stclasslax.com for support."
              ) == true
            ) {
              location.reload();
            }
          }
        } else {
          // present profile data
          const coll =
            "fclUsers/" + userData.value.id + "/registrationProfiles";
          const { posts, error, load } = getData(coll);
          load().then(() => {
            regProfs.value = posts.value;
          });
          accFirstName.value = userData.value.firstName;
          acclastName.value = userData.value.lastName;
          accEmail.value = userData.value.email;
          accGenderF.value = userData.value.gender;
          if (userData.value.birthday) {
            accBirthday.value = userData.value.birthday;
          }
          if (userData.value.phoneNum) {
            accPhone.value = userData.value.phoneNum;
          }
          //Update FCL Online status
          for (let i = 0; i < userSubData.value.length; i++) {
            if (
              userSubData.value[i].status == "active" ||
              userSubData.value[i].status == "trialing"
            ) {
              checkPrem.value = true;
            }
          }
        }
      }
    });
    return {
      cancelSub,
      isPremium,
      isLoading,
      firstNameProf,
      lastNameProf,
      emailProf,
      phoneProf,
      gradYearProf,
      genderProf,
      stateProf,
      cityProf,
      positionProf,
      schoolProf,
      clubProf,
      instaProf,
      highlightVideoProf,
      addRegProf,
      accFirstName,
      acclastName,
      accBirthday,
      accGenderF,
      accEmail,
      accPhone,
      updateAccInfo,
      errorUp,
      addSubProfile,
      tshirtSizeProf,
      shortSizeProf,
      regProfs,
      userData,
      userLoaded,
      accessToken,
      joinProgram,
      emergencyName,
      emergencyPhone,
    };
  },
};
</script>

<style>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
</style>