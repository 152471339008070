<template>
  <loadingSpinner v-if="isLoading" />

  <div class="freeTrialBack">
    <div class="trial-text">
      <h5>- Special limited time offer -</h5>

      <h3>FCL Online</h3>
      <h3>7 Day Free Trial</h3>
      <h5><a @click="payCheckout"> - Click to Activate - </a></h5>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/reactivity";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import loadingSpinner from "../components/loadingSpinner.vue";

export default {
  components: { loadingSpinner },
  setup() {
    const store = useStore();
    const userData = computed(() => store.state.userData);
    const isLoading = ref(false);
    let sessionID = "";

      const stripe = Stripe(
        "pk_live_51IqpudINbM8Ghj39Bg3LwWn3JyCEKocMuGysh8I9iewXNPRrz7NbtH7ZsG2K3gpC5Su7o3AtbHpH4vIoVOcRYKtn00xPMXB0AP"
      );
    
    //price_1IqrGhINbM8Ghj39eIhpgM4I
    const payCheckout = (type) => {
      if (userData.value.stripeId != null) {
        isLoading.value = true;
        axios
          .get(
            "https://us-central1-first-class-lax-c0894.cloudfunctions.net/getSubscriptionTrial",
            { params: { id: userData.value.stripeId } }
          )
          .then((response) => {
            sessionID = response.data.id;
            stripe
              .redirectToCheckout({
                sessionId: sessionID,
              })
              .then(function (result) {
                isLoading.value = false;
                if (result.error) {
                  alert(result.error);
                }
              });
          })
          .catch((error) => {
            console.log(error);
            isLoading.value = false;
          });
      } else {
        alert(
          "Error: User Missing Stripe ID. Please contact Support at contact@1stclasslax.com"
        );
      }
    };
    return { payCheckout, isLoading };
  },
};
</script>

<style>
.freeTrialBack {
  background-color: #23252a;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.pricing-text {
  max-width: 1000px;
  margin: 0 auto;
}

.trial-text h5 {
  letter-spacing: 5px;

  font-family: "redwingMedium";
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.trial-text h3 {
  font-family: "hemi";
  font-size: 64px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #db3a34;
  margin: 0 0 8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}
</style>