<template>
  <eventSectionTitle
    title="Welcome to the New Home of FCL Courses"
    description="FCL now offers digital courses designed to walk you through important concepts and skillsets that you can easily absorb and add to your arsenal. We'll be collaborating with some of the biggest experts in the sport of lacrosse to deliver their insights to the FCL Community. Dive in and follow along as we take you through the skills and schemes that will make you a better player or coach."
  />
  <loadingSpinner v-if="!pageLoaded || isLoading" />

  <div v-if="!isPremium">
    <freeTrial />
  </div>
  <div class="table-responsive py-4" v-if="pageLoaded">
    <div class="row pb-5 mb-4 fourCoachdiv">
      <div
        class="col-lg-3 col-md-6 mb-4 mb-lg-0 padBot courseHeight"
        v-for="course in checkPublish(eventLoad)"
        :key="eventLoad.id"
      >
        <!-- Card-->
        <div class="card shadow-sm border-0 rounded">
          <div class="card-body kbc2 p-0">
            <div class="p-4">
              <h5 class="mb-0">
                <router-link
                  :to="'/fcllive/event/' + course.id"
                  tag="h5"
                  class="mb-0"
                >
                  {{ course.courseName }}
                </router-link>
              </h5>
              <p class="small text-muted">
                Tags:
                <span v-for="(tag, index) in course.courseTags" :key="tag">
                  {{ tag
                  }}<span v-if="index < course.courseTags.length - 1">, </span>
                </span>
              </p>

              <div class="plan-right-disciption">
                <div class="plan-detail">
                  <div class="plan-detail-top">
                    <p>
                      {{ course.courseDescription }}
                    </p>
                  </div>
                  <div class="plan-timing-list">
                    <ul>
                      <li class="light-grey">
                        <p
                          class="playermiddle-text"
                          v-if="course.coursePrice == 0"
                        >
                          <span><i class="fas fa-dollar-sign"></i></span>
                          Free For FCL Online Members
                        </p>
                        <p class="playermiddle-text" v-else>
                          <span><i class="fas fa-dollar-sign"></i></span>
                          {{ course.coursePrice }}
                        </p>
                      </li>
                      <li>
                        <p class="playermiddle-text">
                          <span><i class="far fa-clock"></i></span>
                          {{ course.courseDuration }} Weeks
                        </p>
                      </li>

                      <li>
                        <p class="playermiddle-text">
                          <span><i class="fas fa-bullseye"></i></span>
                          Position: {{ course.coursePosition }}
                        </p>
                      </li>
                      <li class="light-grey">
                        <p class="playermiddle-text">
                          <span><i class="fas fa-child"></i></span>
                          Gender Focus: {{ course.courseGender }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <ul class="social mb-0 list-inline mt-3">
                <li class="list-inline-item m-0"></li>
              </ul>
              <!-- Customer Owns The Course  -->
              <div v-if="customerOwns(course.id)">
                <router-link
                  :to="'/courses/' + course.id"
                  tag="button"
                  class="btn-primary btn btnPlayBot"
                >
                  <i class="far fa-play-circle"></i> Go To Course
                </router-link>
              </div>
              <!-- Customer Does NOT Own The Course  -->
              <div v-else>
                <!-- Course is Free -->
                <div v-if="course.coursePrice == 0">
                  <!-- User is  premium -->
                  <button
                    v-if="isPremium"
                    @click="payCheckoutFree(course.id)"
                    tag="button"
                    class="btn-primary btn btnPlayBot"
                  >
                    Start FCL Online Member Only Course
                  </button>
                  <!-- User is NOT premium -->
                  <button
                    v-if="!isPremium"
                    @click="payCheckout(course.id)"
                    tag="button"
                    disabled
                    class="btn-primary btn btnPlayBot"
                  >
                    Course Only For FCL Online Members
                  </button>
                </div>
                <!-- Course Costs Money -->
                <div v-else>
                  <button
                    @click="payCheckout(course.id)"
                    tag="button"
                    class="btn-primary btn btnPlayBot"
                  >
                    Purchase Course
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Premium Walls
import freeTrial from "../../components/freeTrialOffer.vue";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import pricing from "../../components/pricing";
import eventSectionTitle from "../../components/eventSectionTitle";
import eventCard from "../../components/liveEventCard.vue";
import { computed, ref, watch, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import axios from "axios";
import loadingSpinner from "../../components/loadingSpinner.vue";
export default {
  components: {
    pricing,
    sectionTitle,
    whyFCL,
    freeTrial,
    eventSectionTitle,
    eventCard,
    loadingSpinner,
  },
  setup() {
    const isLoading = ref(false);

    const store = useStore();
    const route = useRoute();
    const isPremium = computed(() => {
      return store.getters.getIsPremium;
    });

    const eventsLoaded = computed(() => store.state.fclCoursesDataLoaded);
    const eventLoad = computed(() => store.state.fclCoursesData);
    const formatAMPM = (date) => {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    };

    const userData = computed(() => store.state.userData);

    //const courses = computed(() => store.state.purchasedCoursesData);
    const coursesLoaded = computed(
      () => store.state.purchasedCoursesDataLoaded
    );
    const coursesUserData = computed(() => store.state.purchasedCoursesData);
    const pageLoaded = computed(() => {
      return coursesLoaded.value && eventsLoaded.value;
    });

    let stripe = null;
    let functionURL = null;
    const customerOwns = (c) => {
      let found = false;
      console.log(c);
      coursesUserData.value.forEach((course) => {
        console.log(course.id);
        if (course.id == c) {
          found = true;
        }
      });
      return found;
    };
    stripe = Stripe(
      "pk_live_51IqpudINbM8Ghj39Bg3LwWn3JyCEKocMuGysh8I9iewXNPRrz7NbtH7ZsG2K3gpC5Su7o3AtbHpH4vIoVOcRYKtn00xPMXB0AP"
    );
    functionURL =
      "https://us-central1-first-class-lax-c0894.cloudfunctions.net/CheckoutCourse";

    let sessionID = "";
    const payCheckoutFree = (docID) => {
      isLoading.value = true;
      axios
        .get(
          "https://us-central1-first-class-lax-c0894.cloudfunctions.net/CheckoutFreeCourse",
          {
            params: {
              course: docID,
              id: userData.value.stripeId,
              userUID: userData.value.id,
            },
          }
        )
        .then((response) => {
          if (response) {
            isLoading.value = false;
            location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          isLoading.value = false;
        });
    };
    const payCheckout = (docID) => {
      //Check to make sure the profile has a user ID
      if (
        userData.value.stripeId == null ||
        userData.value.stripeId == undefined
      ) {
        alert(
          "Error: No Stripe ID found for this account. Please contact support at contact@1stclasslax.com and we will fix it asap. Sorry for the inconvenience"
        );
        return;
      }
      isLoading.value = true;
      axios
        .get(functionURL, {
          params: {
            course: docID,
            id: userData.value.stripeId,
            userUID: userData.value.id,
          },
        })
        .then((response) => {
          sessionID = response.data.id;
          stripe
            .redirectToCheckout({
              sessionId: sessionID,
            })
            .then(function (result) {
              isLoading.value = false;
              if (result.error) {
                alert(result.error);
              }
            });
        })
        .catch((error) => {
          console.log(error);
          isLoading.value = false;
        });
    };

    const checkPublish = (l) => {
      let r = [];
      l.forEach((element) => {
        if (element.publishable == true) {
          r.push(element);
        }
      });
      console.log(r);
      return r;
    };

    return {
      isPremium,
      pageLoaded,
      eventLoad,
      formatAMPM,
      payCheckout,
      customerOwns,
      isLoading,
      checkPublish,
      payCheckoutFree,
    };
  },
};
</script>

<style scoped>
.plan-timing-list ul li {
  width: 100%;
}
.courseHeight {
  min-height: 1000px !important;
}
</style>