<template>
  <loadingSpinner v-if="!eventDataLoaded" />
  <div v-if="eventDataLoaded">
    <div v-if="!isPremium && eventData.isPremium">
      <sectionTitle />
      <whyFCL />
      <freeTrial />
      <pricing />
    </div>

    <div
      class="row"
      v-if="!eventData.isPremium || (isPremium && eventData.isPremium)"
    >
      <div class="col-lg-9" v-if="eventData.chatEnabled">
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            :src="
              'https://vimeo.com/webinars/events/' +
              eventData.vimeoEventId +
              '/embed'
            "
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          ></iframe>
        </div>
      </div>
      <div class="col-lg-12" v-if="!eventData.chatEnabled">
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            :src="
              'https://vimeo.com/webinars/events/' +
              eventData.vimeoEventId +
              '/embed'
            "
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          ></iframe>
        </div>
      </div>
      <div class="col-lg-3" v-if="eventData.chatEnabled">
        <iframe
          :src="
            'https://vimeo.com/webinars/events/' +
            eventData.vimeoEventId +
            '/chat/'
          "
          width="400"
          height="600"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
//Premium Walls
import freeTrial from "../../components/freeTrialOffer.vue";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import pricing from "../../components/pricing";
import { projectFirestore } from "../../firebase/config";
import loadingSpinner from "../../components/loadingSpinner.vue";

import { computed, ref, watch, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  components: {
    pricing,
    sectionTitle,
    whyFCL,
    freeTrial,
    loadingSpinner,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const fclLiveDocId = route.params.id;
    const eventDataLoaded = ref(false);
    const eventData = ref({});
    projectFirestore
      .collection("fclLiveEvents")
      .doc(fclLiveDocId)
      .get()
      .then((event) => {
        eventData.value = event.data();
        eventDataLoaded.value = true;
      });
    const fclLiveId = "14fd2036-593f-47c1-baa9-b4610cc9004a";
    const isPremium = computed(() => {
      return store.getters.getIsPremium;
    });

    return { isPremium, fclLiveId, eventData, eventDataLoaded };
  },
};
</script>

<style>
</style>