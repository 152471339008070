import { projectAuth } from "../firebase/config";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import getDocument from "../composables/getDocument";
import getProgramData from "../composables/getProgramData";
import getSubs from "../composables/getSubscrips";
import getWH from "../composables/getWorkoutHistory";
import getVideos from "../composables/getVideos";
import getData from "../composables/getData";
import getData8 from "../composables/getData2";
import getUserData from "../composables/getUserData";
import checkIfPremium from "../composables/checkPrem";
import getProgramRequests from "../composables/getProgramRequests";
import getProgramMembers from "../composables/getProgramMembers";

// Create a new store instance.
export const store = createStore({
  state: {
    //user data
    user: {},
    userData: {},
    userDataLoaded: false,
    //subscriptions
    subscriptions: {},
    subscriptionsLoaded: {},
    //Workout History
    userWorkoutHistory: {},
    userWorkoutHistoryLoaded: {},
    //videos
    videoData: {},
    videoDataLoaded: false,
    //Workouts
    workoutData: {},
    workoutDataLoaded: false,
    //Exercises
    exerciseData: {},
    exerciseDataLoaded: false,
    //FCLLiveEvents
    fclLiveEventData: {},
    fclLiveEventDataLoaded: false,
    //fcl courses
    fclCoursesData: {},
    fclCoursesDataLoaded: false,
    //fcl Purchased Courses
    purchasedCoursesData: {},
    purchasedCoursesDataLoaded: false,

    //programs
    programData: {},
    programDataLoaded: {},
    isProgramMember: {},
    //program requests
    programRequestData: {},
    programRequestDataLoaded: {},
    //program members
    programMembersData: {},
    programMembersDataLoaded: {},
  },
  //plugins: [createPersistedState()],
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserData(state, userData) {
      state.userData = userData;
    },
    setVidDoc(state, videoData) {
      state.videoData = videoData;
    },
    setSubScrip(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    setExerciseData(state, exerciseData) {
      state.exerciseData = exerciseData;
    },
    setfclLiveEventData(state, fclLiveEventData) {
      state.fclLiveEventData = fclLiveEventData;
    },
    setfclCoursesData(state, fclCoursesData) {
      state.fclCoursesData = fclCoursesData;
    },
    setWorkoutData(state, workoutData) {
      state.workoutData = workoutData;
    },
    setuserWorkoutHistory(state, userWorkoutHistory) {
      state.userWorkoutHistory = userWorkoutHistory;
    },
    setuserprogramData(state, programData) {
      state.programData = programData;
    },
    setuserprogramRequestData(state, programRequestData) {
      state.programRequestData = programRequestData;
    },
    setuserprogramMemberData(state, programMembersData) {
      state.programMembersData = programMembersData;
    },
    setuserprogramMemberStatus(state, isProgramMember) {
      state.isProgramMember = isProgramMember;
    },
    setpurchasedCoursesData(state, purchasedCoursesData) {
      state.purchasedCoursesData = purchasedCoursesData;
    },

    //Loading Setters
    setWorkoutDataLoaded(state, workoutDataLoaded) {
      state.workoutDataLoaded = workoutDataLoaded;
    },
    setExerciseDataLoaded(state, exerciseDataLoaded) {
      state.exerciseDataLoaded = exerciseDataLoaded;
    },
    setfclLiveEventDataLoaded(state, fclLiveEventDataLoaded) {
      state.fclLiveEventDataLoaded = fclLiveEventDataLoaded;
    },
    setfclCoursesDataLoaded(state, fclCoursesDataLoaded) {
      state.fclCoursesDataLoaded = fclCoursesDataLoaded;
    },
    setVideoDataLoaded(state, videoDataLoaded) {
      state.videoDataLoaded = videoDataLoaded;
    },
    setUserDataLoaded(state, userDataLoaded) {
      state.userDataLoaded = userDataLoaded;
    },
    setsubscriptionsLoaded(state, subscriptionsLoaded) {
      state.subscriptionsLoaded = subscriptionsLoaded;
    },
    setuserWorkoutHistoryLoaded(state, userWorkoutHistoryLoaded) {
      state.userWorkoutHistoryLoaded = userWorkoutHistoryLoaded;
    },
    setuserProgramHistoryLoaded(state, programDataLoaded) {
      state.programDataLoaded = programDataLoaded;
    },
    setuserProgramRequestHistoryLoaded(state, programRequestDataLoaded) {
      state.programRequestDataLoaded = programRequestDataLoaded;
    },
    setuserProgramMembersLoaded(state, programMembersDataLoaded) {
      state.programMembersDataLoaded = programMembersDataLoaded;
    },
    setpurchasedCoursesDataLoaded(state, purchasedCoursesDataLoaded) {
      state.purchasedCoursesDataLoaded = purchasedCoursesDataLoaded;
    },
  },
  actions: {
    setUser(context, user) {
      context.commit("setUser", user);
    },

    authChange({ commit }) {
      if (!this.state.user) {
        commit("setUser", null);
        commit("setUserDataLoaded", false);
      }

      projectAuth.onAuthStateChanged((user) => {
        if (user) {
          commit("setUser", user); //setUserDataLoaded
          commit("setUserDataLoaded", false);
          commit("setsubscriptionsLoaded", false);
          const { error, document } = getDocument("fclUsers", user.uid);

          const { subs, error2, load } = getSubs(user.uid);
          const { wh, error3, load3 } = getWH(user.uid);
          const { userData, errorUser, loadUser } = getUserData(user.uid);
          const { posts8, error8, load8 } = getData8(
            `fclUsers/${user.uid}/purchasedCourses`
          );

          loadUser().then(() => {
            commit("setUserData", userData);
            commit("setUserDataLoaded", true);
            //If the user is a member of a program get the program data
            if (
              userData.value.usrType == "pm" ||
              userData.value.usrType == "program-player"
            ) {
              commit("setuserprogramMemberStatus", true);
              const { programData, errorProgram, loadProgram } = getProgramData(
                userData.value.program
              );
              loadProgram().then(() => {
                commit("setuserprogramData", programData);
                commit("setuserProgramHistoryLoaded", true);
              });

              //if the user is also a pm get requests
              if (userData.value.usrType == "pm") {
                const { programMembers, errorPM, loadPM } = getProgramMembers(
                  userData.value.program
                );
                loadPM().then(() => {
                  commit("setuserprogramMemberData", programMembers);
                  commit("setuserProgramMembersLoaded", true);
                });
                const { programRequests, errorPR, loadPR } = getProgramRequests(
                  userData.value.program
                );
                loadPR().then(() => {
                  commit("setuserprogramRequestData", programRequests);
                  commit("setuserProgramRequestHistoryLoaded", true);
                });
              }
            } else {
              commit("setuserprogramMemberStatus", false);
            }
          });
          load().then(() => {
            commit("setSubScrip", subs);
            commit("setsubscriptionsLoaded", true);
          });
          load8().then(() => {
            commit("setpurchasedCoursesData", posts8);
            commit("setpurchasedCoursesDataLoaded", true);
          });
          load3().then(() => {
            commit("setuserWorkoutHistory", wh);
            commit("setuserWorkoutHistoryLoaded", true);
          });
        } else {
          commit("setUser", null);
          commit("setUserData", null);
        }
      });
    },
    acceptExercises({ commit }) {
      commit("setExerciseDataLoaded", false);
      const { posts, error, load } = getData("exercises");
      load().then(() => {
        if (!error.value) {
          commit("setExerciseData", posts);
          commit("setExerciseDataLoaded", true);
        } else {
          console.log(error.value);
        }
      });
    },
    // acceptCoursesData({ commit }) {
    //   commit("purchasedCoursesData", false);
    //   console.log(store.userData);

    //   load().then(() => {
    //     if (!error.value) {
    //       commit("setpurchasedCoursesData", posts);
    //       commit("setpurchasedCoursesDataLoaded", true);
    //     } else {
    //       console.log(error.value);
    //     }
    //   });
    // },
    acceptLiveEvents({ commit }) {
      commit("setfclLiveEventDataLoaded", false);
      const { posts, error, load } = getData("fclLiveEvents");
      load().then(() => {
        if (!error.value) {
          commit("setfclLiveEventData", posts);
          commit("setfclLiveEventDataLoaded", true);
        } else {
          console.log(error.value);
        }
      });
    },

    acceptCourses({ commit }) {
      commit("setfclCoursesDataLoaded", false);
      const { posts, error, load } = getData("courses");
      load().then(() => {
        if (!error.value) {
          commit("setfclCoursesData", posts);
          commit("setfclCoursesDataLoaded", true);
        } else {
          console.log(error.value);
        }
      });
    },

    logout({ commit }) {
      commit("setUserDataLoaded", false);
      commit("setVideoDataLoaded", false);
      commit("setExerciseDataLoaded", false);
      commit("setWorkoutDataLoaded", false);
      commit("setsubscriptionsLoaded", false);
      commit("setuserWorkoutHistoryLoaded", false);
      commit("setuserProgramHistoryLoaded", false);
      commit("setuserProgramRequestHistoryLoaded", false);
      commit("setuserProgramMembersLoaded", false);
      commit("setfclLiveEventDataLoaded", false);
    },

    acceptWorkouts({ commit }) {
      commit("setWorkoutDataLoaded", false);
      const { posts, error, load } = getData("workouts");
      load().then(() => {
        if (!error.value) {
          commit("setWorkoutData", posts);
          commit("setWorkoutDataLoaded", true);
        } else {
          console.log(error.value);
        }
      });
    },

    refreshPrograms({ commit, state }) {
      commit("setuserprogramMemberStatus", true);
      commit("setuserProgramHistoryLoaded", false);
      commit("setuserProgramMembersLoaded", false);
      commit("setuserProgramRequestHistoryLoaded", false);

      const { programData, errorProgram, loadProgram } = getProgramData(
        state.programData.id
      );
      loadProgram().then(() => {
        commit("setuserprogramData", programData);
        commit("setuserProgramHistoryLoaded", true);
      });
      if (state.userData.usrType == "pm") {
        const { programMembers, errorPM, loadPM } = getProgramMembers(
          state.programData.id
        );
        loadPM().then(() => {
          commit("setuserprogramMemberData", programMembers);
          commit("setuserProgramMembersLoaded", true);
        });
        const { programRequests, errorPR, loadPR } = getProgramRequests(
          state.programData.id
        );
        loadPR().then(() => {
          commit("setuserprogramRequestData", programRequests);
          commit("setuserProgramRequestHistoryLoaded", true);
        });
      }
    },

    acceptVids({ commit }) {
      commit("setVideoDataLoaded", false);
      const finalUpload = [];
      let i;
      let tempVid;
      const { vids, error, load } = getVideos();
      load().then(() => {
        if (!error.value) {
          for (i = 0; i < vids.value.length; i++) {
            tempVid = {
              seriesID: vids.value[i].seriesID,
              seriesName: vids.value[i].seriesName,
              videoNames: vids.value[i].videoNames,
              genderFocus: vids.value[i].genderFocus,
              categories: vids.value[i].categories,
              skillLevel: vids.value[i].skillLevel,
              tags: vids.value[i].tags,
              vimeoIds: vids.value[i].vimeoIds,
              vidCount: vids.value[i].videoNames,
              beginnerOnly: vids.value[i].skillLevel.includes("Beginner"),
              id: vids.value[i].id,
            };
            if (typeof vids.value[i].dateOrder != "undefined") {
              tempVid.dateOrder = vids.value[i].dateOrder;
            }
            finalUpload.push(tempVid);
          }
          commit("setVidDoc", finalUpload);
          commit("setVideoDataLoaded", true);
        } else {
          console.log(error.value);
        }
      });
    },
  },
  getters: {
    getAllVideos(state) {
      return state.videoData;
    },
    getAllExercises(state) {
      return state.exerciseData;
    },
    getAllWorkouts(state) {
      return state.workoutData;
    },
    getUserWorkoutHistory(state) {
      return state.userWorkoutHistory;
    },
    getUserDataLoaded(state) {
      if (state.subscriptionsLoaded && state.userDataLoaded) {
        return true;
      }
      return false;
    },
    getProgramUserCountTotal(state) {
      if (state.subscriptionsLoaded && state.userDataLoaded) {
        for (let i = 0; i < state.subscriptions.length; i++) {
          if (
            state.subscriptions[i].status == "active" &&
            state.subscriptions[i].items[0].plan.id ==
              "price_1JSjmVINbM8Ghj39vK7doQ9h"
          ) {
            return state.subscriptions[i].quantity;
          }
        }
      }
      return 0;
    },
    getProgramUserCountTotal(state) {
      if (state.subscriptionsLoaded && state.userDataLoaded) {
        for (let i = 0; i < state.subscriptions.length; i++) {
          if (state.subscriptions[i].status == "active") {
            return state.subscriptions[i].quantity;
          }
        }
      }
      return 0;
    },

    getLiveEvents(state) {
      return state.fclLiveEventData;
    },

    getIsPremium(state) {
      if (state.userDataLoaded) {
        //is user in a program
        if (state.programDataLoaded && state.isProgramMember) {
          //check if the program is active
          if (state.programData.status == "active") {
            return true;
          }
        } else {
          if (state.subscriptionsLoaded && state.userDataLoaded) {
            if (!checkIfPremium(state.userData.email)) {
              for (let i = 0; i < state.subscriptions.length; i++) {
                if (
                  state.subscriptions[i].status == "active" ||
                  state.subscriptions[i].status == "trialing"
                ) {
                  return true;
                }
              }
            } else {
              return true;
            }
          }
        }
      }
      return false;
    },
  },
});
