<template>
  <nav
    class="
      navbar navbar-top navbar-expand navbar-dashboard navbar-dark
      pl-0
      pr-2
      pb-0
    "
  >
    <div class="container-fluid px-0">
      <div
        class="d-flex justify-content-between w-100"
        id="navbarSupportedContent"
      >
        <div class="d-flex">
          <!-- Search form -->
          <form class="navbar-search form-inline" id="navbar-search-main">
            <div class="input-group input-group-merge search-bar"></div>
          </form>
        </div>
        <!-- Navbar links -->
        <ul class="navbar-nav align-items-center">
          <li class="nav-item dropdown">
            <a
              @click="isOpen = !isOpen"
              :class="[{ show: isOpen }, 'nav-link', 'pt-1', 'px-0']"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              :aria-expanded="isOpen"
            >
              <div class="media d-flex align-items-center">
                <img
                  class="user-avatar md-avatar rounded-circle"
                  alt="Image placeholder"
                  src="../../assets/img/example-propic.jpg"
                />
                <div
                  class="
                    media-body
                    ml-2
                    text-dark
                    align-items-center
                    d-none d-lg-block
                  "
                >
                  <span
                    v-if="userData"
                    class="mb-0 font-small font-weight-bold"
                  >
                    {{ userData.firstName }} {{ userData.lastName }}
                  </span>
                </div>
              </div>
            </a>
            <div
              :class="[
                { show: isOpen },
                'dropdown-menu',
                'dashboard-dropdown',
                'dropdown-menu-right',
                'mt-2',
              ]"
            >
              <!-- <router-link to="/profile" class="dropdown-item font-weight-bold">
                <span class="fas fa-user-circle"></span>My Profile</router-link
              > -->

              <!-- <a class="dropdown-item font-weight-bold" href="#"
                          ><span class="fas fa-envelope-open-text"></span
                          >Messages</a
                        > -->
              <router-link to="/support" class="dropdown-item font-weight-bold">
                <span class="fas fa-user-shield"></span>Support</router-link
              >

              <div role="separator" class="dropdown-divider"></div>
              <a class="dropdown-item font-weight-bold" @click="handleLogout"
                ><span class="fas fa-sign-out-alt text-danger"></span>Logout</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "@vue/reactivity";
import useLogout from "../../composables/useLogout";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {
    const store = useStore();
    const { logout } = useLogout();
    const router = useRouter();
    let isOpen = ref(false);
    const userData = computed(() => store.state.userData);

    const handleLogout = async () => {
      store.dispatch("logout");
      await logout();
      console.log("user logged out");
      router.push({ name: "Login" });
    };
    return { isOpen, handleLogout, userData };
  },
};
</script>

<style>
</style>