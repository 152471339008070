import { ref } from "@vue/reactivity";
import oldSiteSubs from "../assets/activeSubs";
import { watch } from "@vue/runtime-dom";

const checkIfPremium = (email) => {
  const isPremium = ref(false);
  const today = new Date();

  for (let j = 0; j < oldSiteSubs.length; j++) {
    if (email.toLowerCase() == oldSiteSubs[j].billing_email.toLowerCase()) {
      const dates = oldSiteSubs[j].Subscription_End.split("/");
      const uMonth = parseInt(dates[0]);
      const uDay = parseInt(dates[1]);
      const uYear = parseInt(dates[2].slice(0, 4));
      const userDate = new Date(uYear, uMonth - 1, uDay, 0, 0, 0, 0);
      if (userDate > today) {
        isPremium.value = true;
        return isPremium.value;
      }
    }
  }

  return isPremium.value;
};
export default checkIfPremium;
