<template>
  <div v-if="!userDataLoaded">
    <loadingSpinner />
  </div>
  <div v-if="!isPremium">
    <sectionTitle />
    <whyFCL />
    <freeTrial />
    <pricing />
  </div>

  <div v-if="!vidFound && videosLoaded && isPremium">
    <h1>error: Video Not Found</h1>
  </div>
  <div v-if="!vidFound && !videosLoaded && isPremium">
    <loadingSpinner />
  </div>
  <div v-if="vidFound">
    <h1>{{ seriesName }}</h1>
    <hr />
    <div v-for="(videoNames, index) in videoNames">
      <h3>{{ videoNames }}</h3>
      <div class="vimeoWrap">
        <iframe
          :src="'https://player.vimeo.com/video/' + videoIds[index]"
          width="640"
          height="334"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ref } from "@vue/reactivity";
import { computed, watchEffect } from "@vue/runtime-core";
import loadingSpinner from "../../components/loadingSpinner.vue";

import freeTrial from "../../components/freeTrialOffer.vue";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import pricing from "../../components/pricing";

export default {
  components: { pricing, sectionTitle, whyFCL, freeTrial, loadingSpinner },
  setup() {
    const store = useStore();
    const route = useRoute();

    const vidFound = ref(false);
    const vidID = route.params.seriesID;
    //Get Videos
    const videosLoaded = computed(() => store.state.videoDataLoaded);
    const videoLoad = computed(() => store.getters.getAllVideos);
    const userDataLoaded = computed(() => {
      return store.state.userDataLoaded;
    });
    let allVideos = computed(() => {
      if (videosLoaded.value) {
        return videoLoad.value;
      } else {
        return [];
      }
    });

    // Other Params
    const seriesName = ref("");
    const videoNames = ref([]);
    const videoIds = ref([]);

    watchEffect(() => {
      if (videosLoaded.value == true) {
        //video info

        let i;
        for (i = 0; i < allVideos.value.length; i++) {
          if (allVideos.value[i].id == vidID) {
            vidFound.value = true;
            seriesName.value = allVideos.value[i].seriesName;
            videoNames.value = allVideos.value[i].videoNames;
            videoIds.value = allVideos.value[i].vimeoIds;
          }
        }
      }
    });

    const isPremium = computed(() => {
      console.log(store.getters.getIsPremium);
      return store.getters.getIsPremium;
    });

    return {
      vidFound,
      seriesName,
      videoNames,
      videoIds,
      videosLoaded,
      isPremium,
      userDataLoaded,
    };
  },
};
</script>

<style>
.vimeoWrap {
  position: relative;
  padding-bottom: 56.25%;
  /* max-width: 75%; */
}

.vimeoWrap iframe {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>