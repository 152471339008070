<template>
  <div v-if="!isPremium">
    <sectionTitle />
    <whyFCL />
    <freeTrial />
    <pricing />
  </div>

  <section v-if="isPremium">
    <div v-if="workoutsLoaded == false || exerciseLoaded == false">
      <h1>Error: Workout Not Found</h1>
    </div>
    <div v-if="workoutsLoaded == true && exerciseLoaded == true">
      <loadingSpinner v-if="isPending" />
      <!-- <div class="container"> -->
      <!-- <div class="row"> -->
      <div class="col-lg-10 workout__section">
        <div class="event-detail-sec">
          <div class="player-text">
            <h3>{{ curWorkout.workoutName }}</h3>
          </div>
          <br />
          <div class="workout__person__details">
            <p>
              <b><i class="fas fa-bullseye"></i></b>&nbsp;&nbsp;
              {{ curWorkout.workoutPosition }}
            </p>
            <p>
              <b><i class="far fa-user"></i></b>&nbsp;&nbsp;{{
                curWorkout.workoutGenderFocus
              }}
            </p>
            <p>
              <b><i class="far fa-calendar"></i></b>&nbsp;&nbsp;{{
                curWorkout.workoutTime[0]
              }}
            </p>
            <p>
              <b><i class="fas fa-user-plus"></i></b>&nbsp;&nbsp;<span
                v-if="curWorkout.workoutAge.length == 3"
                >All</span
              >
              <span v-if="curWorkout.workoutAge.length == 2"
                >{{ curWorkout.workoutAge[0] }},
                {{ curWorkout.workoutAge[1] }}</span
              >
              <span v-if="curWorkout.workoutAge.length == 1">{{
                curWorkout.workoutAge[0]
              }}</span>
            </p>
          </div>

          <div class="workout__track__area">
            <div class="panel-group" id="accordionExample">
              <!-- Day 1 Start -->
              <div class="panel panel-default" @click="changePanel(1)">
                <div class="workout__track__inner">
                  <div class="panel-heading">
                    <h3 class="panel-title">
                      <div class="calender__icon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <a
                        class="days__title"
                        data-toggle="collapse"
                        id="headingone"
                        >Day One
                        <span>
                          {{ userWorkoutData.day1.filter(Boolean).length }} /
                          {{ userWorkoutData.day1.length }}</span
                        >
                        <i
                          v-if="!userWorkoutData.day1.includes(false)"
                          class="fa fa-check"
                        ></i
                      ></a>
                    </h3>
                  </div>

                  <div
                    id="collapse1"
                    :class="[
                      { show: dayOnePanel },
                      'panel-collapse',
                      'in',
                      'collapse',
                    ]"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="progress progress-lg rounded mb-0">
                      <div
                        class="progress-bar bg-success rounded"
                        role="progressbar"
                        :aria-valuenow="
                          100 *
                          (userWorkoutData.day1.filter(Boolean).length /
                            userWorkoutData.day1.length)
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="
                          'width:' +
                          100 *
                            (userWorkoutData.day1.filter(Boolean).length /
                              userWorkoutData.day1.length) +
                          '%'
                        "
                      ></div>
                    </div>
                    <div class="panel-body">
                      <div
                        class="workout__exercises"
                        v-for="(workout, index) in curWorkout.day1"
                        :key="workout.id"
                      >
                        <!-- Exercise Titles -->
                        <div
                          class="exercise__title__in"
                          @click="curExerciseOpen = curWorkout.day1[index].id"
                        >
                          {{
                            getExerciseData(curWorkout.day1[index].id, "name")
                          }}
                          <i
                            class="fa fa-check"
                            v-if="userWorkoutData.day1[index] == true"
                          ></i>
                        </div>
                        <div
                          :class="[
                            {
                              active:
                                curExerciseOpen == curWorkout.day1[index].id,
                            },
                            'exercise__detail__inner',
                          ]"
                        >
                          <h3 class="reps">
                            <i class="fa fa-hourglass"></i>
                            {{ curWorkout.day1[index].quantity }} Reps |
                            <small>{{ curWorkout.day1[index].notes }}</small>
                          </h3>
                          <br /><br /><br />

                          <div class="vimeoWrap">
                            <iframe
                              :src="
                                'https://player.vimeo.com/video/' +
                                getExerciseData(
                                  curWorkout.day1[index].id,
                                  'vimeo'
                                )
                              "
                              width="640"
                              height="334"
                              frameborder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                          <p
                            v-if="
                              getExerciseData(
                                curWorkout.day1[index].id,
                                'description'
                              ) != ''
                            "
                          >
                            <br />
                            {{
                              getExerciseData(
                                curWorkout.day1[index].id,
                                "description"
                              )
                            }}
                          </p>

                          <div
                            class="workout__done__btn"
                            v-if="userWorkoutData.day1[index] == false"
                          >
                            <input
                              @click="completeExercise(1, index, true)"
                              value="Mark Complete"
                              class="btn"
                            />
                          </div>
                          <div
                            class="workout__done__btn"
                            v-if="userWorkoutData.day1[index] == true"
                          >
                            <input
                              @click="completeExercise(1, index, false)"
                              value="Mark Incomplete"
                              class="btn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Day 1 End -->
              <!-- day 2 begin -->
              <div class="panel panel-default" @click="changePanel(2)">
                <div class="workout__track__inner">
                  <div class="panel-heading">
                    <h3 class="panel-title">
                      <div class="calender__icon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <a
                        class="days__title"
                        data-toggle="collapse"
                        id="headingone"
                        >Day Two
                        <span>
                          {{ userWorkoutData.day2.filter(Boolean).length }} /
                          {{ userWorkoutData.day2.length }}</span
                        >
                        <i
                          v-if="!userWorkoutData.day2.includes(false)"
                          class="fa fa-check"
                        ></i
                      ></a>
                    </h3>
                  </div>

                  <div
                    id="collapse1"
                    :class="[
                      { show: dayTwoPanel },
                      'panel-collapse',
                      'in',
                      'collapse',
                    ]"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="progress progress-lg rounded mb-0">
                      <div
                        class="progress-bar bg-success rounded"
                        role="progressbar"
                        :aria-valuenow="
                          100 *
                          (userWorkoutData.day2.filter(Boolean).length /
                            userWorkoutData.day2.length)
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="
                          'width:' +
                          100 *
                            (userWorkoutData.day2.filter(Boolean).length /
                              userWorkoutData.day2.length) +
                          '%'
                        "
                      ></div>
                    </div>
                    <div class="panel-body">
                      <div
                        class="workout__exercises"
                        v-for="(workout, index) in curWorkout.day2"
                        :key="workout.id"
                      >
                        <!-- Exercise Titles -->
                        <div
                          class="exercise__title__in"
                          @click="curExerciseOpen = curWorkout.day2[index].id"
                        >
                          {{
                            getExerciseData(curWorkout.day2[index].id, "name")
                          }}
                          <i
                            class="fa fa-check"
                            v-if="userWorkoutData.day2[index] == true"
                          ></i>
                        </div>
                        <div
                          :class="[
                            {
                              active:
                                curExerciseOpen == curWorkout.day2[index].id,
                            },
                            'exercise__detail__inner',
                          ]"
                        >
                          <h3 class="reps">
                            <i class="fa fa-hourglass"></i>
                            {{ curWorkout.day2[index].quantity }} Reps |
                            <small>{{ curWorkout.day2[index].notes }}</small>
                          </h3>
                          <br /><br /><br />

                          <div class="vimeoWrap">
                            <iframe
                              :src="
                                'https://player.vimeo.com/video/' +
                                getExerciseData(
                                  curWorkout.day2[index].id,
                                  'vimeo'
                                )
                              "
                              width="640"
                              height="334"
                              frameborder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                          <p
                            v-if="
                              getExerciseData(
                                curWorkout.day2[index].id,
                                'description'
                              ) != ''
                            "
                          >
                            <br />
                            {{
                              getExerciseData(
                                curWorkout.day2[index].id,
                                "description"
                              )
                            }}
                          </p>

                          <div
                            class="workout__done__btn"
                            v-if="userWorkoutData.day2[index] == false"
                          >
                            <input
                              @click="completeExercise(2, index, true)"
                              value="Mark Complete"
                              class="btn"
                            />
                          </div>
                          <div
                            class="workout__done__btn"
                            v-if="userWorkoutData.day2[index] == true"
                          >
                            <input
                              @click="completeExercise(2, index, false)"
                              value="Mark Incomplete"
                              class="btn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- day 2 End -->
              <!-- day 3 begin -->
              <div class="panel panel-default" @click="changePanel(3)">
                <div class="workout__track__inner">
                  <div class="panel-heading">
                    <h3 class="panel-title">
                      <div class="calender__icon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <a
                        class="days__title"
                        data-toggle="collapse"
                        id="headingone"
                        >Day Three
                        <span>
                          {{ userWorkoutData.day3.filter(Boolean).length }} /
                          {{ userWorkoutData.day3.length }}</span
                        >
                        <i
                          v-if="!userWorkoutData.day3.includes(false)"
                          class="fa fa-check"
                        ></i
                      ></a>
                    </h3>
                  </div>

                  <div
                    id="collapse1"
                    :class="[
                      { show: dayThreePanel },
                      'panel-collapse',
                      'in',
                      'collapse',
                    ]"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="progress progress-lg rounded mb-0">
                      <div
                        class="progress-bar bg-success rounded"
                        role="progressbar"
                        :aria-valuenow="
                          100 *
                          (userWorkoutData.day3.filter(Boolean).length /
                            userWorkoutData.day3.length)
                        "
                        aria-valuemin="0"
                        aria-valuemax="100"
                        :style="
                          'width:' +
                          100 *
                            (userWorkoutData.day3.filter(Boolean).length /
                              userWorkoutData.day3.length) +
                          '%'
                        "
                      ></div>
                    </div>
                    <div class="panel-body">
                      <div
                        class="workout__exercises"
                        v-for="(workout, index) in curWorkout.day3"
                        :key="workout.id"
                      >
                        <!-- Exercise Titles -->
                        <div
                          class="exercise__title__in"
                          @click="curExerciseOpen = curWorkout.day3[index].id"
                        >
                          {{
                            getExerciseData(curWorkout.day3[index].id, "name")
                          }}
                          <i
                            class="fa fa-check"
                            v-if="userWorkoutData.day3[index] == true"
                          ></i>
                        </div>
                        <div
                          :class="[
                            {
                              active:
                                curExerciseOpen == curWorkout.day3[index].id,
                            },
                            'exercise__detail__inner',
                          ]"
                        >
                          <h3 class="reps">
                            <i class="fa fa-hourglass"></i>
                            {{ curWorkout.day3[index].quantity }} Reps |
                            <small>{{ curWorkout.day3[index].notes }}</small>
                          </h3>
                          <br /><br /><br />

                          <div class="vimeoWrap">
                            <iframe
                              :src="
                                'https://player.vimeo.com/video/' +
                                getExerciseData(
                                  curWorkout.day3[index].id,
                                  'vimeo'
                                )
                              "
                              width="640"
                              height="334"
                              frameborder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                          <p
                            v-if="
                              getExerciseData(
                                curWorkout.day3[index].id,
                                'description'
                              ) != ''
                            "
                          >
                            <br />
                            {{
                              getExerciseData(
                                curWorkout.day3[index].id,
                                "description"
                              )
                            }}
                          </p>

                          <div
                            class="workout__done__btn"
                            v-if="userWorkoutData.day3[index] == false"
                          >
                            <input
                              @click="completeExercise(3, index, true)"
                              value="Mark Complete"
                              class="btn"
                            />
                          </div>
                          <div
                            class="workout__done__btn"
                            v-if="userWorkoutData.day3[index] == true"
                          >
                            <input
                              @click="completeExercise(3, index, false)"
                              value="Mark Incomplete"
                              class="btn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Day 3 end -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <!-- </div> -->
  </section>
</template>

<script>
import eventSectionTitle from "../../components/eventSectionTitle";

//Premium Walls
import freeTrial from "../../components/freeTrialOffer.vue";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import pricing from "../../components/pricing";
//other
import { computed, ref, watch, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import updateWorkoutViews from "../../composables/updateWorkoutView";
import loadingSpinner from "../../components/loadingSpinner.vue";

export default {
  components: {
    loadingSpinner,
    pricing,
    sectionTitle,
    whyFCL,
    eventSectionTitle,
    freeTrial,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const workoutID = route.params.id;
    const userData = computed(() => store.state.userData);

    //This Users Workout Data
    const userWDData = computed(() => {
      if (store.state.userWorkoutHistoryLoaded) {
        return store.state.userWorkoutHistory;
      } else {
        return null;
      }
    });

    const buildWorkoutObject = () => {
      let tempDay1 = [];
      let tempDay2 = [];
      let tempDay3 = [];
      for (let x = 0; x < curWorkout.value.day1.length; x++) {
        tempDay1.push(false);
      }
      for (let y = 0; y < curWorkout.value.day2.length; y++) {
        tempDay2.push(false);
      }
      for (let z = 0; z < curWorkout.value.day3.length; z++) {
        tempDay3.push(false);
      }
      let doc = { day1: tempDay1, day2: tempDay2, day3: tempDay3 };
      return doc;
    };

    const userWorkoutData = computed(() => {
      if (store.state.userWorkoutHistoryLoaded) {
        if (userWDData.value.length > 0) {
          let found = false;
          for (let j = 0; j < userWDData.value.length; j++) {
            if (userWDData.value[j].id == workoutID) {
              found = true;
              return userWDData.value[j];
            }
          }
          if (!found) {
            //user has workout data but not with this workout
            return buildWorkoutObject();
          }
        } else {
          //user has no existing workout data
          return buildWorkoutObject();
        }
      }
    });
    const { errorUp, updateWD, isPending } = updateWorkoutViews();

    //If Panels Are P[em]
    const dayOnePanel = ref(true);
    const dayTwoPanel = ref(false);
    const dayThreePanel = ref(false);
    const curExerciseOpen = ref("");

    //get Workout data
    const workoutsLoaded = computed(() => store.state.workoutDataLoaded);
    const workoutLoad = computed(() => store.getters.getAllWorkouts);
    const curWorkout = ref(null);
    let allWorkouts = computed(() => {
      if (workoutsLoaded.value) {
        return workoutLoad.value;
      } else {
        return [];
      }
    });
    //check to see if all workouts loaded and get the current one
    watchEffect(() => {
      if (workoutsLoaded.value) {
        for (let j = 0; j < allWorkouts.value.length; j++) {
          if (allWorkouts.value[j].id == workoutID) {
            curWorkout.value = allWorkouts.value[j];
            console.log(curWorkout.value);
            break;
          }
        }
      }
    });

    //Get Exercise Data
    const exerciseLoaded = computed(() => store.state.exerciseDataLoaded);
    const exerciseLoad = computed(() => store.getters.getAllExercises);

    //Check for Premium Sub
    const isPremium = computed(() => {
      return store.getters.getIsPremium;
    });

    //function to change panel that open
    const changePanel = (panel) => {
      if (panel == 1) {
        dayOnePanel.value = true;
        dayTwoPanel.value = false;
        dayThreePanel.value = false;
      }
      if (panel == 2) {
        dayOnePanel.value = false;
        dayTwoPanel.value = true;
        dayThreePanel.value = false;
      }
      if (panel == 3) {
        dayOnePanel.value = false;
        dayTwoPanel.value = false;
        dayThreePanel.value = true;
      }
    };
    //Get Exercise Data from ID
    let getExerciseData = (id, field) => {
      for (let i = 0; i < exerciseLoad.value.length; i++) {
        if (exerciseLoad.value[i].id == id) {
          if (field == "name") {
            return exerciseLoad.value[i].exerciseName;
          }
          if (field == "vimeo") {
            return exerciseLoad.value[i].exerciseVimeoId;
          }
          if (field == "description") {
            return exerciseLoad.value[i].exerciseDescription;
          }
        }
      }
    };

    //Complete an exercise
    const completeExercise = (day, index, status) => {
      if (day == 1) {
        userWorkoutData.value.day1[index] = status;
      }
      if (day == 2) {
        userWorkoutData.value.day2[index] = status;
      }
      if (day == 3) {
        userWorkoutData.value.day3[index] = status;
      }
      updateWD(userData.value.id, workoutID, userWorkoutData.value).then(() => {
        if (errorUp.value != null) {
          alert(errorUp.value);
        }
      });
    };

    // watch(userWDData, () => {
    //   //check that we have user data
    //   if (userData.value != null) {
    //     if (userWDData.value.length > 0) {
    //       let found = false;
    //       for (let j = 0; j < userWDData.value.length; j++) {
    //         if (userWDData.value[j].id == workoutID) {
    //           console.log("Workout Found");
    //           found = true;
    //           userWorkoutData.value = userWDData.value[j];
    //         }
    //       }
    //       if (!found) {
    //         //user has workout data but not with this workout
    //         userWorkoutData.value = buildWorkoutObject();
    //       }
    //     } else {
    //       //user has no existing workout data
    //       userWorkoutData.value = buildWorkoutObject();
    //     }
    //   }
    // });
    // watch(userData, () => {
    //   //check that we have user data
    //   if (userData.value != null) {
    //     //Check to see if user has workout data

    //     if (userWDData.value.length > 0) {
    //       let found = false;
    //       for (let j = 0; j < userWDData.value.length; j++) {
    //         if (userWDData.value[j].id == workoutID) {
    //           console.log("Workout Found");
    //           found = true;
    //           console.log;
    //           userWorkoutData.value = userWDData.value[j];
    //         }
    //       }
    //       if (!found) {
    //         //user has workout data but not with this workout
    //         userWorkoutData.value = buildWorkoutObject();
    //       }
    //     } else {
    //       //user has no existing workout data
    //       userWorkoutData.value = buildWorkoutObject();
    //     }
    //   }
    // });
    return {
      isPremium,
      curWorkout,
      getExerciseData,
      changePanel,
      dayOnePanel,
      dayTwoPanel,
      dayThreePanel,
      curExerciseOpen,
      completeExercise,
      userWorkoutData,
      isPending,
      workoutsLoaded,
      exerciseLoaded,
    };
  },
};
</script>

<style scoped>
.player-section {
  min-height: 322px;
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.player-text {
  max-width: 1000px;
  margin: 0 auto;
}

.player-text h5 {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.player-text h3 {
  font-family: "hemi";
  font-size: 64px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #db3a34;
  margin: 0 0 8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.event-detail-sec {
  width: 100%;
  background: #fff;
  padding: 15px;
  float: left;
  margin: 10px 0 0;
}

.workout__section {
  margin: 0 auto;
  text-align: left;
  float: none;
}

.workout__section .event-detail-sec {
  padding-left: 70px;
  padding-right: 70px;
  min-height: 700px;
}

.progress .progress-bar-success {
  background-color: #326fb5;
}

.workout__section .progress {
  max-width: 95%;
  margin: 0px auto 28px !important;
}

.workout__track__area {
  width: 100%;
  float: left;
  padding: 0 0 0 30px;
  margin: 10px 0 0 0;
  position: relative;
}

.workout__track__area .panel {
  border: none;
  border-radius: 0;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  float: left;
  width: 100%;
}

.workout__track__inner .panel-heading {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0 0 20px;
  float: right;
  width: 100%;
}

.workout__track__inner .panel-title {
  border-radius: 5px;
  background: #326fb5;
  padding: 10px 15px 10px 65px;
  position: relative;
}

.days__title {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  position: relative;
  z-index: 9;
  word-break: break-all;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none !important;
}

.days__title:hover {
  color: #000;
}

.days__title .fa {
  color: #326fb5;
  font-size: 18px;
  margin: 0 0 0 11px;
  position: relative;
  top: -1px;
}

.workout__exercises {
  width: 50%;
  float: left;
  padding: 0;
  margin: 0;
}

.exercise__title__in {
  list-style: none;
  width: 100%;
  background: #fafafa;
  margin: 0 0 10px;
  text-align: left;
  padding: 5px 32px 5px 15px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
  border-right: 10px solid #fff;
  cursor: pointer;
}

.exercise__title__in:hover {
  background: #c1c1c1;
}

.exercise__title__in .fa {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #326fb5;
  font-size: 18px;
}

.reps {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin: 17px 0 20px;
  text-align: left;
  float: left;
  width: 100%;
  display: inline !important;
}
.reps small {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  display: inline !important;
}

.reps .fa {
  font-size: 21px;
  position: relative;
  top: 2px;
  margin: 0 4px 0 0;
  color: #326fb5;
}

.exercise__video {
  max-width: 445px;
  overflow: hidden;
  float: left;
  max-height: 260px;
  margin: 0 0 30px;
}

.exercise__video {
  max-width: 445px;
  overflow: hidden;
  float: left;
  max-height: 260px;
  margin: 0 0 10px;
}

.workout__track__inner p {
  width: 100%;
  float: left;
  margin: 0 0 20px;
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
}

.workout__done__btn {
  float: center;
  text-align: center;
  width: 100%;
  margin: 10px 0 20px;
}

.workout__done__btn .btn {
  padding: 6px 30px !important;
  color: #e60909;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  background-color: rgba(196, 181, 131, 0);
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-radius: 100px 100px 100px 100px;
  border-color: #e60909;
  text-align: center;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 260px;
  letter-spacing: 1px;
}

.workout__done__btn .btn:hover {
  border-color: #e60909;
  color: #ffffff;
  background-color: #e60909;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.workout__track__inner .panel-body {
  border: none !important;
  padding: 0 0 30px;
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.workout__track__area .panel-group {
  width: 100%;
  float: left;
}

.workout__track__inner .panel-collapse {
  width: 100%;
  float: left;
}

.calender__icon {
  background: #db3a34;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  color: #fff;
  line-height: 39px;
  position: absolute;
  left: 10px;
  top: 6px;
}

.event-detail-sec .player-text h3 {
  text-align: center;
  font-size: 47px;
  margin: 20px 0 0px;
  display: inline-block;
  width: 100%;
  text-shadow: none;
}

.workout__person__details {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 31px;
}

.workout__person__details p {
  font-size: 16px;
  margin: 0 0 5px;
  font-weight: 500;
  width: 24%;
  text-align: center;
  padding: 0 5px;
}
.workout__person__details i {
}
.exercise__detail__inner {
  display: none;
}

.exercise__detail__inner.active {
  display: block;
}

@media (max-width: 990px) {
  .workout__section .event-detail-sec {
    padding-left: 40px;
    padding-right: 40px;
  }

  .player-text h3 {
    font-size: 54px;
  }

  .workout__exercises li {
    width: 100%;
    border-right: 0px solid #fff;
  }

  .workout__person__details p {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .workout__track__area {
    padding: 0 0 0 20px;
  }

  .workout__section .event-detail-sec {
    padding-left: 25px;
    padding-right: 25px;
  }

  .days__title {
    font-size: 21px;
  }

  .workout__track__inner .panel-title {
    padding: 5px 5px 5px 50px;
  }

  .calender__icon {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    left: 10px;
    top: 6px;
  }

  .workout__track__area {
    padding: 0 0 0 7px;
  }

  .workout__exercises {
    width: 100%;
  }

  .exercise__title__in {
    border-right: none;
  }

  .workout__person__details p {
    width: 100%;
    margin: 0 0 2px;
    padding: 0;
    font-size: 15px;
  }

  .event-detail-sec .player-text h3 {
    font-size: 34px;
  }
  .days__title span {
    float: right;
    position: relative;
    top: 6px;
  }

  @media (max-width: 767px) {
    .workout__person__details p {
      width: 50%;
      padding: 0 15px 0 0;
    }

    .days__title span {
      top: 4px;
    }
  }
}
</style>