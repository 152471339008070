<template>
  <section class="why-we-exit-section">
    <div class="container">
      <div class="exit-title">
        <h3>Why <span>FCL Online</span></h3>
      </div>
      <div class="why-boxes">
        <div class="why-boxe-in">
          <div class="why-icon">
            <i class="fa fa-user-plus"></i>
          </div>
          <div class="why-text">
            <h4>Individual Improvement is a Necessity</h4>
            <p>
              Lacrosse is a sport where massive gains can be made in individual
              work. Good players can become great players on their own
            </p>
          </div>
        </div>
        <div class="why-boxe-in">
          <div class="why-icon">
            <i class="fa fa-sync"></i>
          </div>
          <div class="why-text">
            <h4>Digital Tools Let Us Keep the Instruction Fresh</h4>
            <p>
              Keep up with the latest techniques in lacrosse with a tool that
              evolves as the sport does
            </p>
          </div>
        </div>
        <div class="why-boxe-in">
          <div class="why-icon">
            <i class="fa fa-map-signs"></i>
          </div>
          <div class="why-text">
            <h4>For both beginners and the top players</h4>
            <p>
              Our most devoted users range from those just picking up a stick,
              to World Game MVPs
            </p>
          </div>
        </div>
        <div class="why-boxe-in">
          <div class="why-icon">
            <i class="fa fa-dollar-sign"></i>
          </div>
          <div class="why-text">
            <h4>Affordable Access</h4>
            <p>
              For less than $15/month FCL online is one of the most affordable
              ways to improve in the sport of lacrosse
            </p>
          </div>
        </div>
        <div class="why-boxe-in">
          <div class="why-icon">
            <i class="fa fa-mobile"></i>
          </div>
          <div class="why-text">
            <h4>Mobile Friendly</h4>
            <p>
              Take FCL Online directly to the field with you with our mobile
              friendly website for both iOS and Android. On device applications
              coming soon...
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
</template>

<script>
export default {};
</script>

<style>
.why-we-exit-section {
  min-height: 322px;
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 60px 0px 60px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../assets/img/signup.jpg");
}

.exit-title {
  max-width: 1000px;
  margin: 0 auto;
}

.exit-title h3 {
  font-family: "Hemi";
  font-size: 64px;
  font-weight: 900;
  font-style: normal;
  line-height: 1em;
  color: #fff;
  margin: 0 0 18px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
  position: relative;
  padding: 0 0 16px;
}

.exit-title h3 span {
  color: #db3a34;
}

.exit-title h3::after {
  background: #fff;
  content: "";
  width: 50px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.clearfix {
  clear: both;
}

.bottom-pricing-tables {
  width: 100%;
  float: left;
  margin: 25px 0 15px;
}

.why-boxes {
  width: 100%;
  float: left;
  margin: 75px 0 15px;
  display: flex;
}

.why-boxe-in {
  margin: 0 8px;
  border-radius: 7px 7px 7px 7px;
  background-color: #f7f7f7;
  padding: 20px 20px 30px;
  text-align: center;
  position: relative;
  width: 20%;
  cursor: pointer;
}

.why-icon {
  background: #db3a34;
  width: 100px;
  border-radius: 50%;
  margin: -70px auto 0;
  height: 100px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
}

.why-icon i {
  font-size: 50px;
  color: #f7f7f7;
}

.why-text {
  width: 100%;
  float: left;
  margin: 22px 0 0;
}

.why-text h4 {
  color: #000000;
  font-family: "redwingMedium";
  font-size: 21px;
  font-weight: 700;
  margin: 0 0 12px;
  text-transform: uppercase;
  position: relative;
  padding: 0 0 9px;
}

.why-text h4::after {
  background: #000000;
  content: "";
  width: 40px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.why-text p {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 0px;
}

.why-boxe-in {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.why-boxe-in:hover {
  background-color: #db3a34;
  transform: scale(1.2);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 10px 0px 40px 0px rgba(0, 0, 0, 0.5);
  position: relative;
  z-index: 9;
}

.why-boxe-in:hover .why-text h4,
.why-boxe-in:hover .why-text p {
  color: #f7f7f7;
}

.why-boxe-in:hover .why-text h4::after {
  background: #f7f7f7;
}

.why-boxe-in:hover .why-icon {
  background: #f7f7f7;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.why-boxe-in:hover .why-icon i {
  color: #db3a34;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

@media (max-width: 1200px) {
  .why-boxes {
    margin: 75px 0 15px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .why-boxe-in {
    margin: 0 8px 80px;
    width: 23%;
  }

  .why-boxe-in:hover {
    transform: scale(1.1);
  }
}

@media (max-width: 991px) {
  .why-boxe-in {
    width: 46%;
  }
}

@media (max-width: 630px) {
  .why-boxe-in {
    width: 100%;
  }

  .exit-title h3 {
    font-size: 49px;
  }
}
</style>