<template>
  <router-view v-if="!userLoaded" />
  <!-- Start Mobile Top Nav -->
  <nav
    v-if="userLoaded"
    class="navbar navbar-dark navbar-theme-primary px-4 col-12 d-md-none"
  >
    <router-link tag="a" class="navbar-brand mr-lg-5" to="/home">
      <img
        class="navbar-brand-dark"
        src="./assets/img/fcl.png"
        alt="FCL logo"
        @click="isMOpen = false"
      />
    </router-link>
    <div class="d-flex align-items-center">
      <button
        :class="[{ collapsed: isMOpen }, 'd-md-none', 'navbar-toggler']"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        :aria-expanded="isMOpen"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" @click="isMOpen = !isMOpen"></span>
      </button>
    </div>
  </nav>
  <!-- End Mobile Top Nav -->

  <div v-if="userLoaded" class="container-fluid bg-soft">
    <div class="row">
      <div class="col-12">
        <!-- sidebar menu start -->
        <nav
          id="sidebarMenu"
          :class="[
            { show: isMOpen },
            'sidebar',
            { 'simplebar-scrollable-y': isMOpen },
            'sidebar',
            'd-md-block',
            'bg-primary',
            'text-white',
            'collapse',
          ]"
          data-simplebar
        >
          <div class="sidebar-inner px-4 pt-3">
            <router-link tag="img" to="/home">
              <img src="./assets/img/FirstClass-Logo-web.png" alt="FCL logo" />
            </router-link>
            <div
              class="
                user-card
                d-flex d-md-none
                align-items-center
                justify-content-between justify-content-md-center
                pb-4
              "
            >
              <div class="d-flex align-items-center">
                <div class="user-avatar lg-avatar mr-4">
                  <img
                    src="./assets/img/example-propic.jpg"
                    class="card-img-top rounded-circle border-white"
                    alt="Profile Picture"
                  />
                </div>
                <div class="d-block">
                  <h2 class="h6" v-if="userLoaded">
                    Hi, {{ userData.firstName }}
                  </h2>
                  <a
                    @click="handleLogout"
                    class="btn btn-secondary text-dark btn-xs"
                    ><span class="mr-2"
                      ><span class="fas fa-sign-out-alt"></span></span
                    >Sign Out</a
                  >
                </div>
              </div>
              <div class="collapse-close d-md-none" @click="isMOpen = !isMOpen">
                <a
                  href="#sidebarMenu"
                  :class="[{ collapsed: !isMOpen }, 'fa-times', 'fas']"
                  data-toggle="collapse"
                  data-target="#sidebarMenu"
                  aria-controls="sidebarMenu"
                  :aria-expanded="isMOpen"
                  aria-label="Toggle navigation"
                ></a>
              </div>
            </div>
            <ul class="nav flex-column">
              <!-- Dashboard example drop down -->

              <router-link
                to="/home"
                tag="li"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-home"></span
                    ></span>
                    Home
                  </span>
                </a>
              </router-link>
              <router-link
                to="/workouts"
                tag="li"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-dumbbell"></span
                    ></span>
                    Workouts
                  </span>
                </a>
              </router-link>
              <!-- TRAINING VIDEOS LINKS START-->
              <li
                to="/videos/trainingvideos/offense"
                tag="li"
                class="nav-item"
                @click="isVidOpen = !isVidOpen"
                active-class="active"
              >
                <span
                  :class="[
                    { collapsed: !isVidOpen },
                    'align-items-center',
                    'justify-content-between',
                    'd-flex',
                    'nav-link ',
                  ]"
                  data-toggle="collapse"
                  data-target="#submenu-dashboard"
                >
                  <a>
                    <span>
                      <span class="sidebar-icon"
                        ><span class="fas fa-edit"></span
                      ></span>
                      Training Videos
                    </span>
                    <span class="link-arrow"
                      ><span
                        :class="[
                          { 'fa-chevron-down': isVidOpen },
                          { 'fa-chevron-right': !isVidOpen },
                          'fas',
                        ]"
                      ></span
                    ></span>
                  </a>
                </span>

                <!-- Sub categories  -->
                <div
                  :class="[{ show: isVidOpen }, 'collapse', 'multi-level']"
                  role="list"
                  id="submenu-dashboard"
                  :aria-expanded="isVidOpen"
                >
                  <ul class="flex-column nav">
                    <router-link
                      to="/videos/trainingvideos/offense"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Offense </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/trainingvideos/defense"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Defense </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/trainingvideos/faceoff"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Faceoff </span>
                      </a>
                    </router-link>
                  </ul>
                </div>
              </li>
              <!-- TRAINING VIDEOS LINKS End-->

              <!-- Instructional VIDEOS LINKS START-->
              <li
                to="/profiles"
                tag="li"
                class="nav-item"
                @click="isisvVidOpen = !isisvVidOpen"
                active-class="active"
              >
                <span
                  :class="[
                    { collapsed: !isisvVidOpen },
                    'align-items-center',
                    'justify-content-between',
                    'd-flex',
                    'nav-link ',
                  ]"
                  data-toggle="collapse"
                  data-target="#submenu-dashboard"
                >
                  <a>
                    <span>
                      <span class="sidebar-icon"
                        ><span class="fas fa-chalkboard-teacher"></span
                      ></span>
                      Instruction Videos
                    </span>
                    <span class="link-arrow"
                      ><span
                        :class="[
                          { 'fa-chevron-down': isisvVidOpen },
                          { 'fa-chevron-right': !isisvVidOpen },
                          'fas',
                        ]"
                      ></span
                    ></span>
                  </a>
                </span>

                <!-- Sub categories  -->
                <div
                  :class="[{ show: isisvVidOpen }, 'collapse', 'multi-level']"
                  role="list"
                  id="submenu-dashboard"
                  :aria-expanded="isisvVidOpen"
                >
                  <ul class="flex-column nav">
                    <router-link
                      to="/videos/instructionvideos/offense"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Offense </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/instructionvideos/defense"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Defense </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/instructionvideos/faceoff"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Faceoff </span>
                      </a>
                    </router-link>
                  </ul>
                </div>
              </li>
              <!-- Instructional VIDEOS LINKS End-->

              <!-- Film Breakdown VIDEOS LINKS START-->
              <li
                to="/filmbreakdowns"
                tag="li"
                class="nav-item"
                @click="isWfbVidOpen = !isWfbVidOpen"
                active-class="active"
              >
                <span
                  :class="[
                    { collapsed: !isWfbVidOpen },
                    'align-items-center',
                    'justify-content-between',
                    'd-flex',
                    'nav-link ',
                  ]"
                  data-toggle="collapse"
                  data-target="#submenu-dashboard"
                >
                  <a>
                    <span>
                      <span class="sidebar-icon"
                        ><span class="fas fa-film"></span
                      ></span>
                      Film Breakdowns
                    </span>
                    <span class="link-arrow"
                      ><span
                        :class="[
                          { 'fa-chevron-down': isWfbVidOpen },
                          { 'fa-chevron-right': !isWfbVidOpen },
                          'fas',
                        ]"
                      ></span
                    ></span>
                  </a>
                </span>

                <!-- Sub categories  -->
                <div
                  :class="[{ show: isWfbVidOpen }, 'collapse', 'multi-level']"
                  role="list"
                  id="submenu-dashboard"
                  :aria-expanded="isWfbVidOpen"
                >
                  <ul class="flex-column nav">
                    <router-link
                      to="/videos/filmbreakdowns/offense"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Offense </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/filmbreakdowns/defense"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Defense </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/filmbreakdowns/faceoff"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Faceoff </span>
                      </a>
                    </router-link>
                  </ul>
                </div>
              </li>
              <!-- Film Breakdown LINKS End-->

              <!-- Webinars VIDEOS LINKS START-->
              <li
                to="/webinars"
                tag="li"
                class="nav-item"
                @click="isWebVidOpen = !isWebVidOpen"
                active-class="active"
              >
                <span
                  :class="[
                    { collapsed: !isWebVidOpen },
                    'align-items-center',
                    'justify-content-between',
                    'd-flex',
                    'nav-link ',
                  ]"
                  data-toggle="collapse"
                  data-target="#submenu-dashboard"
                >
                  <a>
                    <span>
                      <span class="sidebar-icon"
                        ><span class="fas fa-users"></span
                      ></span>
                      Webinars
                    </span>
                    <span class="link-arrow"
                      ><span
                        :class="[
                          { 'fa-chevron-down': isWebVidOpen },
                          { 'fa-chevron-right': !isWebVidOpen },
                          'fas',
                        ]"
                      ></span
                    ></span>
                  </a>
                </span>

                <!-- Sub categories  -->
                <div
                  :class="[{ show: isWebVidOpen }, 'collapse', 'multi-level']"
                  role="list"
                  id="submenu-dashboard"
                  :aria-expanded="isWebVidOpen"
                >
                  <ul class="flex-column nav">
                    <router-link
                      to="/videos/webinars/offense"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Offense </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/webinars/defense"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Defense </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/webinars/faceoff"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Faceoff </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/webinars/mobility"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Mobility </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/videos/webinars/journey"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Journey </span>
                      </a>
                    </router-link>
                  </ul>
                </div>
              </li>
              <!-- Webinars VIDEOS LINKS End-->
              <!-- Event Registration LINKS START-->
              <li
                to="/filmbreakdowns"
                tag="li"
                class="nav-item"
                @click="isEventRegOpen = !isEventRegOpen"
                active-class="active"
              >
                <span
                  :class="[
                    { collapsed: !isEventRegOpen },
                    'align-items-center',
                    'justify-content-between',
                    'd-flex',
                    'nav-link ',
                  ]"
                  data-toggle="collapse"
                  data-target="#submenu-dashboard"
                >
                  <a>
                    <span>
                      <span class="sidebar-icon"
                        ><span class="fas fa-calendar-alt"></span
                      ></span>
                      Event Registration
                    </span>
                    <span class="link-arrow"
                      ><span
                        :class="[
                          { 'fa-chevron-down': isEventRegOpen },
                          { 'fa-chevron-right': !isEventRegOpen },
                          'fas',
                        ]"
                      ></span
                    ></span>
                  </a>
                </span>

                <!-- Sub categories  -->
                <div
                  :class="[{ show: isEventRegOpen }, 'collapse', 'multi-level']"
                  role="list"
                  id="submenu-dashboard"
                  :aria-expanded="isEventRegOpen"
                >
                  <ul class="flex-column nav">
                    <router-link
                      to="/events"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> Training Events </span>
                      </a>
                    </router-link>

                    <router-link
                      to="/livereps"
                      tag="li"
                      class="nav-item"
                      @click="isMOpen = false"
                    >
                      <a class="nav-link">
                        <span> LiveRep Events </span>
                      </a>
                    </router-link>
                  </ul>
                </div>
              </li>
              <router-link
                to="/courses"
                tag="li"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><i class="fas fa-book-reader"></i
                    ></span>
                    FCL Courses
                    <span class="badge badge-md bg-secondary ml-1 text-dark"
                      >New!</span
                    >
                  </span>
                </a>
              </router-link>

              <router-link
                to="/fcllive/calendar"
                tag="li"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-microphone"></span
                    ></span>
                    FCL Live
                  </span>
                </a>
              </router-link>
              <router-link
                to="/bestinclass"
                tag="li"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-award"></span
                    ></span>
                    Best In Class
                  </span>
                </a>
              </router-link>
              <router-link
                to="/merchandise"
                tag="li"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-store"></span
                    ></span>
                    FCL Merch</span
                  >
                </a>
              </router-link>

              <a
                href="https://1stclasslax.com/blog"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-book-open"></span
                    ></span>
                    Blog
                  </span>
                </a>
              </a>
              <!-- Film Event Registration LINKS End-->

              <!-- Dashboard example drop down end-->
              <li
                role="separator"
                class="dropdown-divider mt-4 mb-3 border-black"
              ></li>
              <!-- Messages example drop down -->
              <!-- <li class="nav-item">
                <a
                  href="../../pages/messages.html"
                  class="nav-link d-flex align-items-center justify-content-between"
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-inbox"></span
                    ></span>
                    Messages
                  </span>
                  <span class="badge badge-md bg-danger badge-pill">4</span>
                </a>
              </li> -->
              <!-- Messages example drop down End-->
              <!-- <router-link
                to="/about"
                tag="li"
                class="nav-item"
                active-class="active"
              >
                <a
                  class="nav-link d-flex align-items-center justify-content-between"
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-info-circle"></span
                    ></span>
                    About FCL Online
                  </span>
                </a>
              </router-link> -->
              <router-link
                v-if="userData.usrType == 'pm'"
                to="/program-manager"
                tag="li"
                class="nav-item"
                @click="isMOpen = false"
                active-class="active"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-users"></span
                    ></span>
                    Manage Program
                  </span>
                </a>
              </router-link>

              <router-link
                to="/profile"
                tag="li"
                class="nav-item"
                @click="isMOpen = false"
                active-class="active"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-user"></span
                    ></span>
                    Profile
                  </span>
                </a>
              </router-link>

              <router-link
                to="/support"
                tag="li"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-life-ring"></span
                    ></span>
                    Support
                  </span>
                </a>
              </router-link>
              <router-link
                to="/feedback"
                tag="li"
                class="nav-item"
                active-class="active"
                @click="isMOpen = false"
              >
                <a
                  class="
                    nav-link
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <span>
                    <span class="sidebar-icon"
                      ><span class="fas fa-comments"></span
                    ></span>
                    Feedback
                  </span>
                </a>
              </router-link>
            </ul>
          </div>
        </nav>

        <main class="content">
          <!-- Top Nav Bar -->
          <horizontalNav />
          <router-view />

          <!-- Footer vue component -->
          <footerNav />
        </main>
      </div>
    </div>
    <!-- main conent end -->
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useLogout from "./composables/useLogout";
import footerNav from "./components/DashboardComponents/footerNav.vue";
import horizontalNav from "./components/DashboardComponents/horizontalNav.vue";
import getUser from "./composables/getUser";
import { computed, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { projectAuth } from "./firebase/config";

export default {
  components: { footerNav, horizontalNav },
  setup() {
    console.log("THIS ENVIORNMENT IS: " + process.env.VUE_APP_ENV);
    const { logout } = useLogout();
    const router = useRouter();
    const store = useStore();
    store.dispatch("authChange");
    const userLoaded = computed(() => store.state.userDataLoaded);
    const userData = computed(() => store.state.userData);
    watchEffect(() => {
      if (store.getters.getUserDataLoaded) {
        //All the User data has loaded we can load all the others
        if (!store.state.fclCoursesDataLoaded) {
          store.dispatch("acceptCourses");
        }

        if (!store.state.fclLiveEventDataLoaded) {
          store.dispatch("acceptLiveEvents");
        }
        if (store.getters.getIsPremium) {
          if (!store.state.videoDataLoaded) {
            store.dispatch("acceptVids");
          }
          if (!store.state.workoutDataLoaded) {
            store.dispatch("acceptWorkouts");
          }
          if (!store.state.exerciseDataLoaded) {
            store.dispatch("acceptExercises");
          }
        }
      }
    });
    let isMOpen = ref(false);
    let isVidOpen = ref(false);
    let isWebVidOpen = ref(false);
    let isWfbVidOpen = ref(false);
    let isisvVidOpen = ref(false);
    let isEventRegOpen = ref(false);
    let isFCLLiveOpen = ref(false);
    const curPage = ref();
    const { user } = getUser();

    //When ever the user authentication state changes write the user to vuex.

    projectAuth.onAuthStateChanged((user) => {
      if (user) {
        store.dispatch("setUser", user);
      } else {
        store.dispatch("setUser", null);
      }
    });

    const handleLogout = async () => {
      console.log("user logged out HERE1");
      store.dispatch("logout");
      await logout();
      console.log("user logged out HERE2");
      router.push({ name: "Login" });
    };

    return {
      isMOpen,
      isVidOpen,
      user,
      curPage,
      isWebVidOpen,
      isWfbVidOpen,
      isisvVidOpen,
      document,
      store,
      userData,
      userLoaded,
      isEventRegOpen,
      isFCLLiveOpen,
      handleLogout,
    };
  },
};
</script>

<style></style>
