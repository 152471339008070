import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

const useCollection = () => {
  const error2 = ref(null);
  const isPending2 = ref(false);

  // add a new document
  const addDoc = async (uid, doc) => {
    error2.value = null;
    isPending2.value = true;
    try {
      await projectFirestore
        .collection("fclUsers")
        .doc(uid)
        .collection("registrationProfiles")
        .add(doc);

      isPending2.value = false;
    } catch (err) {
      console.log(err.message);
      error2.value = err.message;
      isPending2.value = false;
    }
  };

  return { error2, addDoc, isPending2 };
};

export default useCollection;
