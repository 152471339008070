<template>
  <div class="row">
    <div class="col-12 col-xl-12 mb-4">
      <div class="row">
        <div class="col-12 col-sm-3 mb-4 mb-md-0">
          <div class="card border-light shadow-sm">
            <div class="card-body">
              <h2 class="h5">3-Day Workouts Completed</h2>
              <h3 class="h2 mb-1" style="text-align: center">
                {{ getWorkoutsCompleted(wh) }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-3 mb-4 mb-md-0">
          <div class="card border-light shadow-sm">
            <div class="card-body">
              <h2 class="h5">3-Day Workouts Started</h2>
              <h3 class="h2 mb-1" style="text-align: center">
                {{ wh.length }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-3 mb-4 mb-md-0">
          <div class="card border-light shadow-sm">
            <div class="card-body">
              <h2 class="h5">1-Day Workouts Completed</h2>
              <h3 class="h2 mb-1" style="text-align: center">
                {{ getWorkoutDaysCompleted(wh) }}
              </h3>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-3 mb-4 mb-md-0">
          <div class="card border-light shadow-sm">
            <div class="card-body">
              <h2 class="h5">Exercises Completed</h2>
              <h3 class="h2 mb-1" style="text-align: center">
                {{ getExercisesCompleted(wh) }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="userLoaded && userData.usrType != 'pm'">
    <h1>Access Denied</h1>
  </div>
  <div v-if="wh.length == 0">
    <p>This is player has not started any workouts.</p>
  </div>
  <div
    v-if="
      userLoaded &&
      userData.usrType == 'pm' &&
      wdLoaded &&
      workoutsLoaded &&
      wh.length > 0
    "
  >
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card border-light shadow-sm">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-centered table-nowrap mb-0 rounded">
                <thead class="thead-light">
                  <tr>
                    <th class="border-0">#</th>
                    <th class="border-0">Workout Name</th>
                    <th class="border-0">Workout Completed</th>
                    <th class="border-0">Days Completed</th>
                    <th class="border-0">Days Worked</th>
                    <th class="border-0">Exercises Completed</th>
                    <th class="border-0">Unfinished Exercises</th>
                    <th class="border-0">Percent Complete</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Item -->
                  <tr v-for="workout in wh" :key="workout.id">
                    <td class="border-0">
                      <a href="#" class="text-primary font-weight-bold">1</a>
                    </td>
                    <td class="border-0 font-weight-bold">
                      {{ getWorkoutName(workout.id) }}
                    </td>
                    <td class="border-0">
                      <span
                        v-if="getWorkoutCompletedIndiv(workout)"
                        class="fas fa-check-circle"
                      ></span
                      ><span
                        v-if="!getWorkoutCompletedIndiv(workout)"
                        class="text-danger fas fa-times-circle"
                      ></span>
                    </td>
                    <td class="border-0">
                      {{ getWorkoutsCompletedIndiv(workout) }}
                    </td>
                    <td class="border-0">
                      {{ getWorkoutsStartedIndiv(workout) }}
                    </td>
                    <td class="border-0">
                      {{ getExercisesCompletedIndiv(workout) }}
                    </td>
                    <td class="border-0">
                      {{
                        getTotalExerciese(workout) -
                        getExercisesCompletedIndiv(workout)
                      }}
                    </td>
                    <td class="border-0">
                      <div class="row d-flex align-items-center">
                        <div class="col-12 col-xl-2 px-0">
                          <div class="small font-weight-bold">
                            {{
                              Math.round(
                                (getExercisesCompletedIndiv(workout) /
                                  getTotalExerciese(workout)) *
                                  100
                              )
                            }}%
                          </div>
                        </div>
                        <div class="col-12 col-xl-10 px-0 px-xl-1">
                          <div class="progress progress-lg mb-0">
                            <div
                              class="progress-bar bg-primary"
                              role="progressbar"
                              :aria-valuenow="
                                (getExercisesCompletedIndiv(workout) /
                                  getTotalExerciese(workout)) *
                                100
                              "
                              aria-valuemin="0"
                              aria-valuemax="100"
                              :style="
                                'width:' +
                                (getExercisesCompletedIndiv(workout) /
                                  getTotalExerciese(workout)) *
                                  100 +
                                '%'
                              "
                            ></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!-- End of Item -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loadingSpinner v-if="isLoading" />
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import loadingSpinner from "../../components/loadingSpinner.vue";
import getWH from "../../composables/getWorkoutHistory";

export default {
  components: { loadingSpinner },
  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const route = useRoute();
    const userId = route.params.uid;
    //user data
    const userLoaded = computed(() => store.state.userDataLoaded);
    const userData = computed(() => store.state.userData);
    //workout Data
    const workoutsLoaded = computed(() => store.state.workoutDataLoaded);
    const workoutLoad = computed(() => store.getters.getAllWorkouts);
    //Exercise Data
    const exercisesLoaded = computed(() => store.state.exerciseDataLoaded);
    const exerciseLoad = computed(() => store.state.exerciseData);
    //Workout Data
    const wdLoaded = ref(false);
    const { wh, error3, load3 } = getWH(userId);
    load3().then(() => {
      if (!error3.value) {
        wdLoaded.value = true;
        isLoading.value = false;
      } else {
        console.log(error3.value);
      }
    });

    const getWorkoutName = (id) => {
      for (let i = 0; i < workoutLoad.value.length; i++) {
        if (workoutLoad.value[i].id == id) {
          return workoutLoad.value[i].workoutName;
        }
      }
    };
    let ExerciseId = 0;

    const getExerciseNameName = (id, index, day) => {
      ExerciseId = 0;
      for (let i = 0; i < workoutLoad.value.length; i++) {
        if (workoutLoad.value[i].id == id) {
          if (day == 1) {
            ExerciseId = workoutLoad.value[i].day1[index].id;
          }
          if (day == 2) {
            ExerciseId = workoutLoad.value[i].day2[index].id;
          }
          if (day == 3) {
            ExerciseId = workoutLoad.value[i].day3[index].id;
          }
        }

        for (let j = 0; j < exerciseLoad.value.length; j++) {
          if (exerciseLoad.value[j].id == ExerciseId) {
            return exerciseLoad.value[j].exerciseName;
          }
        }
      }

      return false;
    };

    const getWorkoutsCompleted = (workouts) => {
      if (workouts.length == 0) {
        return 0;
      } else {
        let count = 0;
        workouts.forEach((workout) => {
          if (workout.day1.includes(false) == false) {
            if (workout.day2.includes(false) == false) {
              if (workout.day3.includes(false) == false) {
                count++;
              }
            }
          }
        });
        return count;
      }
    };

    const getWorkoutsCompletedIndiv = (workout) => {
      let count = 0;
      if (workout.day1.includes(false) == false) {
        count++;
        if (workout.day2.includes(false) == false) {
          count++;
          if (workout.day3.includes(false) == false) {
            count++;
          }
        }
      }
      return count;
    };
    const getWorkoutCompletedIndiv = (workout) => {
      let comp = false;
      if (workout.day1.includes(false) == false) {
        if (workout.day2.includes(false) == false) {
          if (workout.day3.includes(false) == false) {
            comp = true;
          }
        }
      }
      return comp;
    };
    const getWorkoutsStartedIndiv = (workout) => {
      let count = 0;
      if (workout.day1.includes(true) == true) {
        count++;
        if (workout.day2.includes(true) == true) {
          count++;
          if (workout.day3.includes(true) == true) {
            count++;
          }
        }
      }
      return count;
    };

    const getWorkoutDaysCompleted = (workouts) => {
      if (workouts.length == 0) {
        return 0;
      } else {
        let count = 0;
        workouts.forEach((workout) => {
          if (workout.day1.includes(false) == false) {
            count++;
          }
          if (workout.day2.includes(false) == false) {
            count++;
          }
          if (workout.day3.includes(false) == false) {
            count++;
          }
        });
        return count;
      }
    };
    const getExercisesCompleted = (workouts) => {
      if (workouts.length == 0) {
        return 0;
      } else {
        let count = 0;
        workouts.forEach((workout) => {
          workout.day1.forEach((excercise) => {
            if (excercise == true) {
              count++;
            }
          });
          workout.day2.forEach((excercise) => {
            if (excercise == true) {
              count++;
            }
          });
          workout.day3.forEach((excercise) => {
            if (excercise == true) {
              count++;
            }
          });
        });
        return count;
      }
    };
    const getExercisesCompletedIndiv = (workout) => {
      let count = 0;
      workout.day1.forEach((excercise) => {
        if (excercise == true) {
          count++;
        }
      });
      workout.day2.forEach((excercise) => {
        if (excercise == true) {
          count++;
        }
      });
      workout.day3.forEach((excercise) => {
        if (excercise == true) {
          count++;
        }
      });
      return count;
    };

    const getTotalExerciese = (workout) => {
      let count = 0;
      workout.day1.forEach((excercise) => {
        count++;
      });
      workout.day2.forEach((excercise) => {
        count++;
      });
      workout.day3.forEach((excercise) => {
        count++;
      });
      return count;
    };

    return {
      getExercisesCompleted,
      getWorkoutDaysCompleted,
      getWorkoutsCompleted,
      isLoading,
      userLoaded,
      userData,
      wdLoaded,
      wh,
      workoutsLoaded,
      getWorkoutName,
      getExerciseNameName,
      getExercisesCompleted,
      getExercisesCompletedIndiv,
      getTotalExerciese,
      getWorkoutsCompletedIndiv,
      getWorkoutsStartedIndiv,
      getWorkoutCompletedIndiv,
    };
  },
};
</script>

<style>
</style>