<template>
  <footer class="footer section py-5">
    <div class="row">
      <div class="col-12 col-lg-6 mb-4 mb-lg-0">
        <p class="mb-0 text-center text-xl-left">
          Copyright © 2021-<span class="current-year"></span>
          <a
            class="text-primary font-weight-normal"
            href="/about"
            target="_blank"
            >First Class Lacrosse</a
          >
        </p>
      </div>

      <div class="col-12 col-lg-6">
        <!-- List -->
        <ul
          class="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0"
        >
          <li class="list-inline-item px-0 px-sm-2">
            <router-link to="/support"> Support</router-link>
          </li>
          <li class="list-inline-item px-0 px-sm-2">
            <router-link to="/privacypolicy"> Privacy Policy</router-link>
          </li>
          <li class="list-inline-item px-0 px-sm-2">
            <router-link to="/termsandconditions">
              Terms &amp; Conditions</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- footer section end -->
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>