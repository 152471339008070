<template>
  <loadingSpinner v-if="isLoading" />
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ref } from "@vue/reactivity";
import axios from "axios";

import loadingSpinner from "../../components/loadingSpinner.vue";
import { computed, watchEffect } from "@vue/runtime-core";
export default {
  components: { loadingSpinner },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isLoading = ref(true);

    const interval = route.params.interval;
    const userData = computed(() => store.state.userData);
    const userDataLoaded = computed(() => store.state.userDataLoaded);
    const quarterly = "price_1IqrGhINbM8Ghj39eIhpgM4I";
    const semiYearly = "price_1IqrPTINbM8Ghj39tFXq2jAZ";
    const yearly = "price_1IqrPTINbM8Ghj39qSrR8gcc";
    let stripe = null;
    let functionURL = null;
    let sessionID = "";
    if (process.env.VUE_APP_ENV == "test") {
      stripe = Stripe(
        "pk_test_51IqpudINbM8Ghj39JofFXCMtsRwKaDSCT8oIENe7GfBM2W0snnzbJgVtEShhL4KnRtwfcHSgzTBhd5m4WfzfhYvy00XEXWd9H1"
      );
      functionURL =
        "https://us-central1-fcl-online-dev.cloudfunctions.net/getSubscriptionTrial";
    } else {
      stripe = Stripe(
        "pk_live_51IqpudINbM8Ghj39Bg3LwWn3JyCEKocMuGysh8I9iewXNPRrz7NbtH7ZsG2K3gpC5Su7o3AtbHpH4vIoVOcRYKtn00xPMXB0AP"
      );
      functionURL =
        "https://us-central1-first-class-lax-c0894.cloudfunctions.net/getSubscriptionTrial";
    }

    const payCheckout = () => {
      if (userData.value.stripeId != null) {
        isLoading.value = true;
        axios
          .get(functionURL, {
            params: { id: userData.value.stripeId },
          })
          .then((response) => {
            sessionID = response.data.id;
            stripe
              .redirectToCheckout({
                sessionId: sessionID,
              })
              .then(function (result) {
                isLoading.value = false;
                if (result.error) {
                  alert(result.error);
                }
              });
          })
          .catch((error) => {
            console.log(error);
            isLoading.value = false;
          });
      } else {
        alert(
          "Error: User Missing Stripe ID. Please contact Support at contact@1stclasslax.com"
        );
      }
    };

    watchEffect(() => {
      if (store.getters.getUserDataLoaded) {
        payCheckout();
      }
    });

    return { interval, isLoading };
  },
};
</script>

<style></style>
