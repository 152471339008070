import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getSingleDoc = (col, id) => {
  const Doc = ref([]);
  const sdError = ref(null);

  const loadSD = async () => {
    try {
      const res = await projectFirestore
        .collection(col)
        .doc(id)
        .get();

      Doc.value = { ...res.data(), id: res.id };
    } catch (err) {
      console.log(err.message);
      sdError.value = err.message;
    }
  };

  return { Doc, sdError, loadSD };
};

export default getSingleDoc;
