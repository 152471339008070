import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

const updateCollection = (collection) => {
  const errorUp = ref(null);
  const isPending = ref(false);

  // add a new document
  const updateDoc = async (id, doc) => {
    errorUp.value = null;
    isPending.value = true;
    try {
      await projectFirestore
        .collection(collection)
        .doc(id)
        .update(doc);
      isPending.value = false;
    } catch (err) {
      console.log(err.message);
      errorUp.value = "could not update the document " + err.message;
      isPending.value = false;
    }
  };

  return { errorUp, updateDoc, isPending };
};

export default updateCollection;
