import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getUserData = (k) => {
  const userData = ref([]);
  const errorUser = ref(null);

  const loadUser = async () => {
    try {
      const res = await projectFirestore
        .collection("fclUsers")
        .doc(k)
        .get();

      userData.value = { ...res.data(), id: res.id };
    } catch (err) {
      console.log(err.message);
      errorUser.value = err.message;
    }
  };

  return { userData, errorUser, loadUser };
};

export default getUserData;
