<template>
  <br />
  <loadingSpinner v-if="isLoading" />

  <div v-if="programDataLoaded && subDataLoaded">
    <eventSectionTitle
      v-if="programData.status == 'active'"
      :title="programData.name"
    />
    <br />
    <!-- START -->
    <!-- TOP ROW **NON** - RESELL PROGRAMS -->
    <!-- START -->

    <div class="row" v-if="programData.isResell == true">
      <div
        class="col-12 col-xl-4 mb-4"
        v-if="programData.status == 'active' && programMemberDataLoaded"
      >
        <div class="card border-light shadow-sm text-center py-4 mb-4 mb-lg-0">
          <div class="card-body">
            <h2 class="display-4 mb-3">Program Access Request Code:</h2>

            <small>{{ programData.id }}</small> <br />
          </div>

          <p>
            This is your programs unique access code. Your players can enter
            this into their profile section to pay for their program access.
            Your funds will automatically be added to your stripe account
          </p>
        </div>
      </div>
      <!-- END -->
      <div
        class="col-12 col-xl-4 mb-4"
        v-if="programData.status == 'active' && programMemberDataLoaded"
      >
        <div class="card border-light shadow-sm text-center py-4 mb-4 mb-lg-0">
          <div class="card-body">
            <h2 class="display-4 mb-3">Manage Program:</h2>
            <form
              :action="
                'https://dashboard.stripe.com/' +
                programData.connect +
                '/dashboard'
              "
            >
              <input
                type="submit"
                style="margin-top: 10px"
                value="View Program Stripe Dashboard"
                class="btn btn-sm btn-dark mr-2"
              />
            </form>
          </div>
        </div>
      </div>

      <!-- END -->
      <div
        class="col-12 col-xl-4 mb-4"
        v-if="programData.status == 'active' && programMemberDataLoaded"
      >
        <div class="card border-light shadow-sm text-center py-4 mb-4 mb-lg-0">
          <div class="card-body">
            <h2 class="display-4 mb-3">Program Roster:</h2>

            <h4>{{ programMemberData.length }} Current Members</h4>
          </div>
        </div>
      </div>
      <!-- END -->
    </div>

    <!-- END -->
    <!-- TOP ROW **NON** - RESELL PROGRAMS -->
    <!-- END -->

    <!-- START -->
    <!-- TOP ROW **NON** - RESELL PROGRAMS -->
    <!-- START -->
    <div class="row" v-if="programData.isResell == false">
      <div
        class="col-12 col-xl-4 mb-4"
        v-if="programData.status == 'active' && programMemberDataLoaded"
      >
        <div class="card border-light shadow-sm text-center py-4 mb-4 mb-lg-0">
          <div class="card-body">
            <h2 class="display-4 mb-3">Program Access Request Code:</h2>

            <small>{{ programData.id }}</small> <br />
          </div>

          <p>
            This is your programs unique access code. Your players can enter
            this into their profile section to request access to this program.
          </p>
        </div>
      </div>
      <!-- END -->
      <div
        class="col-12 col-xl-4 mb-4"
        v-if="programData.status == 'active' && programMemberDataLoaded"
      >
        <div class="card border-light shadow-sm text-center py-4 mb-4 mb-lg-0">
          <div class="card-body">
            <h2 class="display-4 mb-3">Manage Program:</h2>
            <button
              style="margin-top: 10px"
              class="btn btn-sm btn-dark mr-2"
              @click="cancelSub"
            >
              Cancel Subscription
            </button>
            <button
              style="margin-top: 10px"
              class="btn btn-sm btn-dark mr-2"
              @click="cancelSub"
            >
              Change Payment Method
            </button>
            <button
              style="margin-top: 10px"
              class="btn btn-sm btn-dark mr-2"
              @click="adjustPlayerCount"
            >
              Adjust Player Count
            </button>
          </div>
        </div>
      </div>
      <!-- END -->
      <div
        class="col-12 col-xl-4 mb-4"
        v-if="programData.status == 'active' && programMemberDataLoaded"
      >
        <div class="card border-light shadow-sm text-center py-4 mb-4 mb-lg-0">
          <div class="card-body">
            <h2 class="display-4 mb-3">Program Roster:</h2>

            <h4>
              {{ programMemberData.length }} Current Members |
              {{ subCount - programMemberData.length }} Seats Available
            </h4>
          </div>
        </div>
      </div>
      <!-- END -->
    </div>

    <!-- END -->
    <!-- TOP ROW **NON** - RESELL PROGRAMS -->
    <!-- END -->

    <!-- If the Program has not signed up for stripe yet START-->

    <div
      class="row"
      v-if="
        programData.status == 'setup' &&
        subCount <= 1 &&
        programData.isResell == false
      "
    >
      <div class="col-12 col-xl-2 mb-4"></div>
      <div class="col-12 col-xl-8 mb-4">
        <div class="card card-body bg-white border-light shadow-sm mb-4">
          <h2 class="h5 mb-4">Welcome to your new Program</h2>
          <div class="row">
            <div class="row">
              <div class="col-md-12 mb-3">
                <div class="form-group">
                  <label for="phone">Program User Quantity</label>
                  <input
                    class="form-control"
                    id="phone"
                    type="number"
                    placeholder="Enter the number of seats that you need..."
                    v-model="seatCount"
                  />
                </div>
              </div>
            </div>
            <button
              class="btn btn-primary btn-dark mt-2 animate-up-2"
              @click="payCheckout(500)"
            >
              Purchase Program Access
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- If the Program has not signed up for stripe yet END-->

    <!-- If the program has signed up through Pstripe but has not made a profile of data START -->
    <div
      class="row"
      v-if="
        programData.status == 'setup' &&
        (subCount >= 1 || programData.isResell == true)
      "
    >
      <div class="col-12 col-xl-12 mb-4">
        <div class="card card-body bg-white border-light shadow-sm mb-4">
          <h2 class="h5 mb-4">
            Please enter the following information to finish setting up your
            program!
          </h2>
          <div class="row">
            <div class="col-md-4 mb-3">
              <div>
                <label for="programName">Program Name</label>
                <input
                  class="form-control"
                  id="programName"
                  type="text"
                  placeholder="Program Name"
                  v-model="programName"
                  required
                />
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div>
                <label for="programType">Program Type</label>
                <select
                  class="form-select mb-0"
                  id="programType"
                  aria-label="Program"
                  v-model="programType"
                >
                  <option value="Club Team">Club Team</option>
                  <option value="Recreation">Recreation</option>
                  <option value="School Team">School Team</option>
                </select>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div>
                <label for="programDomainName">Program Domain Name</label>
                <input
                  class="form-control"
                  id="programDomainName"
                  type="text"
                  placeholder="www.1stclasslax.com"
                  v-model="programDomainName"
                  required
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="programDescription">Program Description</label>
              <textarea
                v-model="programDescription"
                class="form-control"
                placeholder="The XYZ National program is an invitation only roster with a player pool that is comprised of the top athletes...."
                id="programDescription"
                rows="4"
              ></textarea>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-4 mb-3">
              <label for="programGender">Gender Focus</label>
              <select
                class="form-select mb-0"
                id="programGender"
                aria-label="Gender select example"
                v-model="programGender"
              >
                <option value="female">Men's Lacrosse</option>
                <option value="male">Women's Lacrosse</option>
                <option value="both">Men's and Women's Lacrosse</option>
              </select>
            </div>
            <div class="col-md-4 mb-3">
              <label for="programRegion">Region</label>
              <select
                class="form-select mb-0"
                id="programRegion"
                aria-label="Gender select example"
                v-model="programRegion"
              >
                <option value="female">Mid Atlantic</option>
                <option value="male">South</option>
                <option value="both">Midwest</option>
                <option value="both">West Coast</option>
              </select>
            </div>
            <div class="col-md-4 mb-3">
              <label for="programStyle">Program Style</label>
              <select
                class="form-select mb-0"
                id="programStyle"
                aria-label="Gender select example"
                v-model="programStyle"
              >
                <option value="field">Field</option>
                <option value="box">Box</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-3">
              <div>
                <label for="programHeadName"
                  >Program Head First/Last Name</label
                >
                <input
                  class="form-control"
                  id="programHeadName"
                  type="text"
                  placeholder="First & Last Name"
                  v-model="programHeadName"
                  required
                />
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label for="programHeadEmail">Program Head Email</label>
                <input
                  class="form-control"
                  id="programHeadEmail"
                  type="email"
                  placeholder="name@company.com"
                  v-model="programHeadEmail"
                />
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label for="programHeadPhone">Program Head Phone</label>
                <input
                  class="form-control"
                  id="programHeadPhone"
                  type="tel"
                  placeholder="xxx-xxx-xxxx"
                  v-model="programHeadPhone"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-3">
              <div>
                <label for="programAdminName"
                  >Program Admin First/Last Name</label
                >
                <input
                  class="form-control"
                  id="programAdminName"
                  type="text"
                  placeholder="First & Last Name"
                  v-model="programAdminName"
                  required
                />
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label for="programAdminEmail">Program Admin Email</label>
                <input
                  class="form-control"
                  id="programAdminEmail"
                  type="email"
                  placeholder="name@company.com"
                  v-model="programAdminEmail"
                />
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="form-group">
                <label for="programAdminPhone">Program Admin Phone</label>
                <input
                  class="form-control"
                  id="programAdminPhone"
                  type="tel"
                  placeholder="xxx-xxx-xxxx"
                  v-model="programAdminPhone"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <div>
                <label for="programPlayerCount">Number of Teams</label>
                <input
                  class="form-control"
                  id="programPlayerCount"
                  type="text"
                  placeholder="5"
                  v-model="programPlayerCount"
                  required
                />
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div>
                <label for="programTeamCount">Number of Players</label>
                <input
                  class="form-control"
                  id="programTeamCount"
                  type="number"
                  placeholder="150"
                  v-model="programTeamCount"
                  required
                />
              </div>
            </div>
          </div>
          <button
            class="btn btn-primary btn-dark mt-2 animate-up-2"
            @click="sendProgramDoc"
          >
            Launch FCL Online Program
          </button>
        </div>
      </div>
    </div>
    <!-- If the program has signed up through stripe but has not made a profile of data END -->

    <!-- Current User  Table START-->

    <div
      class="
        card card-body
        border-light
        shadow-sm
        table-wrapper table-responsive
        pt-0
      "
      v-if="programData.status == 'active' && programMemberDataLoaded"
    >
      <br />
      <h2>Your Current Users</h2>
      <button
        style="margin-top: 10px"
        class="btn btn-sm btn-dark mr-2"
        @click="downloadPlayerData()"
      >
        Download All Player Data
      </button>
      <br />
      <div v-if="programMemberData.length == 0">
        - You do not currently have any Members -
      </div>
      <table
        class="table user-table table-hover align-items-center"
        v-if="programMemberData.length > 0"
      >
        <thead>
          <tr>
            <th class="border-bottom">Name</th>
            <th class="border-bottom">Remove</th>
            <th class="border-bottom">View Activity</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="requestor in programMemberData" :key="requestor.id">
            <td>
              <a href="#" class="d-flex align-items-center">
                <div class="d-block">
                  <span class="font-weight-bold"
                    >{{ requestor.firstName }} {{ requestor.lastName }}</span
                  >
                  <div class="small text-gray">{{ requestor.email }}</div>
                </div>
              </a>
            </td>

            <td>
              <a
                @click="
                  removeProgramAccess(
                    requestor.id,
                    requestor.uid,
                    requestor.email
                  )
                "
                href="#"
                class="text-danger ml-2"
                title="Deny"
                data-toggle="tooltip"
                ><span class="fas fa-times-circle"></span
              ></a>
            </td>
            <td>
              <router-link
                :to="'/program-manager/viewplayeractivity/' + requestor.uid"
                tag="a"
                class="mb-0"
                ><span class="fas fa-eye"></span
              ></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Current User Table END-->
    <br />
    <!-- User Accept Table START-->

    <div
      class="
        card card-body
        border-light
        shadow-sm
        table-wrapper table-responsive
        pt-0
      "
      v-if="
        programData.status == 'active' &&
        programRequestDataLoaded &&
        programData.isResell == false
      "
    >
      <br />
      <h2>Current Users Requesting Access</h2>
      <br />
      <div v-if="programRequestData.length == 0">
        - You do not currently have any program requests -
      </div>
      <table
        class="table user-table table-hover align-items-center"
        v-if="programRequestData.length > 0"
      >
        <thead>
          <tr>
            <th class="border-bottom">Name</th>
            <th class="border-bottom">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="requestor in programRequestData" :key="requestor.id">
            <td>
              <a href="#" class="d-flex align-items-center">
                <div class="d-block">
                  <span class="font-weight-bold"
                    >{{ requestor.firstName }} {{ requestor.lastName }}</span
                  >
                  <div class="small text-gray">{{ requestor.email }}</div>
                </div>
              </a>
            </td>

            <td>
              <a
                @click="
                  acceptToProgram(requestor.id, requestor.uid, requestor.email)
                "
                href="#"
                class="text-success ml-2"
                title="Accept"
                data-toggle="tooltip"
                ><span class="fas fa-check-circle"></span
              ></a>
              <a
                @click="denyProgramAccess(requestor.id)"
                href="#"
                class="text-danger ml-2"
                title="Deny"
                data-toggle="tooltip"
                ><span class="fas fa-times-circle"></span
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- User Accept Table END-->
    <br />
    <!-- Program Access Request START -->

    <!-- Program Access Request END -->
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import loadingSpinner from "../../components/loadingSpinner.vue";
import axios from "axios";
import { firebaseFunctions } from "../../firebase/config";
import { projectFirestore } from "../../firebase/config";
import updateCollection from "../../composables/updateDoc";
import eventSectionTitle from "../../components/eventSectionTitle";

export default {
  components: { loadingSpinner, eventSectionTitle },

  setup() {
    const { errorUp, updateDoc, isPending } = updateCollection("programs");
    const store = useStore();
    const isLoading = ref(false);
    //user data
    const userLoaded = computed(() => store.state.userDataLoaded);
    const userData = computed(() => store.state.userData);
    //subscription data
    const subData = computed(() => store.state.subscriptions);
    const subDataLoaded = computed(() => store.state.subscriptionsLoaded);
    const subCount = ref(0);
    //program data
    const programData = computed(() => store.state.programData);
    const programDataLoaded = computed(() => store.state.programDataLoaded);
    //program Request
    const programRequestData = computed(() => store.state.programRequestData);
    const programRequestDataLoaded = computed(
      () => store.state.programRequestDataLoaded
    );

    //program Member Lists
    const programMemberData = computed(() => store.state.programMembersData);
    const programMemberDataLoaded = computed(() => {
      console.log(store.state.programMembersDataLoaded);
      return store.state.programMembersData;
    });
    console.log();

    //Program Ingo Form Fields
    const programName = ref("");
    const programDescription = ref("");
    const programDomainName = ref("");
    const programType = ref("");

    const programGender = ref("");
    const programRegion = ref("");
    const programStyle = ref("");

    const programHeadName = ref("");
    const programHeadEmail = ref("");
    const programHeadPhone = ref("");

    const programAdminName = ref("");
    const programAdminEmail = ref("");
    const programAdminPhone = ref("");

    const programPlayerCount = ref("");
    const programTeamCount = ref("");

    let functionPrefix = null;
    if (process.env.VUE_APP_ENV == "test") {
      functionPrefix = "https://us-central1-fcl-online-dev";
    } else {
      functionPrefix = "https://us-central1-first-class-lax-c0894";
    }

    watchEffect(() => {
      if (subDataLoaded) {
        console.log(store.getters.getProgramUserCountTotal);
        subCount.value = store.getters.getProgramUserCountTotal;
      }
    });

    const seatCount = ref("");

    let stripe = null;
    let functionURL = null;
    let priceTo = null;

    if (process.env.VUE_APP_ENV == "test") {
      stripe = Stripe(
        "pk_test_51IqpudINbM8Ghj39JofFXCMtsRwKaDSCT8oIENe7GfBM2W0snnzbJgVtEShhL4KnRtwfcHSgzTBhd5m4WfzfhYvy00XEXWd9H1"
      );
      functionURL =
        "https://us-central1-fcl-online-dev.cloudfunctions.net/programSubscription";
      priceTo = "price_1KlzWZINbM8Ghj39ZPyC5BkJ";
    } else {
      stripe = Stripe(
        "pk_live_51IqpudINbM8Ghj39Bg3LwWn3JyCEKocMuGysh8I9iewXNPRrz7NbtH7ZsG2K3gpC5Su7o3AtbHpH4vIoVOcRYKtn00xPMXB0AP"
      );
      functionURL =
        "https://us-central1-first-class-lax-c0894.cloudfunctions.net/programSubscription";
      priceTo = "price_1KpxWdINbM8Ghj39swhei1Kj";
    }

    let sessionID = "";
    //Signup Program
    const payCheckout = () => {
      if (userData.value.stripeId != null) {
        //check to make sure a quantity was entered
        if (parseInt(seatCount.value) > 0) {
          isLoading.value = true;
          axios
            .get(functionURL, {
              params: {
                id: userData.value.stripeId,
                quant: seatCount.value,
                price: priceTo,
              },
            })
            .then((response) => {
              sessionID = response.data.id;
              stripe
                .redirectToCheckout({
                  sessionId: sessionID,
                })
                .then(function (result) {
                  isLoading.value = false;
                  if (result.error) {
                    alert(result.error);
                  }
                });
            })
            .catch((error) => {
              console.log(error);
              isLoading.value = false;
            });
        } else {
          alert(
            "Please enter a seat count greater than zero. These must be entered as integers."
          );
        }
      } else {
        alert(
          "Error: User Missing Stripe ID. Please contact Support at contact@1stclasslax.com"
        );
      }
    };

    const removeProgramAccess = (id, uid, email) => {
      if (email == userData.value.email) {
        alert("You cannot remove the program manager.");
      } else {
        if (
          confirm(
            "Are you sure you want to remove" +
              " " +
              email +
              " " +
              "from the program?"
          )
        ) {
          isLoading.value = true;
          axios
            .get(functionPrefix + ".cloudfunctions.net/removeProgramAccess", {
              params: {
                program: programData.value.id,
                acceptDoc: id,
                user: uid,
              },
            })
            .then((response) => {
              store.dispatch("refreshPrograms");
              isLoading.value = false;

              console.log(response.data);
              alert(response.data);
            });
        }
      }
    };

    const acceptToProgram = (id, uid, email) => {
      if (email == userData.value.email) {
        alert(
          "You do not need to add the program manager to the account. The program manager has access by default."
        );
      } else {
        isLoading.value = true;
        axios
          .get(functionPrefix + ".cloudfunctions.net/programAccessApproval", {
            params: {
              program: programData.value.id,
              acceptDoc: id,
              user: uid,
            },
          })
          .then((response) => {
            store.dispatch("refreshPrograms");
            isLoading.value = false;
            console.log(response.data);
            alert(response.data);
          });
      }
    };

    const denyProgramAccess = (docID) => {
      //isLoading.value = true;
      let r = confirm(
        "Are you sure that you want to DENY this user access to the program?"
      );
      if (r == true) {
        console.log(docID);
        console.log(programData.value.id);

        projectFirestore
          .collection("programs")
          .doc(programData.value.id)
          .collection("accessRequest")
          .doc(docID)
          .delete()
          .then(() => {
            console.log("User has been deleted");
            store.dispatch("refreshPrograms");
          });
      } else {
        console.log("User was not denied.");
      }
    };

    const cancelSub = async () => {
      isLoading.value = true;
      const functionRef = firebaseFunctions.httpsCallable(
        "ext-firestore-stripe-subscriptions-createPortalLink"
      );
      const { data } = await functionRef({ returnUrl: window.location.origin });
      window.location.assign(data.url);
      isLoading.value = false;
    };

    const sendProgramDoc = () => {
      const tempDoc = {
        name: programName.value,
        description: programDescription.value,
        domain: programDomainName.value,
        programType: programType.value,
        genderFocus: programGender.value,
        region: programRegion.value,
        style: programStyle.value,
        programHeadName: programHeadName.value,
        programHeadEmail: programHeadEmail.value,
        programHeadPhone: programHeadPhone.value,
        programAdminName: programAdminName.value,
        programAdminEmail: programAdminEmail.value,
        programAdminPhone: programAdminPhone.value,
        programPlayerCount: programPlayerCount.value,
        programTeamCount: programTeamCount.value,
        status: "active",
      };
      updateDoc(programData.value.id, tempDoc).then(() => {
        if (errorUp.value) {
          console.log(errorUp.value);
        } else {
          console.log("Success program doc update");
        }
        window.location.reload();
      });
    };

    const adjustPlayerCount = () => {
      if (
        confirm(
          "Please email kurt@1stclasslax.com to adjust program seat count. Proceed with email?"
        )
      ) {
        window.location.href =
          "mailto:kurt@1stclasslax.com?subject:Program Seat Count Adjustment";
      }
    };

    const getExercisesCompleted = (workouts) => {
      if (workouts.length == 0) {
        return 0;
      } else {
        let count = 0;
        workouts.forEach((workout) => {
          workout.data().day1.forEach((excercise) => {
            if (excercise == true) {
              count++;
            }
          });
          workout.data().day2.forEach((excercise) => {
            if (excercise == true) {
              count++;
            }
          });
          workout.data().day3.forEach((excercise) => {
            if (excercise == true) {
              count++;
            }
          });
        });
        return count;
      }
    };
    let downDoc = [];
    const resetDownDoc = () => {
      downDoc = [];
      downDoc.push([
        "First Name",
        "Last Name",
        "Email",
        "3-Day Workouts Completed",
        "3-Day Workouts Started",
        "1-Day Workouts Completed",
        "Exercises Completed",
      ]);
    };

    let { firstName, lastName, Email, workoutsCompleted, exercisesComeplete } =
      "";
    const eventRef = projectFirestore.collection("fclUsers");

    const getWorkoutsCompleted = (workouts) => {
      if (workouts.length == 0) {
        return 0;
      } else {
        let count = 0;
        workouts.forEach((workout) => {
          if (workout.data().day1.includes(false) == false) {
            if (workout.data().day2.includes(false) == false) {
              if (workout.data().day3.includes(false) == false) {
                count++;
              }
            }
          }
        });
        return count;
      }
    };
    const getWorkoutDaysCompleted = (workouts) => {
      if (workouts.length == 0) {
        return 0;
      } else {
        let count = 0;
        workouts.forEach((workout) => {
          if (workout.data().day1.includes(false) == false) {
            count++;
          }
          if (workout.data().day2.includes(false) == false) {
            count++;
          }
          if (workout.data().day3.includes(false) == false) {
            count++;
          }
        });
        return count;
      }
    };

    const downloadPlayerData = () => {
      resetDownDoc();

      let promiseList = [];
      let workoutList = [];
      //for each of the players in the program
      programMemberData.value.forEach((play) => {
        promiseList.push(
          eventRef.doc(play.uid).collection("workoutData").get()
        );
      });

      Promise.all(promiseList).then((values) => {
        values.forEach((val) => {
          workoutList.push(val.docs);
        });

        for (let p = 0; p < programMemberData.value.length; p++) {
          firstName = programMemberData.value[p].firstName.replace(/,/g, "");
          lastName = programMemberData.value[p].lastName.replace(/,/g, "");
          Email = programMemberData.value[p].email.replace(/,/g, "");
          downDoc.push([
            firstName,
            lastName,
            Email,
            getWorkoutsCompleted(workoutList[p]),
            workoutList[p].length,
            getWorkoutDaysCompleted(workoutList[p]),

            getExercisesCompleted(workoutList[p]),
          ]);
        }

        let csvContent = "data:text/csv;charset=utf-8,";
        console.log(downDoc);
        downDoc.forEach(function (rowArray) {
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
        });
        let encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", programData.value.name + "_bic.csv");
        document.body.appendChild(link); // Required for FF

        link.click();
      });
    };
    return {
      downloadPlayerData,
      adjustPlayerCount,
      userLoaded,
      userData,
      isLoading,
      payCheckout,
      seatCount,
      cancelSub,
      programData,
      programDataLoaded,
      subData,
      subDataLoaded,
      subCount,
      programRequestDataLoaded,
      programRequestData,
      denyProgramAccess,
      acceptToProgram,
      programMemberData,
      programMemberDataLoaded,
      removeProgramAccess,
      programName,
      programDescription,
      programDomainName,
      programType,
      programGender,
      programRegion,
      programStyle,
      programHeadName,
      programHeadEmail,
      programHeadPhone,
      programAdminName,
      programAdminEmail,
      programAdminPhone,
      programPlayerCount,
      programTeamCount,
      sendProgramDoc,
    };
  },
};
</script>

<style>
</style>