<template>
  <!-- Section -->
  <section class="vh-lg-100 d-flex align-items-center bg-image2">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div
            class="
              signin-inner
              my-3 my-lg-0
              bg-white
              shadow-soft
              border
              rounded
              border-light
              p-4 p-lg-5
              w-100
              fmxw-500
            "
          >
            <div class="text-center text-md-center mb-4 mt-md-0">
              <h1 class="mb-0 authTextHeader">
                Sign Up <br />
                First Class Portal
              </h1>
            </div>
            <div v-if="error" class="alert alert-warning" role="alert">
              {{ error }}
            </div>
            <br />
            <br /><br />
            <br /><br /><br />
            <br />
            <div v-if="disIntMessage" class="alert alert-warning" role="alert">
              {{ intMessage }}
            </div>
            <div class="alert alert-warning" role="alert">
              Welcome to FCL - Please create an account and explore our offering
              inside!
            </div>
            <!-- <div class="alert alert-warning" role="alert">
             TEst
            </div> -->
            <form @submit.prevent="handleSubmit">
              <!-- Form -->
              <div class="form-group mb-4">
                <label for="email">Your Email</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon3"
                    ><span class="fas fa-envelope"></span
                  ></span>
                  <input
                    v-model="email"
                    type="email"
                    class="form-control"
                    placeholder="example@gmail.com"
                    id="email"
                    autofocus
                    required
                  />
                </div>
              </div>
              <div class="form-group mb-4">
                <label for="email">Confirm Your Email</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon3"
                    ><span class="fas fa-envelope"></span
                  ></span>
                  <input
                    v-model="emailC"
                    type="email"
                    class="form-control"
                    placeholder="example@gmail.com"
                    id="email"
                    autofocus
                    required
                  />
                </div>
              </div>
              <!-- End of Form -->
              <!-- Form -->
              <div class="form-group mb-4">
                <label for="firstName">First Name</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon3"
                    ><span class="fas fa-user-alt"></span
                  ></span>
                  <input
                    v-model="firstName"
                    type="text"
                    class="form-control"
                    placeholder="First Name"
                    id="firstName"
                    autofocus
                    required
                  />
                </div>
              </div>
              <div class="form-group mb-4">
                <label for="lastName">Last Name</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon3"
                    ><span class="fas fa-user-alt"></span
                  ></span>
                  <input
                    v-model="lastName"
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    id="lastName"
                    autofocus
                    required
                  />
                </div>
              </div>
              <div class="form-group mb-4">
                <label for="userType">User Type</label>
                <div class="input-group">
                  <label>
                    <input
                      type="radio"
                      name="userType"
                      value="player"
                      v-model="userType"
                    />I am a Player
                  </label>
                  <label>
                    <input
                      type="radio"
                      v-model="userType"
                      name="userType"
                      value="coach"
                    />I am a Coach
                  </label>
                </div>
              </div>
              <div class="form-group mb-4">
                <label for="genderSelect">Training Focus</label>
                <div class="input-group">
                  <label>
                    <input
                      type="radio"
                      name="genderSelect"
                      v-model="genderSelect"
                      id="maleOpt"
                      value="male"
                    />Men's Game
                  </label>
                  <label>
                    <input
                      v-model="genderSelect"
                      type="radio"
                      name="genderSelect"
                      id="femaleOpt"
                      value="female"
                    />Women's Game
                  </label>
                  <br />
                </div>
              </div>
              <!-- End of Form -->
              <div class="form-group">
                <!-- Form -->
                <div class="form-group mb-4">
                  <label for="password">Your Password</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon4"
                      ><span class="fas fa-unlock-alt"></span
                    ></span>
                    <input
                      v-model="password"
                      type="password"
                      placeholder="Password"
                      class="form-control"
                      id="password"
                      required
                    />
                  </div>
                </div>
                <!-- End of Form -->
                <!-- Form -->
                <div class="form-group mb-4">
                  <label for="confirm_password">Confirm Password</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon5"
                      ><span class="fas fa-unlock-alt"></span
                    ></span>
                    <input
                      v-model="confirmPassword"
                      type="password"
                      placeholder="Confirm Password"
                      class="form-control"
                      id="confirm_password"
                      required
                    />
                  </div>
                </div>
                <!-- End of Form -->
                <div class="form-check mb-4">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="terms"
                    v-model="acceptTerms"
                  />
                  <label class="form-check-label" for="terms">
                    I agree to the <a href="#">terms and conditions</a>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                v-if="!isPending"
                class="btn btn-block btn-primary"
              >
                Sign Up
              </button>
              <button
                type="submit"
                v-if="isPending"
                class="btn btn-block btn-primary"
                disabled
              >
                Loading...
              </button>
            </form>

            <div class="d-flex justify-content-center align-items-center mt-4">
              <span class="font-weight-normal">
                Already have an account?

                <a @click="toAuthPage('Login')"> Login Here</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import useSignup from "../../composables/useSignup";
import { useRouter } from "vue-router";
import useCollection from "../../composables/useCollection";
import { useRoute } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { error, signup, isPending } = useSignup();
    const { addDoc, error2, isPending2 } = useCollection("fclUsers");
    const email = ref("");
    const emailC = ref("");

    const firstName = ref("");
    const lastName = ref("");
    const password = ref("");
    const genderSelect = ref("");
    const userType = ref("");
    const confirmPassword = ref("");
    const acceptTerms = ref(false);
    const disIntMessage = ref(false);
    let intMessage = ref("");
    const sendVideoSeries = async (uid) => {
      const userDoc = {
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        gender: genderSelect.value,
        usrType: userType.value,
      };
      await addDoc(userDoc, uid);
      if (!error2.value) {
        console.log("User has been signed up");
        if (route.query.from) {
          router.push({ path: route.query.from });
        } else {
          router.push({ name: "Home" });
        }
      }
    };

    const signUserUp = async () => {
      disIntMessage.value = false;
      const res = await signup(email.value, password.value);

      if (!error.value) {
        sendVideoSeries(res.user.uid);
      } else {
        console.log("Error on singup:");
        console.log(error.value);
      }
    };

    //validation
    const handleSubmit = () => {
      disIntMessage.value = false;
      if (email.value != emailC.value) {
        disIntMessage.value = true;
        intMessage.value = "Please check that your emails match.";
        return;
      }
      if (!firstName.value) {
        disIntMessage.value = true;
        intMessage.value = "Please enter your first name";
        return;
      }
      if (!lastName.value) {
        disIntMessage.value = true;
        intMessage.value = "Please enter your last name";
        return;
      }
      if (!genderSelect.value) {
        disIntMessage.value = true;
        intMessage.value = "Please choose a training focus.";
        return;
      }
      if (!userType.value) {
        disIntMessage.value = true;
        intMessage.value = "Please choose an user type.";
        return;
      }
      if (!acceptTerms.value) {
        disIntMessage.value = true;
        intMessage.value = "Please accept our terms and conditions";
        return;
      }
      if (!acceptTerms.value) {
        disIntMessage.value = true;
        intMessage.value = "Please accept our terms and conditions";
        return;
      }
      if (!(confirmPassword.value === password.value)) {
        disIntMessage.value = true;
        intMessage.value = "Passwords do not match";
        return;
      }

      signUserUp();
    };

    const toAuthPage = (page) => {
      if (route.query.from) {
        const loginpath = route.query.from;
        router.push({ name: page, query: { from: loginpath } });
      } else {
        router.push({ name: page });
      }
    };
    return {
      toAuthPage,
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      acceptTerms,
      error,
      isPending,
      handleSubmit,
      disIntMessage,
      intMessage,
      genderSelect,
      userType,
      emailC,
    };
  },
};
</script>

<style>
input[type="radio"] {
  margin: 0 10px 0 10px;
}

.bg-image2 {
  /* Location of the image */
  background-image: url(../../assets/img/signup.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #464646;
  background-attachment: fixed !important;
}
</style>