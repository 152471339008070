<template>
  <section class="vh-lg-100 bg-image3 d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-center form-bg-image">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div
            class="
              signin-inner
              my-3 my-lg-0
              bg-white
              shadow-soft
              border
              rounded
              border-light
              p-4 p-lg-5
              w-100
              fmxw-500
            "
          >
            <div
              class="alert alert-warning"
              role="alert"
              style="text-align: center"
            >
              Check out our new Interactive Workouts feature! Sign in and start
              your free trial today!
            </div>
            <div class="text-center text-md-center mb-4 mt-md-0">
              <h1 class="mb-0 authTextHeader">
                Sign In <br />
                First Class Portal
              </h1>
            </div>

            <div v-if="error" class="alert alert-warning" role="alert">
              {{ error }}
            </div>
            <form @submit.prevent="handleSubmit" class="mt-4">
              <!-- Form -->
              <div class="form-group mb-4">
                <label for="email">Your Email</label>
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1"
                    ><span class="fas fa-envelope"></span
                  ></span>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="example@gmail.com"
                    id="email"
                    v-model="inputEmail"
                    autofocus
                    required
                  />
                </div>
              </div>
              <!-- End of Form -->
              <div class="form-group">
                <!-- Form -->
                <div class="form-group mb-4">
                  <label for="password">Your Password</label>
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon2"
                      ><span class="fas fa-unlock-alt"></span
                    ></span>
                    <input
                      type="password"
                      placeholder="Password"
                      class="form-control"
                      id="password"
                      v-model="inputPassword"
                      required
                    />
                  </div>
                </div>
                <!-- End of Form -->
                <div
                  class="d-flex justify-content-between align-items-center mb-4"
                >
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck5"
                    />
                    <label class="form-check-label" for="defaultCheck5">
                      Remember me
                    </label>
                  </div>
                  <div>
                    <a
                      @click="toAuthPage('ForgotPass')"
                      class="small text-right"
                    >
                      Lost password?</a
                    >
                  </div>
                </div>
              </div>
              <button
                type="submit"
                v-if="!isPending"
                class="btn btn-block btn-primary"
              >
                Sign in
              </button>
              <button
                type="submit"
                v-if="isPending"
                class="btn btn-block btn-primary"
                disabled
              >
                Loading...
              </button>
            </form>

            <div class="d-flex justify-content-center align-items-center mt-4">
              <span class="font-weight-normal">
                Not registered?
                <a @click="toAuthPage('Signup')"> Create account</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import useLogin from "../../composables/useLogin";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { error, login, isPending } = useLogin();
    const inputEmail = ref("");
    const inputPassword = ref("");

    const toAuthPage = (page) => {
      if (route.query.from) {
        const loginpath = route.query.from;
        router.push({ name: page, query: { from: loginpath } });
      } else {
        router.push({ name: page });
      }
    };
    //Function Submitted on Login
    const handleSubmit = async () => {
      const res = await login(inputEmail.value, inputPassword.value);
      if (!error.value) {
        if (route.query.from) {
          router.push({ path: route.query.from });
        } else {
          router.push({ name: "Home" });
        }
      }
    };

    return {
      inputEmail,
      inputPassword,
      handleSubmit,
      error,
      isPending,
      toAuthPage,
    };
  },
};
</script>

<style>
.bg-image3 {
  /* Location of the image */
  background-image: url(../../assets/img/deemer_class_header.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #464646;
  height: 100vh;
}

.authTextHeader {
  font-family: hemi;
}
</style>