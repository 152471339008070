import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";
//'eventType' = 'training' OR 'liveReps'
const getEvents = (k) => {
  const events = ref([]);
  const error = ref(null);

  const load = async () => {
    try {
      const res = await projectFirestore
        .collection("events")
        .where("eventType", "==", k)
        .orderBy("eventName")
        .get();
      //const res = await projectFirestore.collection("events").get()
      events.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      error.value = err.message;
    }
  };

  return { events, error, load };
};

export default getEvents;
