<template>
  <section class="banner-section">
    <div class="banenr-container">
      <div class="baner-text">
        <h1>{{ title }}</h1>

        <p>
          {{ description }}
        </p>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
</template>

<script>
export default {
  props: ["title", "description"],
};
</script>

<style scoped>
.banner-section {
  min-height: 322px;
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../assets/img/cbb.jpg");
}

.banenr-container {
  max-width: 1050px;
  margin: 0 auto;
}

.baner-text h1 {
  font-family: "hemi";
  font-size: 60px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #ffffff;
  margin-bottom: 20px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.baner-text p {
  font-family: "redwingMedium";
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.baner-text p span {
  color: #f31b1b;
  font-weight: 800;
}

.clearfix {
  clear: both;
}

@media (max-width: 600px) {
  .baner-text h1 {
    font-size: 44px;
  }

  .baner-text p {
    font-size: 17px;
    line-height: 26px;
  }
}
</style>