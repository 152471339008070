<template>
  <div :id="`my-store-${storeId}`"></div>
</template>
<script>
function injectEcwidScript(storeId, catID) {
  const ecwidScript = document.createElement("script");
  ecwidScript.setAttribute("type", "text/javascript");
  ecwidScript.setAttribute("charset", "utf-8");
  ecwidScript.setAttribute("data-cfasync", "false");
  ecwidScript.setAttribute(
    "src",
    `https://app.ecwid.com/script.js?${storeId}&data_platform=code&data_date=2020-02-17`
  );
  ecwidScript.onload = injectEcwidProductBrowser(storeId,  catID);
  document.head.appendChild(ecwidScript);
}
function injectEcwidProductBrowser(storeId, catID) {
  return function () {
    const ecwidBrowserScript = document.createElement("script");
    ecwidBrowserScript.setAttribute("type", "text/javascript");
    ecwidBrowserScript.setAttribute("charset", "utf-8");
    ecwidBrowserScript.text = `xProductBrowser("categoriesPerRow=3","views=grid(20,3) list(60) table(60)","categoryView=grid","searchView=list","id=my-store-${storeId}","defaultCategoryId=${catID}");`;
    document.head.appendChild(ecwidBrowserScript);
  };
}


export default {
  name: "Ecwid",
  props: {
    storeId: {
      type: Number,
      default: 1003,
    },
    catID: {
      type: Number,
      default: 123641315,
    },
  }, 
  mounted() {
    console.log('RRNRNRNNRRNNNNNNN')
    injectEcwidScript(this.storeId, this.catID);
  },
};
</script>
<style>
</style>