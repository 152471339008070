import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getProgramRequests = (id) => {
  const programRequests = ref([]);
  const errorPR = ref(null);

  const loadPR = async () => {
    try {
      const res = await projectFirestore
        .collection("programs/" + id + "/accessRequest")
        .orderBy("firstName")
        .get();

      programRequests.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      console.log(err.message);
      errorPR.value = err.message;
    }
  };

  return { programRequests, errorPR, loadPR };
};

export default getProgramRequests;
