import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getProgramMembers = (id) => {
  const programMembers = ref([]);
  const errorPM = ref(null);

  const loadPM = async () => {
    try {
      const res = await projectFirestore
        .collection("programs/" + id + "/currentMembers")
        .orderBy("firstName")
        .get();

      programMembers.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      console.log(err.message);
      errorPM.value = err.message;
    }
  };

  return { programMembers, errorPM, loadPM };
};

export default getProgramMembers;
