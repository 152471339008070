<template>
  <loadingSpinner v-if="!listLoaded || isLoading" />

  <div v-if="listLoaded && userDataLoaded && eventData != null">
    <div
      v-if="
        isFull && !(checkEmail(userData.email) && userData.bicEventsAttended)
      "
    >
      <h2>Sorry {{ eventData.eventName }} is sold out.</h2>
    </div>
    <div v-if="checkEmail(userData.email) && userData.bicEventsAttended">
      <eventSectionTitle
        :title="
          userData.firstName +
          ', Thanks for signing up for ' +
          eventData.eventName +
          ' Your registration is confirmed. '
        "
        description=" We can't wait to have you and work with you. Our staff looks forward to continue to get to know you and build a relationship. Please feel free to reach out to Deemer with any questions at contact@1stclasslax.com and stay tuned for updates regarding schedules, announcements and more. We can't wait for you to experience Best In Class!"
      />
      <div class="freeTrialBack">
        <div class="trial-text">
          <h5>- Offer For Only BIC Registrants -</h5>

          <h3>FCL Online</h3>
          <h3>35% off with your BIC Status</h3>
          <h5 style="color: white !important">
            <a
              style="color: white !important"
              href="https://learn.1stclasslax.com/coupon/NiLfXX0J"
            >
              - Click to Claim -
            </a>
          </h5>
        </div>
      </div>
      <!-- Map Section -->
      <div v-if="eventData.gmap">
        <iframe
          :src="eventData.gmap"
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>

      <!-- Details Section -->
      <div class="plan-timing-list">
        <ul>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-calendar"></i></span>
              {{ eventData.startDate }} - {{ eventData.endDate }}
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-clock"></i></span> {{ eventData.eventTime }}
            </p>
          </li>
          <li>
            <p class="playermiddle-text">
              <span><i class="fa fa-map-marker"></i></span>
              {{ eventData.eventAddress }}
            </p>
          </li>
          <li>
            <p class="playermiddle-text">
              <span><i class="fa fa-dot-circle"></i></span> Position:
              {{ eventData.eventPosition }}
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-dot-circle"></i></span>
              Gender Focus: {{ eventData.genderFocus }}
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fas fa-dollar-sign"></i></span>
            </p>
            <span class="pricein"> Registration Confirmed</span>
          </li>
        </ul>
      </div>
      <br />

      <div class="clearfix"></div>
      <div v-if="checkEmail(userData.email) && userData.bicEventsAttended">
        <Ecwid :storeId="66725921" :catID="123617942" />
      </div>
    </div>
    <!-- INVITED  START -->
    <div
      v-if="
        checkEmail(userData.email) && !userData.bicEventsAttended && !isFull
      "
    >
      <eventSectionTitle
        :title="
          userData.firstName + ', You Are Invited To ' + eventData.eventName
        "
        description="FCL Best in Class, aims to provide a unique environment that optimizes a player’s training, development and evaluation as they begin the college recruitment process. We are very excited to invite you to come learn and compete with the best players in the country! Please complete the registration process to secure your spot!!"
      />

      <!-- Map Section -->
      <div v-if="eventData.gmap">
        <iframe
          :src="eventData.gmap"
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>

      <!-- Details Section -->
      <div class="plan-timing-list">
        <ul>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-calendar"></i></span>
              {{ eventData.startDate }} - {{ eventData.endDate }}
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-clock"></i></span> {{ eventData.eventTime }}
            </p>
          </li>
          <li>
            <p class="playermiddle-text">
              <span><i class="fa fa-map-marker"></i></span>
              {{ eventData.eventAddress }}
            </p>
          </li>
          <li>
            <p class="playermiddle-text">
              <span><i class="fa fa-dot-circle"></i></span> Position:
              {{ eventData.eventPosition }}
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-dot-circle"></i></span>
              Gender Focus: {{ eventData.genderFocus }}
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fas fa-dollar-sign"></i></span>
            </p>
            <span class="pricein">
              {{ eventData.eventPrice }} <sub>PER PLAYER</sub></span
            >
          </li>
        </ul>
      </div>

      <div class="clearfix"></div>

      <div v-if="regProfs.length == 0">
        <!-- Add event registration profile Start -->
        <div class="col-12 col-xl-12 mb-4">
          <div class="card card-body bg-white border-light shadow-sm mb-4">
            <h2 class="h5 mb-4">Add Event Registration Profile</h2>
            <p>
              You don't have any event registration profiles on your account.
              Event registration profiles provide an easy way for you to
              checkout multiple players for events within one account. You can
              add as many as you like from
              <router-link to="/profile" tag="a"> <u> profile</u> </router-link>
              section on the fcl portal.
            </p>
          </div>
        </div>
      </div>
      <div v-if="regProfs.length > 0">
        <label for="gender"
          >Select the player registration profile that you would like to
          register for this event. You can add a different player in your
          <u>
            <router-link to="/profile" tag="a"
              >Account Profile Section</router-link
            ></u
          ></label
        >
        <select
          class="form-select mb-0"
          v-model="chosenProfile"
          id="profSelect"
          required
        >
          <option value="" disabled selected>
            Select Registration Profile
          </option>
          <option
            v-for="profile in regProfs"
            :key="profile"
            :value="profile.id"
          >
            {{ profile.firstName }} {{ profile.lastName }} -
            {{ profile.position }}
          </option>
        </select>
        <br />
        <button
          class="btn btn-primary"
          @click="payCheckout(eventData.id)"
          style="width: 100%"
          :disabled="chosenProfile == ''"
        >
          <span v-if="chosenProfile != ''">Register For Best In Class</span>
          <span v-if="chosenProfile == ''">Select a Registration Profile</span>
        </button>
      </div>
    </div>
    <!-- INVITED  END -->
    <!-- Not Invited Yet START -->
    <div v-if="!checkEmail(userData.email)">
      <eventSectionTitle
        title="FCL BEST IN CLASS"
        description="FCL Best in Class, aims to provide a unique environment that optimizes a player’s training, development and evaluation as they begin the college recruitment process.Best in Class combines the core FCL principles and method of training with recruiting and evaluative components to create a 2 day training and recruiting event aimed to maximize the time and energy of high school recruits. Scroll down to apply today!"
      />
      <br />
      <div class="alert alert-info" role="alert">
        <h4>I Have applied but have not hear back</h4>
        <p>
          your application is being reviewed, we have received over 3,000
          applications nation-wide, so please be patient with us as we evaluate
          all of them. If you have very urgent questions, please email
          bestinclass@1stclasslax.com
        </p>
      </div>
      <br />

      <div class="alert alert-info" role="alert">
        <h4>I Am Looking To Apply</h4>
        <p>
          Throw Your Hat In The Ring And Let Us Know Why You Should Be At Our
          August Best In Class Event Submit The Application So We Can Get You On
          Our Radar
          <a href="https://linktr.ee/firstclasslax">Click Here To Apply</a>
        </p>
      </div>
      <br />
      <div class="alert alert-info" role="alert">
        <h4>What is Best In Class?</h4>
        <p>
          The Best in Class format has been designed to give players the unique
          opportunity to train and learn in an environment that will push them
          to be open minded, expand their games and become better lacrosse
          players. Through this training, coaches will have a unique chance to
          see players respond to feedback, make improvements and demonstrate
          other intangibles that might not always come out in pure gameplay. Our
          hope is that it will showcase those players that are drawn to
          improvement and hard work – the players with the highest trajectory to
          excel in their 4 years of collegiate lacrosse.
          <a href="https://1stclasslax.com/compete/bestinclass"
            >Learn More...</a
          >
        </p>
      </div>
    </div>
    <!-- Not Invited Yet end -->
  </div>
</template>

<script>
import eventSectionTitle from "../components/eventSectionTitle";
import getBicRegList from "../composables/getBicRegList";
import { useStore } from "vuex";
import { computed, ref, watchEffect } from "@vue/runtime-core";
import loadingSpinner from "../components/loadingSpinner.vue";
import getSingleDoc from "../composables/getSingleDoc";
import getData from "../composables/getData";
import axios from "axios";
import Ecwid from "../components/ecwid.vue";

export default {
  components: { eventSectionTitle, loadingSpinner, Ecwid },

  setup() {
    const listLoaded = ref(false);
    const emailList = ref({});
    const { RegDoc, errorPM, loadPM } = getBicRegList();
    const eventData = ref(null);
    const store = useStore();
    const userData = computed(() => store.state.userData);
    const userDataLoaded = computed(() => store.state.userDataLoaded);
    const isLoading = ref(false);
    const isFull = ref(false);
    const regProfs = ref([]);
    const chosenProfile = ref("");
    const getEvent = (e) => {
      if (typeof emailList.value[e] != "undefined") {
        return emailList.value[e].replace("\n", "");
      }
      if (typeof emailList.value["\n" + e] != "undefined") {
        return emailList.value["\n" + e].replace("\n", "");
      }
      if (typeof emailList.value[e + "\n"] != "undefined") {
        return emailList.value[e + "\n"].replace("\n", "");
      }
      return false;
    };

    let stripe = null;
    let functionURL = null;
    if (process.env.VUE_APP_ENV == "test") {
      stripe = Stripe(
        "pk_test_51IqpudINbM8Ghj39JofFXCMtsRwKaDSCT8oIENe7GfBM2W0snnzbJgVtEShhL4KnRtwfcHSgzTBhd5m4WfzfhYvy00XEXWd9H1"
      );
      functionURL =
        "https://us-central1-fcl-online-dev.cloudfunctions.net/CheckoutSession";
    } else {
      stripe = Stripe(
        "pk_live_51IqpudINbM8Ghj39Bg3LwWn3JyCEKocMuGysh8I9iewXNPRrz7NbtH7ZsG2K3gpC5Su7o3AtbHpH4vIoVOcRYKtn00xPMXB0AP"
      );
      functionURL =
        "https://us-central1-first-class-lax-c0894.cloudfunctions.net/CheckoutSession";
    }

    let sessionID = "";

    const payCheckout = (docID) => {
      //Check to make sure the profile has a user ID
      if (
        userData.value.stripeId == null ||
        userData.value.stripeId == undefined
      ) {
        alert(
          "Error: No Stripe ID found for this account. Please contact support at contact@1stclasslax.com and we will fix it asap. Sorry for the inconvenience"
        );
        return;
      }
      if (chosenProfile.value == "") {
        alert("Please choose a registration profile.");
        return;
      }
      console.log(userData.value.id);
      console.log(docID);
      isLoading.value = true;
      axios
        .get(functionURL, {
          params: {
            event: docID,
            id: userData.value.stripeId,
            subProf: chosenProfile.value,
            userUID: userData.value.id,
          },
        })
        .then((response) => {
          sessionID = response.data.id;
          stripe
            .redirectToCheckout({
              sessionId: sessionID,
            })
            .then(function (result) {
              isLoading.value = false;
              if (result.error) {
                alert(result.error);
              }
            });
        })
        .catch((error) => {
          console.log(error);
          isLoading.value = false;
        });
    };

    watchEffect(() => {
      if (userData.value != null) {
        console.log(userData.value.email);
        loadPM().then(() => {
          listLoaded.value = true;
          emailList.value = RegDoc.value.emails;
          console.log(getEvent(userData.value.email));
          const { Doc, sdError, loadSD } = getSingleDoc(
            "events",
            getEvent(userData.value.email)
          );
          loadSD().then(() => {
            if (Doc.value.currentRegister >= Doc.value.eventPopCap) {
              isFull.value = true;
            }
            eventData.value = Doc.value;
          });
        });
      }
    });

    watchEffect(() => {
      if (userData.value != null) {
        const coll = "fclUsers/" + userData.value.id + "/registrationProfiles";
        const { posts, error, load } = getData(coll);
        load().then(() => {
          console.log(error.value);
          regProfs.value = posts.value;
        });
      }
    });

    const checkEmail = (e) => {
      console.log(e);
      console.log(emailList.value);

      if (
        typeof emailList.value[e] == "undefined" &&
        typeof emailList.value["\n" + e] == "undefined" &&
        typeof emailList.value[e + "\n"] == "undefined"
      ) {
        return false;
      }
      console.log(getEvent(e));
      return true;
    };

    return {
      userData,
      userDataLoaded,
      listLoaded,
      emailList,
      checkEmail,
      eventData,
      regProfs,
      chosenProfile,
      payCheckout,
      isLoading,
      isFull,
    };
  },
};
</script>

<style scoped>
.player-text {
  max-width: 1000px;
  margin: 0 auto;
}

.player-text h5 {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.player-text h3 {
  font-size: 64px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #f31b1b;
  margin: 0 0 8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.player-text p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin: 0 0 20px;
  position: relative;
}

.clearfix {
  clear: both;
}

.plan-box-outer {
  width: 100%;
  float: left;
  background: #fff;
  display: flex;
  margin: 10px 0;
}

.plan-left-title {
  width: 260px;
  background: rgba(0, 0, 0, 1);
  padding: 20px 10px;
  display: flex;
  flex-flow: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.plan-left-title h4 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 2px;
  position: relative;
  z-index: 9;
  word-break: break-all;
}

.plan-right-disciption {
  width: 100%;
  display: flex;
}

.plan-detail {
  padding: 15px 15px;
}

.plan-detail-top p {
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.plan-detail-top {
  width: 100%;
  float: left;
  margin: 0 0 18px;
}

.plan-timing-list {
  width: 100%;
  float: left;
}

.plan-timing-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.plan-timing-list ul li {
  list-style: none;
  margin: 0;
  padding: 10px 25px 10px 0;
  width: 50%;
  text-align: left;
}

.plan-timing-list ul li .playermiddle-text {
  width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  text-align: left;
  padding: 0px 5px 0px 37px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.plan-timing-list ul li .playermiddle-text span {
  position: absolute;
  font-size: 17px;
  top: -1px;
  left: 12px;
  color: #000000;
}

.light-grey {
  background: #fafafa;
}

.left-search-sec {
  width: 100%;
  background: #fff;
  margin: 10px 0 0;
  height: 98%;
  padding: 20px 15px;
}

.search_bar-players {
  width: 100%;
  float: left;
  position: relative;
}

.search_bar-players .form-control {
  border: 1px solid #c4c4c4;
  background: #fafafa;
  border-radius: 0;
  padding: 9px 14px;
  font-size: 13px;
}

.search_bar-players .btn {
  position: absolute;
  right: 0;
  top: 0;
  background: #f31b1b;
  border-radius: 0;
  color: #fff;
}

.filter-title {
  width: 100%;
  float: left;
  text-align: left;
  margin: 30px 0 5px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 0 10px;
}

.filter-sub-title {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin: 18px 0 10px;
  width: 100%;
  float: left;
}

.filter-search-list {
  width: 100%;
  float: left;
}

.filter-search-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.filter-search-list ul li {
  list-style: none;
  margin: 0;
  padding: 0 0 8px 0;
  width: 100%;
  float: left;
}

.filter-search-list ul li .checkbox {
  width: 100%;
  float: left;
  text-align: left;
}

.filter-search-list ul li label {
  position: relative;
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  padding: 0 0 0 20px;
}

.filter-search-list ul li .checkbox input {
  margin: 0;
  position: absolute;
  left: 0;
  top: 3.8px;
}

.event-detail-sec {
  width: 100%;
  background: #fff;
  padding: 15px;
  float: left;
  margin: 10px 0 0;
}

.event-user-img {
  width: 100%;
  float: left;
  margin: 0 0 10px;
  max-height: 280px;
  overflow: hidden;
}

.event-user-img img {
  width: 100%;
}

.event-name-title {
  width: 100%;
  text-align: left;
  margin: 5px 0 5px;
  float: left;
}

.event-name-title h3 {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 3px 0 5px;
  position: relative;
  z-index: 9;
  word-break: break-all;
  text-transform: uppercase;
}

.event-name-title p {
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.price {
  margin: 12px 0 16px;
  float: left;
  width: 100%;
  text-align: left;
}

.price b {
  color: #000000;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 100%;
  float: left;
  font-family: "Montserrat", sans-serif;
}

.price p {
  margin: 0px 0 3px;
  float: left;
  width: 100%;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
}

.pricein {
  margin: 0px 0 0px 3px;
  padding-left: 25px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
  position: relative;
  top: 0;
}

.price sub {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0px;
  color: #000;
  bottom: 2px;
  margin: 0;
}

.gwt-loc {
  margin: 25px 0px 20px;
  float: left;
  width: 100%;
  text-align: left;
}

.gwt-loc h5 {
  color: #000000;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 100%;
  float: left;
  font-family: "Montserrat", sans-serif;
}

.gwt-loc p {
  margin: 0px 0 3px;
  float: left;
  width: 100%;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
}

.gwt-loc ul {
  width: 100%;
  float: left;
  margin: 4px 0 5px;
  padding: 0 0 0 25px;
}

.gwt-loc ul li {
  margin: 0px 0 3px;
  float: left;
  width: 100%;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
  list-style: decimal;
}

.register-form {
  width: 100%;
  margin: 25px 0 0px;
  float: left;
  background: #fafafa;
  padding: 10px;
}

.register-form h3 {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 23px 0 38px;
  text-transform: uppercase;
}

.register-form-in .form-container {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
}

.register-form-in .form-container.col-3 .form-group {
  max-width: 33.33%;
  width: 33.33%;
}

.register-form-in .form-group {
  max-width: 50%;
  width: 50%;
  padding: 0 10px;
  margin-bottom: 15px;
}

.register-form-in .form-group label {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 2px;
  font-family: "Montserrat", sans-serif;
}

.register-form-in .form-group .form-control {
  border-radius: 0;
  height: 40px;
  font-size: 13px;
  color: #8e8e8e;
  font-family: "Montserrat", sans-serif;
}

.register-form-in .form-group .form-control {
  border-radius: 0;
  height: 40px;
  font-size: 13px;
  color: #8e8e8e;
  font-family: "Montserrat", sans-serif;
}

.register-form-in .form-group.textareafield {
  max-width: 100%;
  width: 100%;
}

.register-form-in .form-group.textareafield .form-control {
  min-height: 110px;
}

.submit-btn {
  text-align: left;
  margin: 14px 0 0;
}

.submit-btn .btn {
  padding: 8px 0;
  color: #e60909;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background-color: rgba(196, 181, 131, 0);
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-radius: 100px 100px 100px 100px;
  border-color: #e60909;
  text-align: center;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 155px;
  letter-spacing: 2px;
}

.submit-btn .btn:hover {
  border-color: #e60909;
  color: #ffffff;
  background-color: #e60909;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.aboutsec-bottom {
  width: 100%;
  float: left;
  display: flex;
  margin: 10px 0 10px;
  background: #23252a;
  padding: 15px;
}

.about-img-left {
  width: 49%;
  padding: 0 20px 0 0;
}

.about-img-left img {
  max-width: 100%;
}

.about-text-right {
  width: 50%;
  padding: 0 0 0 0;
  text-align: center;
}

.about-text-right h5 {
  color: white;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 1px;
  width: 100%;
  float: left;
  font-family: "Montserrat", sans-serif;
}

.about-text-right p {
  margin: 0px 0 12px;
  text-align: center;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: white;
}

@media (max-width: 1200px) {
  .plan-left-title h4 {
    font-size: 17px;
  }

  .player-pack-timing {
    margin: 0px 0 0 6px;
  }

  .register-slct-btn {
    min-width: 120px;
  }

  .plan-detail-top p {
    font-size: 12px;
    line-height: 15px;
  }

  .plan-timing-list ul li .playermiddle-text {
    font-size: 11px;
    line-height: 21px;
  }

  .plan-timing-list ul li {
    padding: 10px 8px 10px 0;
  }
}

@media (max-width: 992px) {
  .left-search-sec {
    height: auto;
    float: left;
  }
}

@media (max-width: 767px) {
  .plan-box-outer {
    display: inline-block;
  }

  .register-form-in .form-container .form-group,
  .register-form-in .form-container.col-3 .form-group {
    max-width: 100% !important;
    width: 100% !important;
  }

  .aboutsec-bottom {
    flex-wrap: wrap;
  }

  .about-img-left {
    width: 100%;
    padding: 0 0px 20px 0;
  }

  .about-text-right {
    width: 100%;
    padding: 0 0 0 0;
    text-align: left;
  }
}

.vimeoWrap2 {
  position: relative;
  padding-bottom: 56.25%;
}

.vimeoWrap2 iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media (max-width: 450px) {
  .plan-timing-list ul li {
    width: 100%;
  }

  .light-grey {
    background: transparent;
  }

  .plan-timing-list ul li:nth-child(2n + 1) {
    background: #fafafa;
  }
}
</style>