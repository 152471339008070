import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_PROJECT_ID + ".firebaseapp.com",
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_PROJECT_ID + ".appspot.com",
  messagingSenderId: "945901333738",
  appId: "1:945901333738:web:96850cfc9a861df754fb93",
  measurementId: "G-LVPEFMEE94",
};

firebase.initializeApp(firebaseConfig);
//console.log("FIREBASE ID:" + firebaseApp.options.projectId);
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

const fBase = firebase;
const firebaseApp = firebase.app();
const firebaseFunctions = firebase.app().functions("us-central1");
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
export {
  projectAuth,
  projectFirestore,
  timestamp,
  firebaseApp,
  firebaseFunctions,
  fBase,
};
