<template>
  <div v-if="!isPremium">
    <sectionTitle />
    <whyFCL />
    <freeTrial />
    <pricing />
  </div>
</template>

<script>
//Premium Walls
import freeTrial from "../../components/freeTrialOffer.vue";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import pricing from "../../components/pricing";

import { computed, ref, watch, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  components: {
    pricing,
    sectionTitle,
    whyFCL,
    freeTrial,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isPremium = computed(() => {
      console.log(store.getters.getIsPremium);
      return store.getters.getIsPremium;
    });
    return { isPremium };
  },
};
</script>

<style>
</style>