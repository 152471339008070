import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag-next";
import "./assets/css/volt.css";
import { loadStripe } from "@stripe/stripe-js";

const stripe = loadStripe(
  "pk_test_51IqpudINbM8Ghj39JofFXCMtsRwKaDSCT8oIENe7GfBM2W0snnzbJgVtEShhL4KnRtwfcHSgzTBhd5m4WfzfhYvy00XEXWd9H1"
);

import "./assets/@fortawesome/fontawesome-free/css/all.min.css";

import { store } from "./store";
const app = createApp(App);
app.use(router);
app.use(store);

app.use(VueGtag, {
  property: {
    id: "G-56CL798G1E",
    useDebugger: true,
  },
});

app.mount("#app");
