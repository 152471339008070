<template>
  <h1>FCL Online Support</h1>
  <p>Please email contact@1stclasslax.com with any issues.</p>
  <!-- <form @submit.prevent="sendMessage">
    <div class="mb-3">
      <label class="my-1 me-2" for="issue">Issue Category</label>
      <select
        v-model="issueCategory"
        class="form-select"
        id="issue"
        aria-label="Default select example"
      >
        <option value="Billing">Billing</option>
        <option value="Video Playback">Video Playback</option>
        <option value="Event Registration">Event Registration</option>
      </select>
    </div>
    <div class="mb-3">
      <label class="my-1 me-2" for="email">Your Email:</label>

      <input
        type="email"
        v-model="issueEmail"
        class="form-control"
        id="email"
        required
      />
    </div>
    <div class="mb-3">
      <label class="my-1 me-2" for="userName">Your Name:</label>

      <input
        type="text"
        v-model="issueName"
        class="form-control"
        id="userName"
        required
      />
    </div>
    <div class="mb-3">
      <label for="exampleFormControlTextarea1" class="form-label"
        >Explain the issue</label
      >
      <textarea
        v-model="issueMessage"
        class="form-control"
        id="exampleFormControlTextarea1"
        rows="3"
      ></textarea>
    </div>
    <button class="btn btn-dark">Send Support Message</button>
  </form> -->
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import useCollection from "../../composables/useCollectionSupport";
import { computed } from "vue";

export default {
  setup() {
    const { addDoc, error2, isPending2 } = useCollection(
      "fclOnlineSupportMessages"
    );
    const issueCategory = ref("");
    const issueMessage = ref("");
    const issueEmail = ref("");
    const issueName = ref("");
    const store = useStore();
    const userData = computed(() => store.state.userData);
    const sendSupportMessage = async () => {
      const supportObject = {
        userEmail: issueEmail.value,
        userName: issueName.value,
        issueStatus: "New",
        issueCategory: issueCategory.value,
        issueMessage: issueMessage.value,
      };
      await addDoc(supportObject);
      if (!error2.value) {
        alert("Support message has been sent!");
        issueCategory.value = "";
        issueMessage.value = "";
      } else {
        console.log(error2.value);
      }
    };

    const sendMessage = () => {
      if (issueCategory.value == "") {
        alert("Please choose an issue category");
        return;
      }
      if (issueMessage.value == "") {
        alert("Please enter a message explaining the issue.");
        return;
      }
      sendSupportMessage();
    };
    return { issueCategory, issueMessage, sendMessage, issueEmail, issueName };
  },
};
</script>

<style>
</style>