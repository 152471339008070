import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getSubs = (id) => {
  const subs = ref([]);
  const error = ref(null);

  const load = async () => {
    try {
      const res = await projectFirestore
        .collection("fclUsers/" + id + "/subscriptions")
        .get();

      subs.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      error.value = err.message;
    }
  };

  return { subs, error, load };
};

export default getSubs;
