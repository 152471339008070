import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getVideos = () => {
  const vids = ref([]);
  const error = ref(null);

  const load = async () => {
    try {
      const res = await projectFirestore.collection("Videos").get();

      vids.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      error.value = err.message;
    }
  };

  return { vids, error, load };
};

export default getVideos;
