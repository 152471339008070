import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getWH = (id) => {
  const wh = ref([]);
  const error3 = ref(null);

  const load3 = async () => {
    try {
      const res = await projectFirestore
        .collection("fclUsers/" + id + "/workoutData")
        .get();

      wh.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      console.log(err.message);
      error3.value = err.message;
    }
  };

  return { wh, error3, load3 };
};

export default getWH;
