<template>
  <loadingSpinner v-if="isLoading" />

  <section class="pricing-section">
    <div class="container">
      <div class="pricing-text">
        <h5>SIGN UP FOR</h5>
        <h3>FCL Online</h3>
      </div>
      <div class="bottom-pricing-tables">
        <div class="row">
          <div class="col-lg-4 col-md-12">
            <div class="pricing-box">
              <div class="price-header">
                <h4>QUARTERLY</h4>
                <p>Subscription</p>
              </div>
              <div class="plan-priceB">
                <div class="plan-price-in">
                  <p class="curency">$</p>
                  <p class="pack-price">19</p>
                  <p class="pack-end-price">99</p>
                  <p class="pack-timing">PER MONTH</p>
                </div>
              </div>
              <div class="plan-detail">
                <ul>
                  <li>
                    <span><i class="bx bx-calendar"></i></span>
                    <strong>Weekly Workouts</strong> Workouts customized to
                    every level player at every position, all tied to
                    demonstration videos
                  </li>
                  <li>
                    <span><i class="bx bxs-video"></i></span>
                    <strong>Training Videos</strong> A library of over 100 wall
                    ball, shooting, defensive and footwork drills essential to
                    your development
                  </li>
                  <li>
                    <span><i class="bx bx-chalkboard"></i></span>
                    <strong>Instructional Videos</strong> Quick hitting
                    instructional videos focusing on technique
                  </li>
                  <li>
                    <span><i class="bx bxs-film"></i></span>
                    <strong>Film Breakdowns</strong> Raise your lacrosse IQ by
                    studying detailed analysis of real game film at the highest
                    levels
                  </li>
                  <li>
                    <span><i class="bx bx-git-pull-request"></i></span>
                    <strong>Live and Recorded Webinars</strong> Take a deep dive
                    into key principles from skill to team concepts in our in
                    depth webinars with live Q&amp;A
                  </li>
                </ul>
              </div>
              <div class="pack-btn">
                <button
                  v-if="!isLoading"
                  @click="payCheckout('price_1IqrGhINbM8Ghj39eIhpgM4I')"
                  class="fourButton pack-slct-btn"
                >
                  Start Training
                </button>
                <button
                  v-if="isLoading"
                  class="fourButton pack-slct-btn"
                  disabled
                >
                  Loading Checkout...
                </button>
                <p class="bill-text">
                  Billed 3-monthly.<br />
                  Cancel future payments anytime.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 bg-color-change">
            <div class="pricing-box">
              <div class="price-header">
                <h4>ANNUAL - BEST VALUE</h4>
                <p>Subscription</p>
              </div>
              <div class="plan-price">
                <div class="plan-price-in">
                  <p class="curency">$</p>
                  <p class="pack-price">14</p>
                  <p class="pack-end-price">99</p>
                  <p class="pack-timing">PER MONTH</p>
                </div>
              </div>
              <div class="plan-detail">
                <ul>
                  <li>
                    <span><i class="bx bx-calendar"></i></span>
                    <strong>Weekly Workouts</strong> Workouts customized to
                    every level player at every position, all tied to
                    demonstration videos
                  </li>
                  <li>
                    <span><i class="bx bxs-video"></i></span>
                    <strong>Training Videos</strong> A library of over 100 wall
                    ball, shooting, defensive and footwork drills essential to
                    your development
                  </li>
                  <li>
                    <span><i class="bx bx-chalkboard"></i></span>
                    <strong>Instructional Videos</strong> Quick hitting
                    instructional videos focusing on technique
                  </li>
                  <li>
                    <span><i class="bx bxs-film"></i></span>
                    <strong>Film Breakdowns</strong> Raise your lacrosse IQ by
                    studying detailed analysis of real game film at the highest
                    levels
                  </li>
                  <li>
                    <span><i class="bx bx-git-pull-request"></i></span>
                    <strong>Live and Recorded Webinars</strong> Take a deep dive
                    into key principles from skill to team concepts in our in
                    depth webinars with live Q&amp;A
                  </li>
                </ul>
              </div>
              <div class="pack-btn">
                <button
                  v-if="!isLoading"
                  @click="payCheckout('price_1IqrPTINbM8Ghj39qSrR8gcc')"
                  class="fourButton pack-slct-btn"
                >
                  Start Training
                </button>
                <button
                  v-if="isLoading"
                  class="fourButton pack-slct-btn"
                  disabled
                >
                  Loading Checkout...
                </button>
                <p class="bill-text">
                  Billed Annually.<br />
                  Cancel future payments anytime.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 lastpricbox">
            <div class="pricing-box">
              <div class="price-header">
                <h4>SEMI-ANNUALLY</h4>
                <p>Subscription</p>
              </div>
              <div class="plan-priceB">
                <div class="plan-price-in">
                  <p class="curency">$</p>
                  <p class="pack-price">17</p>
                  <p class="pack-end-price">99</p>
                  <p class="pack-timing">PER MONTH</p>
                </div>
              </div>
              <div class="plan-detail">
                <ul>
                  <li>
                    <span><i class="bx bx-calendar"></i></span>
                    <strong>Weekly Workouts</strong> Workouts customized to
                    every level player at every position, all tied to
                    demonstration videos
                  </li>
                  <li>
                    <span><i class="bx bxs-video"></i></span>
                    <strong>Training Videos</strong> A library of over 100 wall
                    ball, shooting, defensive and footwork drills essential to
                    your development
                  </li>
                  <li>
                    <span><i class="bx bx-chalkboard"></i></span>
                    <strong>Instructional Videos</strong> Quick hitting
                    instructional videos focusing on technique
                  </li>
                  <li>
                    <span><i class="bx bxs-film"></i></span>
                    <strong>Film Breakdowns</strong> Raise your lacrosse IQ by
                    studying detailed analysis of real game film at the highest
                    levels
                  </li>
                  <li>
                    <span><i class="bx bx-git-pull-request"></i></span>
                    <strong>Live and Recorded Webinars</strong> Take a deep dive
                    into key principles from skill to team concepts in our in
                    depth webinars with live Q&amp;A
                  </li>
                </ul>
              </div>
              <div class="pack-btn">
                <button
                  v-if="!isLoading"
                  @click="payCheckout('price_1IqrPTINbM8Ghj39tFXq2jAZ')"
                  class="fourButton pack-slct-btn"
                >
                  Start Training
                </button>
                <button
                  v-if="isLoading"
                  class="fourButton pack-slct-btn"
                  disabled
                >
                  Loading Checkout...
                </button>
                <p class="bill-text">
                  Billed 6-monthly.<br />
                  Cancel future payments anytime.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/reactivity";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import loadingSpinner from "../components/loadingSpinner.vue";

export default {
  components: { loadingSpinner },

  setup() {
    const isLoading = ref(false);
    const store = useStore();
    const userData = computed(() => store.state.userData);
    let sessionID = "";
    let stripe = null;
    let functionURL = null;
    if (process.env.VUE_APP_ENV == "test") {
      stripe = Stripe(
        "pk_test_51IqpudINbM8Ghj39JofFXCMtsRwKaDSCT8oIENe7GfBM2W0snnzbJgVtEShhL4KnRtwfcHSgzTBhd5m4WfzfhYvy00XEXWd9H1"
      );
      functionURL =
        "https://us-central1-fcl-online-dev.cloudfunctions.net/programSubscriptionConnect";
    } else {
      stripe = Stripe(
        "pk_live_51IqpudINbM8Ghj39Bg3LwWn3JyCEKocMuGysh8I9iewXNPRrz7NbtH7ZsG2K3gpC5Su7o3AtbHpH4vIoVOcRYKtn00xPMXB0AP"
      );
      functionURL =
        "https://us-central1-first-class-lax-c0894.cloudfunctions.net/getSubscription";
    }

    const payCheckout = (type) => {
      if (userData.value.stripeId != null) {
        isLoading.value = true;
        axios
          .get(functionURL, {
            params: { item: type, id: userData.value.stripeId },
          })
          .then((response) => {
            sessionID = response.data.id;
            stripe
              .redirectToCheckout({
                sessionId: sessionID,
              })
              .then(function (result) {
                isLoading.value = false;
                if (result.error) {
                  alert(result.error);
                }
              });
          })
          .catch((error) => {
            console.log(error);
            isLoading.value = false;
          });
      } else {
        alert(
          "Error: User Missing Stripe ID. Please contact Support at contact@1stclasslax.com"
        );
      }
    };
    return { payCheckout, isLoading };
  },
};
</script>

<style>
.btnPlayBot {
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 20px;
  right: 0;
}

.pricing-section {
  min-height: 322px;
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../assets/img/Banners/banner-bg.png");
}

.pricing-text {
  max-width: 1000px;
  margin: 0 auto;
}

.pricing-text h5 {
  font-family: "redwingMedium";
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.pricing-text h3 {
  font-family: "hemi";
  font-size: 64px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #db3a34;
  margin: 0 0 8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.pricing-text p {
  font-family: "redwingMedium";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin: 0 0 20px;
  position: relative;
  letter-spacing: 1px;
}

.pricing-text p span {
  color: #db3a34;
  font-weight: 800;
}

.pricing-text p.small-text {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  padding: 0 0 18px;
}

.pricing-text p.small-text::after {
  background: #fff;
  content: "";
  width: 30px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.clearfix {
  clear: both;
}

.bottom-pricing-tables {
  width: 100%;
  float: left;
  margin: 25px 0 15px;
}

.pricing-box {
  margin: 0;
  border-radius: 7px 7px 7px 7px;
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 0 0 30px;
}

.price-header {
  background-color: #e5e5e5;
  padding: 25px 0px 54px 0px;
  margin: 0 0 -45px 0px;
}

.price-header h4 {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 2px;
}

.price-header p {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 0px;
}

.plan-price {
  background: #db3a34;
  max-width: 150px;
  border-radius: 50%;
  margin: 0px auto;
  height: 150px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.plan-priceB {
  background: #326fb5;
  max-width: 150px;
  border-radius: 50%;
  margin: 0px auto;
  height: 150px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.plan-price-in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.curency {
  font-family: "Montserrat", sans-serif;
  align-self: flex-start;
  font-size: 17px;
  color: #f7f7f7;
  font-weight: 700;
  margin: 0;
}

.pack-end-price {
  font-family: "Montserrat", sans-serif;
  align-self: flex-start;
  font-size: 17px;
  color: #f7f7f7;
  font-weight: 700;
  margin: 0;
}

.fourButton {
  background: #db3a34;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px !important;
  margin-top: 10px;
}

.fourButton:hover {
  background: #326fb5 !important;
  color: white;
}

.pack-price {
  font-family: "Montserrat", sans-serif;
  color: #f7f7f7;
  font-size: 57px;
  font-weight: 700;
  margin: 0;
  line-height: 61px;
  padding: 0 0 0 6px;
}

.pack-timing {
  color: #f7f7f7;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: -6px 0 0 -9px;
  padding: 0;
}

.plan-detail {
  width: 100%;
  float: left;
  margin: 0 0 0;
  padding: 25px 50px 10px 30px;
}

.plan-detail ul {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}

.plan-detail ul li {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}

.plan-detail ul li {
  width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  text-align: left;
  padding: 15px 0 15px 30px;
  border-bottom: 1px solid #e6e6e6;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.plan-detail ul li span {
  position: absolute;
  font-size: 24px;
  top: 16px;
  left: 0;
  color: #545454;
}

.plan-detail ul li:last-child {
  border-bottom: 0px solid #e6e6e6;
}

.pack-slct-btn {
  font-family: "redwingMedium";
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 2px;

  font-weight: 500;
}

.pack-slct-btn:hover {
  border-color: #db3a34;
  color: #ffffff;
  background-color: #db3a34;
  transform: scale(1.1);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bill-text {
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 200;
  line-height: 1.7em;
  margin: 15px 30px 0px 30px;
}

.bottom-pricing-tables .row {
  align-content: center;
  align-items: center;
  justify-content: center;
}

.bottom-pricing-tables .col-lg-4 {
  margin: 0 -20px;
}

.bg-color-change .pricing-box {
  box-shadow: 10px 0px 40px 0px rgba(0, 0, 0, 0.5);
  position: relative;
}

.lastpricbox .plan-detail {
  padding: 25px 37px 10px 43px;
}

@media (max-width: 991px) {
  .bottom-pricing-tables .col-lg-4 {
    margin: 0;
  }

  .pricing-box {
    margin: 0 0 20px;
  }

  .pricing-section {
    padding: 30px 0px;
  }

  .plan-detail {
    padding: 25px 30px 10px 30px;
  }
}

@media (max-width: 767px) {
  .lastpricbox .plan-detail {
    padding: 25px 25px 10px;
  }
}
</style>