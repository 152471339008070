import { createRouter, createWebHistory } from "vue-router";
import { trackRouter } from "vue-gtag-next";

import { fBase } from "../firebase/config";
import Home from "../views/Home.vue";

//Auth pages
import Login from "../views/Auth/Login.vue";
import Signup from "../views/Auth/Signup.vue";

import ForgotPass from "../views/Auth/forgotPass.vue";

//other pages
import Support from "../views/Other/Support";
import TandC from "../views/Other/TermsandCon";
import PrivacyP from "../views/Other/Privacy";
import Profile from "../views/Other/Profile";
import About from "../views/Other/About";

//Video Pages
import Video from "../views/Videos/Videos";
import singleVid from "../views/Videos/videoSingle";

//workouts
import Workouts from "../views/Workouts/workouts";
import singleWorkout from "../views/Workouts/workoutIndividual";

//events
import events from "../views/events";
import liveReps from "../views/liveReps";

//best in class
import bic from "../views/bic";

//store
import { store } from "../store/index";
import Feedback from "../views/feedback";
import singleEvent from "../views/eventSingle";

//store Front
import storeFront from "../views/storeFront";
// Programs
import programManager from "../views/Programs/program-manager";
import viewPlayerActivity from "../views/Programs/player-activity";

//purchase pages
import fclonlinesub from "../views/purchase/fcl-online-sub";
import freetrial from "../views/purchase/fcl-online-trial";
import coupon from "../views/purchase/coupon";
import ambassadorDirect from "../views/purchase/ambassadorDirect";
//FCL Live Events
import FCLLiveCal from "../views/FCL_Live/fcl_live_calendar";
import FCLLiveIndiv from "../views/FCL_Live/fcl_live_individual";
import FCLLivePast from "../views/FCL_Live/fcl_live_past";

//courses
import Courses from "../views/courses/courses";
import CoursesIndividual from "../views/courses/coursesIndividual";

import FCLOnlineProductDirect from "../views/purchase/fcl-online-product-direct.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/merchandise",
    name: "storefront",
    component: storeFront,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ambassador/:id",
    name: "ambassadorDirect",
    component: ambassadorDirect,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      requiresNAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPass",
    component: ForgotPass,
    meta: {
      requiresAuth: false,
      requiresNAuth: true,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      requiresAuth: false,
      requiresNAuth: true,
    },
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/:id",
    name: "Profile",
    component: Profile,
    alias: "/profile",
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/program-manager",
    name: "programManager",
    component: programManager,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/program-manager/viewplayeractivity/:uid",
    name: "viewPlayerActivity",
    component: viewPlayerActivity,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/events",
    name: "Events",
    component: events,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/livereps",
    name: "liveReps",
    component: liveReps,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/termsandconditions",
    name: "TandC",
    component: TandC,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/privacypolicy",
    name: "PrivacyP",
    component: PrivacyP,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: Feedback,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/bestinclass",
    name: "bic",
    component: bic,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/videos/:videoTopic/:position",
    name: "Video",
    component: Video,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/courses/:id",
    name: "CoursesIndividual",
    component: CoursesIndividual,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/courses",
    name: "Courses",
    component: Courses,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/subscribe/:interval",
    name: "fclonlinesub",
    component: fclonlinesub,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/fcllive/event/:id",
    name: "FCLLiveIndiv",
    component: FCLLiveIndiv,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/buyonline/:product",
    name: "FCLOnlineProductDirect",
    component: FCLOnlineProductDirect,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/fcllive/pastevents",
    name: "FCLLivePast",
    component: FCLLivePast,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/fcllive/calendar",
    name: "FCLLiveCal",
    component: FCLLiveCal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/coupon/:coupon",
    name: "coupon",
    component: coupon,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/freetrial",
    name: "freetrial",
    component: freetrial,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/videos/series/:seriesID",
    name: "singleVid",
    component: singleVid,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/workouts",
    name: "Workouts",
    component: Workouts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/workouts/:id",
    name: "singleWorkout",
    component: singleWorkout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/events/:eventID",
    name: "singleEvent",
    component: singleEvent,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  window.scrollTo(0, 0);
  let currentUser = store.state.user;
  let requriesAuth = to.matched.some((record) => record.meta.requiresAuth);
  let requriesNotAuth = to.matched.some((record) => record.meta.requiresNAuth);
  const curUser = await fBase.getCurrentUser();
  var _hsq = (window._hsq = window._hsq || []);

  if (requriesAuth && !curUser) {
    const loginpath = window.location.pathname;
    if (window.location.pathname == "/freetrial") {
      _hsq.push(["setPath", "/Signup"]);
      next({ name: "Signup", query: { from: loginpath } });
    } else {
      _hsq.push(["setPath", "/Login"]);
      next({ name: "Login", query: { from: loginpath } });
    }
  }
  if (requriesNotAuth && curUser) {
    _hsq.push(["setPath", "/home"]);
    next({ name: "Home" });
  } else {
    console.log(to);
    _hsq.push(["setPath", to.fullpath]);
    next();
  }

  _hsq.push(["trackPageView"]);
});
trackRouter(router);
export default router;
