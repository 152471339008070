import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getProgramData = (k) => {
  const programData = ref([]);
  const errorProgram = ref(null);

  const loadProgram = async () => {
    try {
      const res = await projectFirestore
        .collection("programs")
        .doc(k)
        .get();

      programData.value = { ...res.data(), id: res.id };
    } catch (err) {
      console.log(err.message);
      errorProgram.value = err.message;
    }
  };

  return { programData, errorProgram, loadProgram };
};

export default getProgramData;
