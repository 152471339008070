<template>
  <div v-if="error">Error Loading event: {{ error }}</div>
  <div v-if="!error && document && userData">
    <div v-if="userData.usrType != 'program'">
      <loadingSpinner v-if="isLoading" />

      <eventSectionTitle
        :title="document.eventName"
        :description="document.eventDescription"
      />
      <br />

      <div class="plan-timing-list">
        <ul>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-calendar"></i></span>
              {{ document.startDate }}
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-clock"></i></span> {{ document.eventTime }}
            </p>
          </li>
          <li>
            <p class="playermiddle-text">
              <span><i class="fa fa-map-marker"></i></span>
              {{ document.eventAddress }}
            </p>
          </li>
          <li>
            <p class="playermiddle-text">
              <span><i class="fa fa-dot-circle"></i></span> Position: Offense
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fa fa-dot-circle"></i></span>
              Gender Focus: {{ document.genderFocus }}
            </p>
          </li>
          <li class="light-grey">
            <p class="playermiddle-text">
              <span><i class="fas fa-dollar-sign"></i></span>
            </p>
            <span class="pricein">
              {{ document.eventPrice }} <sub>PER PLAYER</sub></span
            >
          </li>
        </ul>
      </div>

      <div class="clearfix"></div>

      <br />
      <!-- Coaches Section Start -->
      <div v-for="coach in document.eventCoaches" :key="coach">
        <!-- Deemer Section -->
        <!-- <div v-if="coach == 'Deemer Class'"> -->
        <div v-if="false">
          <!-- <div class="about-img-left">
          <img src="../assets/img/Coaches/DC_Bio.jpg" alt="" />
        </div> -->
          <!-- <div class="about-text-right"> -->
          <h2 style="text-align: center" class="new">
            FCL Coaches at This Clinic
          </h2>
          <h5>Coach Deemer Class</h5>
          <p>
            Born in Baltimore, MD, Deemer has played in the highest levels of
            the game – professionally, Division 1, and the most elite high
            school conference in the country. Deemer is widely regarded as one
            of the best outside shooters in lacrosse, and has developed a
            reputation for coaching offensive techniques from shooting, dodging
            and re-dodging, stick work, IQ and offensive sets. Coach Class
            attended Loyola Blakefield, where he helped lead Loyola to a MIAA
            championship game appearance and was a 2x USL All American and Under
            Armour all American.
          </p>
          <!-- </div> -->
        </div>
      </div>
      <!-- Coaches Section End -->
      <!-- <div v-if="document.eventType == 'liveReps'"> -->
      <div v-if="document.eventType == false">
        <div class="aboutsec-bottom">
          <div class="about-text-right">
            <h5>About FCL Live Rep Sessions</h5>

            <p>
              FCL Live Reps are our Elite Live Rep sessions will give
              opportunity to apply and practice our skills in a competitive
              practice like environment. Players will build IQ, decision making
              skills and be immersed in game like situations. We believe in
              providing an environment that is highly competitive and one where
              you are ok to make mistakes and grow. These are great sessions to
              be evaluated at for our Best In Class series of events.
            </p>
            <p>Please check out this video of a live rep session in action</p>
          </div>
          <div class="about-img-left">
            <div class="vimeoWrap2">
              <iframe
                src="https://player.vimeo.com/video/568624098"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <!-- Map Section -->
      <div v-if="document.gmap">
        <iframe
          :src="document.gmap"
          width="100%"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>

      <br />
      <br />
      <!-- event is sold out -->
      <div v-if="document.eventPopCap <= document.currentRegister">
        <h1 style="text-align: center">Sorry, this event is sold out.</h1>
      </div>

      <div v-if="document.eventType == 'archived'">
        <h1>Sorry, this event has been cancelled</h1>
      </div>
      <div
        v-if="
          (!document.currentRegister ||
            document.eventPopCap > document.currentRegister) &&
          document.eventType != 'archived'
        "
      >
        <div v-if="regProfs.length == 0">
          <!-- Add event registration profile Start -->
          <div class="col-12 col-xl-12 mb-4">
            <div class="card card-body bg-white border-light shadow-sm mb-4">
              <h2 class="h5 mb-4">Add Event Registration Profile</h2>
              <p>
                You don't have any event registration profiles on your account.
                Event registration profiles provide an easy way for you to
                checkout multiple players for events within one account. You can
                add as many as you like from profile section on the fcl portal.
                Get started by adding your first one below!
              </p>
              <form @submit.prevent="addSubProfile" class="mt-4">
                <!-- Row One -->
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div>
                      <label for="first_name">First Name *</label>
                      <input
                        class="form-control"
                        id="pfirst_name"
                        type="text"
                        placeholder="John"
                        required
                        v-model="firstNameProf"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div>
                      <label for="last_name">Last Name *</label>
                      <input
                        class="form-control"
                        id="plast_name"
                        type="text"
                        placeholder="Smith"
                        v-model="lastNameProf"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="email">Player Email *</label>
                      <input
                        class="form-control"
                        id="pemail"
                        type="email"
                        placeholder="name@company.com"
                        v-model="emailProf"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="email">Emergency Contact Name *</label>
                      <input
                        class="form-control"
                        id="emergencyName"
                        type="text"
                        placeholder="John Smith"
                        v-model="emergencyName"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="phone">Emergency Contact Phone *</label>
                      <input
                        class="form-control"
                        id="emergencyPhone"
                        type="tel"
                        placeholder="xxx-xxx-xxxx"
                        v-model="emergencyPhone"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="email"
                        >Emergency Contact relationship *</label
                      >
                      <input
                        class="form-control"
                        id="emergencyName"
                        type="text"
                        placeholder="Parent"
                        required
                      />
                    </div>
                  </div>
                </div>
                <!-- row two -->
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="phone">Player Phone *</label>
                      <input
                        class="form-control"
                        id="phone"
                        type="tel"
                        placeholder="xxx-xxx-xxxx"
                        v-model="phoneProf"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="gender">Graduation Year *</label>
                    <select
                      class="form-select mb-0"
                      id="gradYear"
                      v-model="gradYearProf"
                      required
                    >
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      <option value="2026">2026</option>
                      <option value="2027">2027</option>
                      <option value="2028">2028</option>
                      <option value="2029">2029</option>
                      <option value="2030">2030</option>
                      <option value="2031">2031</option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="gender">Gender Focus *</label>
                    <select
                      class="form-select mb-0"
                      id="gender"
                      aria-label="Gender select example"
                      required
                      v-model="genderProf"
                    >
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-4 mb-3">
                    <label for="gender">State *</label>
                    <select
                      class="form-select mb-0"
                      id="state"
                      aria-label="Gender select example"
                      v-model="stateProf"
                      required
                    >
                      <option value="AL">Alabama</option>
                      <option value="AK">Alaska</option>
                      <option value="AZ">Arizona</option>
                      <option value="AR">Arkansas</option>
                      <option value="CA">California</option>
                      <option value="CO">Colorado</option>
                      <option value="CT">Connecticut</option>
                      <option value="DE">Delaware</option>
                      <option value="DC">District Of Columbia</option>
                      <option value="FL">Florida</option>
                      <option value="GA">Georgia</option>
                      <option value="HI">Hawaii</option>
                      <option value="ID">Idaho</option>
                      <option value="IL">Illinois</option>
                      <option value="IN">Indiana</option>
                      <option value="IA">Iowa</option>
                      <option value="KS">Kansas</option>
                      <option value="KY">Kentucky</option>
                      <option value="LA">Louisiana</option>
                      <option value="ME">Maine</option>
                      <option value="MD">Maryland</option>
                      <option value="MA">Massachusetts</option>
                      <option value="MI">Michigan</option>
                      <option value="MN">Minnesota</option>
                      <option value="MS">Mississippi</option>
                      <option value="MO">Missouri</option>
                      <option value="MT">Montana</option>
                      <option value="NE">Nebraska</option>
                      <option value="NV">Nevada</option>
                      <option value="NH">New Hampshire</option>
                      <option value="NJ">New Jersey</option>
                      <option value="NM">New Mexico</option>
                      <option value="NY">New York</option>
                      <option value="NC">North Carolina</option>
                      <option value="ND">North Dakota</option>
                      <option value="OH">Ohio</option>
                      <option value="OK">Oklahoma</option>
                      <option value="OR">Oregon</option>
                      <option value="PA">Pennsylvania</option>
                      <option value="RI">Rhode Island</option>
                      <option value="SC">South Carolina</option>
                      <option value="SD">South Dakota</option>
                      <option value="TN">Tennessee</option>
                      <option value="TX">Texas</option>
                      <option value="UT">Utah</option>
                      <option value="VT">Vermont</option>
                      <option value="VA">Virginia</option>
                      <option value="WA">Washington</option>
                      <option value="WV">West Virginia</option>
                      <option value="WI">Wisconsin</option>
                      <option value="WY">Wyoming</option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div>
                      <label for="first_name">City Name *</label>
                      <input
                        class="form-control"
                        id="city_name"
                        type="text"
                        placeholder="New York"
                        v-model="cityProf"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div>
                      <label for="first_name">Player School Name *</label>
                      <input
                        class="form-control"
                        id="school_name"
                        type="text"
                        placeholder="Briarcliff Middle School"
                        v-model="schoolProf"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label for="gender">Player Position *</label>
                    <select
                      class="form-select mb-0"
                      id="position"
                      aria-label="Gender select example"
                      required
                      v-model="positionProf"
                    >
                      <option value="Attack">Attack</option>
                      <option value="Defense">Defense</option>
                      <option value="Face Off">Face Off</option>
                      <option value="Goalie">Goalie</option>
                      <option value="Long Stick Midfielder">
                        Long Stick Midfielder
                      </option>
                      <option value="Midfield">Midfield</option>
                    </select>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label for="gender">T Shirt Size *</label>
                    <select
                      class="form-select mb-0"
                      id="tshirtsize"
                      v-model="tshirtSizeProf"
                      required
                    >
                      <option value="XS">XS</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="gender">Shorts Size *</label>
                    <select
                      class="form-select mb-0"
                      id="shortSize"
                      required
                      v-model="shortSizeProf"
                    >
                      <option value="XS">XS</option>
                      <option value="S">S</option>
                      <option value="M">M</option>
                      <option value="L">L</option>
                      <option value="XL">XL</option>
                      <option value="XXL">XXL</option>
                    </select>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-md-4 mb-3">
                    <div>
                      <label for="first_name">Player Club Program Name</label>
                      <input
                        class="form-control"
                        id="program_name"
                        type="text"
                        placeholder="FCL Club"
                        v-model="clubProf"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div>
                      <label for="last_name">Instagram Handle</label>
                      <input
                        class="form-control"
                        id="insta"
                        type="text"
                        placeholder="@22Classy"
                        v-model="instaProf"
                      />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <div class="form-group">
                      <label for="address">Player Highlight Video Link</label>
                      <input
                        class="form-control"
                        id="address"
                        type="text"
                        placeholder="https://www.youtube.com/"
                        v-model="highlightVideoProf"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-4">
                  <button
                    class="btn btn-primary btn-dark mt-2 animate-up-2"
                    type="submit"
                  >
                    Add Player Profile
                  </button>
                </div>
              </form>
            </div>
          </div>
          <!-- Add Event Registration Profile End -->
          <button class="btn btn-primary" style="width: 100%" disabled>
            No Registration Profile Found
          </button>
        </div>
        <div v-if="regProfs.length > 0">
          <label for="gender"
            >Select the player registration profile that you would like to
            register for this event. You can add a different player in your
            <u>
              <router-link to="/profile" tag="a"
                >Account Profile Section</router-link
              ></u
            ></label
          >
          <select
            class="form-select mb-0"
            v-model="chosenProfile"
            id="profSelect"
            required
          >
            <option
              v-for="profile in regProfs"
              :key="profile"
              :value="profile.id"
            >
              {{ profile.firstName }} {{ profile.lastName }} -
              {{ profile.position }}
            </option>
          </select>
          <br />
          <button
            class="btn btn-primary"
            @click="payCheckout(document.id)"
            style="width: 100%"
            :disabled="chosenProfile == ''"
          >
            <span v-if="chosenProfile != ''">Register Now</span>
            <span v-if="chosenProfile == ''"
              >Select a Registration Profile</span
            >
          </button>
        </div>
      </div>
    </div>
    <div v-if="userData.usrType == 'program'">
      <h6>Access Denied</h6>
      <p>
        We are sorry for the inconvenience but you are currently logged in as a
        program. Please sign out and sign in with an individual account in order
        to register for events
      </p>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import getDocument from "../composables/getDocument";
import axios from "axios";
import loadingSpinner from "../components/loadingSpinner.vue";
import { useStore } from "vuex";
import { computed, ref, watch, watchEffect } from "vue";
import getData from "../composables/getData";
import useCollection from "../composables/useCollectionSubProf";
import eventSectionTitle from "../components/eventSectionTitle";

export default {
  components: { eventSectionTitle, loadingSpinner },

  setup() {
    const route = useRoute();
    const eventID = route.params.eventID;
    const { error, document } = getDocument("events", eventID);
    const { error2, addDoc, isPending2 } = useCollection();

    const store = useStore();
    const userData = computed(() => store.state.userData);
    const isLoading = ref(false);
    const regProfs = ref([]);
    const chosenProfile = ref("");
    let stripe = null;
    let functionURL = null;

    stripe = Stripe(
      "pk_live_51IqpudINbM8Ghj39Bg3LwWn3JyCEKocMuGysh8I9iewXNPRrz7NbtH7ZsG2K3gpC5Su7o3AtbHpH4vIoVOcRYKtn00xPMXB0AP"
    );
    functionURL =
      "https://us-central1-first-class-lax-c0894.cloudfunctions.net/CheckoutSession";

    let sessionID = "";

    //Player event profile variables
    const firstNameProf = ref("");
    const lastNameProf = ref("");
    const emailProf = ref("");
    const phoneProf = ref("");
    const gradYearProf = ref("");
    const genderProf = ref("");
    const stateProf = ref("");
    const tshirtSizeProf = ref("");
    const shortSizeProf = ref("");
    const cityProf = ref("");
    const positionProf = ref("");
    const schoolProf = ref("");
    const clubProf = ref("");
    const instaProf = ref("");
    const highlightVideoProf = ref("");
    const emergencyName = ref("");
    const emergencyPhone = ref("");

    const payCheckout = (docID) => {
      //Check to make sure the profile has a user ID
      if (
        userData.value.stripeId == null ||
        userData.value.stripeId == undefined
      ) {
        alert(
          "Error: No Stripe ID found for this account. Please contact support at contact@1stclasslax.com and we will fix it asap. Sorry for the inconvenience"
        );
        return;
      }
      if (chosenProfile.value == "") {
        alert("Please choose a registration profile.");
        return;
      }
      console.log(userData.value.id);
      isLoading.value = true;
      axios
        .get(functionURL, {
          params: {
            event: docID,
            id: userData.value.stripeId,
            subProf: chosenProfile.value,
            userUID: userData.value.id,
          },
        })
        .then((response) => {
          sessionID = response.data.id;
          stripe
            .redirectToCheckout({
              sessionId: sessionID,
            })
            .then(function (result) {
              isLoading.value = false;
              if (result.error) {
                alert(result.error);
              }
            });
        })
        .catch((error) => {
          console.log(error);
          isLoading.value = false;
        });
    };

    const addSubProfile = async () => {
      if (firstNameProf.value == "") {
        alert("Please Enter a First Name");
        return;
      }
      if (lastNameProf.value == "") {
        alert("Please Enter a Last Name");
        return;
      }
      if (emailProf.value == "") {
        alert("Please Enter an Email");
        return;
      }
      if (emergencyName.value == "") {
        alert("An Emergency contact Name Must be added.");
        return;
      }
      if (emergencyPhone.value == "") {
        alert("An Emergency contact Name Must be added.");
        return;
      }
      const subProf = {
        firstName: firstNameProf.value,
        lastName: lastNameProf.value,
        email: emailProf.value,
        phoneNum: phoneProf.value,
        gradYear: gradYearProf.value,
        genderFocus: genderProf.value,
        state: stateProf.value,
        city: cityProf.value,
        position: positionProf.value,
        tshirtSize: tshirtSizeProf.value,
        shortSize: shortSizeProf.value,
        schoolName: schoolProf.value,
        clubName: clubProf.value,
        instaHandle: instaProf.value,
        highlightVideoLink: highlightVideoProf.value,
        emergencyName: emergencyName.value,
        emergencyPhone: emergencyPhone.value,
        accountLevelEmail: userData.value.email,
        accountLevelUID: userData.value.id,
      };
      isLoading.value = isPending2.value;
      await addDoc(userData.value.id, subProf);
      isLoading.value = isPending2.value;
      if (!error2.value) {
        window.location.reload();
        alert("User Registration Profile Has Been Added!");
      } else {
        isLoading.value = isPending2.value;
        console.log(error2.value);
      }
      isLoading.value = isPending2.value;
    };

    watchEffect(() => {
      if (userData.value != null) {
        const coll = "fclUsers/" + userData.value.id + "/registrationProfiles";
        const { posts, error, load } = getData(coll);
        load().then(() => {
          console.log(error.value);
          console.log(posts.value);
          regProfs.value = posts.value;
        });
      }
    });

    return {
      error,
      document,
      eventID,
      isLoading,
      payCheckout,
      regProfs,
      chosenProfile,
      userData,
      firstNameProf,
      lastNameProf,
      emailProf,
      phoneProf,
      gradYearProf,
      genderProf,
      stateProf,
      tshirtSizeProf,
      shortSizeProf,
      cityProf,
      positionProf,
      schoolProf,
      clubProf,
      instaProf,
      highlightVideoProf,
      addSubProfile,
      emergencyName,
      emergencyPhone,
    };
  },
};
</script>

<style scoped>
.player-text {
  max-width: 1000px;
  margin: 0 auto;
}

.player-text h5 {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.player-text h3 {
  font-size: 64px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #f31b1b;
  margin: 0 0 8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.player-text p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin: 0 0 20px;
  position: relative;
}

.clearfix {
  clear: both;
}

.plan-box-outer {
  width: 100%;
  float: left;
  background: #fff;
  display: flex;
  margin: 10px 0;
}

.plan-left-title {
  width: 260px;
  background: rgba(0, 0, 0, 1);
  padding: 20px 10px;
  display: flex;
  flex-flow: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.plan-left-title h4 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 2px;
  position: relative;
  z-index: 9;
  word-break: break-all;
}

.plan-right-disciption {
  width: 100%;
  display: flex;
}

.plan-detail {
  padding: 15px 15px;
}

.plan-detail-top p {
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.plan-detail-top {
  width: 100%;
  float: left;
  margin: 0 0 18px;
}

.plan-timing-list {
  width: 100%;
  float: left;
}

.plan-timing-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.plan-timing-list ul li {
  list-style: none;
  margin: 0;
  padding: 10px 25px 10px 0;
  width: 50%;
  text-align: left;
}

.plan-timing-list ul li .playermiddle-text {
  width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  text-align: left;
  padding: 0px 5px 0px 37px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.plan-timing-list ul li .playermiddle-text span {
  position: absolute;
  font-size: 17px;
  top: -1px;
  left: 12px;
  color: #000000;
}

.light-grey {
  background: #fafafa;
}

.left-search-sec {
  width: 100%;
  background: #fff;
  margin: 10px 0 0;
  height: 98%;
  padding: 20px 15px;
}

.search_bar-players {
  width: 100%;
  float: left;
  position: relative;
}

.search_bar-players .form-control {
  border: 1px solid #c4c4c4;
  background: #fafafa;
  border-radius: 0;
  padding: 9px 14px;
  font-size: 13px;
}

.search_bar-players .btn {
  position: absolute;
  right: 0;
  top: 0;
  background: #f31b1b;
  border-radius: 0;
  color: #fff;
}

.filter-title {
  width: 100%;
  float: left;
  text-align: left;
  margin: 30px 0 5px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 0 10px;
}

.filter-sub-title {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin: 18px 0 10px;
  width: 100%;
  float: left;
}

.filter-search-list {
  width: 100%;
  float: left;
}

.filter-search-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.filter-search-list ul li {
  list-style: none;
  margin: 0;
  padding: 0 0 8px 0;
  width: 100%;
  float: left;
}

.filter-search-list ul li .checkbox {
  width: 100%;
  float: left;
  text-align: left;
}

.filter-search-list ul li label {
  position: relative;
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  padding: 0 0 0 20px;
}

.filter-search-list ul li .checkbox input {
  margin: 0;
  position: absolute;
  left: 0;
  top: 3.8px;
}

.event-detail-sec {
  width: 100%;
  background: #fff;
  padding: 15px;
  float: left;
  margin: 10px 0 0;
}

.event-user-img {
  width: 100%;
  float: left;
  margin: 0 0 10px;
  max-height: 280px;
  overflow: hidden;
}

.event-user-img img {
  width: 100%;
}

.event-name-title {
  width: 100%;
  text-align: left;
  margin: 5px 0 5px;
  float: left;
}

.event-name-title h3 {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 3px 0 5px;
  position: relative;
  z-index: 9;
  word-break: break-all;
  text-transform: uppercase;
}

.event-name-title p {
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.price {
  margin: 12px 0 16px;
  float: left;
  width: 100%;
  text-align: left;
}

.price b {
  color: #000000;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 100%;
  float: left;
  font-family: "Montserrat", sans-serif;
}

.price p {
  margin: 0px 0 3px;
  float: left;
  width: 100%;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
}

.pricein {
  margin: 0px 0 0px 3px;
  padding-left: 25px;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 17px;
  font-weight: 700;
  display: inline-block;
  position: relative;
  top: 0;
}

.price sub {
  font-weight: 400;
  font-size: 10px;
  letter-spacing: 0px;
  color: #000;
  bottom: 2px;
  margin: 0;
}

.gwt-loc {
  margin: 25px 0px 20px;
  float: left;
  width: 100%;
  text-align: left;
}

.gwt-loc h5 {
  color: #000000;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 1px;
  width: 100%;
  float: left;
  font-family: "Montserrat", sans-serif;
}

.gwt-loc p {
  margin: 0px 0 3px;
  float: left;
  width: 100%;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
}

.gwt-loc ul {
  width: 100%;
  float: left;
  margin: 4px 0 5px;
  padding: 0 0 0 25px;
}

.gwt-loc ul li {
  margin: 0px 0 3px;
  float: left;
  width: 100%;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #000;
  list-style: decimal;
}

.register-form {
  width: 100%;
  margin: 25px 0 0px;
  float: left;
  background: #fafafa;
  padding: 10px;
}

.register-form h3 {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 23px 0 38px;
  text-transform: uppercase;
}

.register-form-in .form-container {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
}

.register-form-in .form-container.col-3 .form-group {
  max-width: 33.33%;
  width: 33.33%;
}

.register-form-in .form-group {
  max-width: 50%;
  width: 50%;
  padding: 0 10px;
  margin-bottom: 15px;
}

.register-form-in .form-group label {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 2px;
  font-family: "Montserrat", sans-serif;
}

.register-form-in .form-group .form-control {
  border-radius: 0;
  height: 40px;
  font-size: 13px;
  color: #8e8e8e;
  font-family: "Montserrat", sans-serif;
}

.register-form-in .form-group .form-control {
  border-radius: 0;
  height: 40px;
  font-size: 13px;
  color: #8e8e8e;
  font-family: "Montserrat", sans-serif;
}

.register-form-in .form-group.textareafield {
  max-width: 100%;
  width: 100%;
}

.register-form-in .form-group.textareafield .form-control {
  min-height: 110px;
}

.submit-btn {
  text-align: left;
  margin: 14px 0 0;
}

.submit-btn .btn {
  padding: 8px 0;
  color: #e60909;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background-color: rgba(196, 181, 131, 0);
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-radius: 100px 100px 100px 100px;
  border-color: #e60909;
  text-align: center;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 155px;
  letter-spacing: 2px;
}

.submit-btn .btn:hover {
  border-color: #e60909;
  color: #ffffff;
  background-color: #e60909;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.aboutsec-bottom {
  width: 100%;
  float: left;
  display: flex;
  margin: 10px 0 10px;
  background: #23252a;
  padding: 15px;
}

.about-img-left {
  width: 49%;
  padding: 0 20px 0 0;
}

.about-img-left img {
  max-width: 100%;
}

.about-text-right {
  width: 50%;
  padding: 0 0 0 0;
  text-align: center;
}

.about-text-right h5 {
  color: white;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: 1px;
  width: 100%;
  float: left;
  font-family: "Montserrat", sans-serif;
}

.about-text-right p {
  margin: 0px 0 12px;
  text-align: center;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: white;
}

@media (max-width: 1200px) {
  .plan-left-title h4 {
    font-size: 17px;
  }

  .player-pack-timing {
    margin: 0px 0 0 6px;
  }

  .register-slct-btn {
    min-width: 120px;
  }

  .plan-detail-top p {
    font-size: 12px;
    line-height: 15px;
  }

  .plan-timing-list ul li .playermiddle-text {
    font-size: 11px;
    line-height: 21px;
  }

  .plan-timing-list ul li {
    padding: 10px 8px 10px 0;
  }
}

@media (max-width: 992px) {
  .left-search-sec {
    height: auto;
    float: left;
  }
}

@media (max-width: 767px) {
  .plan-box-outer {
    display: inline-block;
  }

  .register-form-in .form-container .form-group,
  .register-form-in .form-container.col-3 .form-group {
    max-width: 100% !important;
    width: 100% !important;
  }

  .aboutsec-bottom {
    flex-wrap: wrap;
  }

  .about-img-left {
    width: 100%;
    padding: 0 0px 20px 0;
  }

  .about-text-right {
    width: 100%;
    padding: 0 0 0 0;
    text-align: left;
  }
}

.vimeoWrap2 {
  position: relative;
  padding-bottom: 56.25%;
}

.vimeoWrap2 iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@media (max-width: 450px) {
  .plan-timing-list ul li {
    width: 100%;
  }

  .light-grey {
    background: transparent;
  }

  .plan-timing-list ul li:nth-child(2n + 1) {
    background: #fafafa;
  }
}
</style>