import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getBicRegList = (id) => {
  const RegDoc = ref([]);
  const errorPM = ref(null);

  const loadPM = async () => {
    try {
      const res = await projectFirestore
        .collection("bic2022roster")
        .doc("4LGLk3M7QddTSTHE9HNj")
        .get();
      console.log(res);
      console.log(res.data());
      console.log(res.data().emails);
      RegDoc.value = { ...res.data(), id: res.id };
    } catch (err) {
      console.log(err.message);
      errorPM.value = err.message;
    }
  };

  return { RegDoc, errorPM, loadPM };
};

export default getBicRegList;
