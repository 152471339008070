<template>
  <div v-if="!isPremium">
    <sectionTitle />
    <whyFCL />
    <freeTrial />
    <pricing />
  </div>

  <!-- Paid Subscriber Begin -->
  <div v-if="isPremium && videosLoaded">
    <div class="col-12 mb-4">
      <eventSectionTitle :title="'FCL ' + curPosition + ' ' + curVidType" />
      <br />
      <!-- event details start -->
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="row mb-4">
            <!-- Left Hand Column -->
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4">
                <label for="seriesName">Video Name:</label>
                <input
                  v-model="searchTerm"
                  type="text"
                  class="form-control"
                  id="videoName"
                />
              </div>
            </div>
            <!-- Center  Column -->
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4">
                <label class="my-1 mr-2" for="eventCoaches">Gender Focus</label>
                <select
                  class="form-select"
                  id="eventCoaches"
                  aria-label="Default select example"
                  v-model="genderChosen"
                >
                  <option value="boys">Boys</option>
                  <option value="girls">Girls</option>
                  <option value="Either">Either</option>
                </select>
              </div>
            </div>
            <!-- Right Hand Column -->
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4">
                <label class="my-1 mr-2" for="eventCoaches">Difficulty</label>
                <select
                  class="form-select"
                  id="eventCoaches"
                  aria-label="Default select example"
                  v-model="dificultyChosen"
                >
                  <option value="all">All</option>
                  <!-- <option value="advanced">Advanced Only</option> -->
                  <option value="beginner">Beginner</option>
                </select>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12">
              <label class="my-1 mr-2">Select Tags:</label> <br />

              <span
                @click="addTagList(t)"
                v-for="t in tagList"
                :key="t"
                v-bind:class="{
                  'bg-primary': !selectedTagList.includes(t),
                  'bg-secondaryfcl': selectedTagList.includes(t),
                }"
                class="badge bg-primary badgeClick"
                >{{ t }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive py-4">
      <div class="row pb-5 mb-4 fourCoachdiv">
        <div
          class="col-lg-3 col-md-6 mb-4 mb-lg-0 padBot"
          v-for="video in vidsReturn"
          :key="video.id"
        >
          <!-- Card-->
          <div class="card shadow-sm border-0 rounded">
            <div class="card-body kbc2 p-0">
              <div class="p-4">
                <h5 class="mb-0">
                  <span
                    v-if="video.new == true"
                    style="margin-right: 5px"
                    class="badge badge-md bg-secondary ml-1 text-dark"
                  >
                    New!
                  </span>
                  <router-link
                    :to="'/videos/series/' + video.id"
                    tag="h5"
                    class="mb-0"
                  >
                    <i class="far fa-play-circle"></i> {{ video.seriesName }}
                  </router-link>
                </h5>

                <p class="small text-muted">
                  Videos:
                  <strong>{{ video.videoNames.length }}</strong>
                </p>

                <ul class="social mb-0 list-inline mt-3">
                  <li class="list-inline-item m-0"></li>
                </ul>
                <router-link
                  :to="'/videos/series/' + video.id"
                  tag="button"
                  class="btn-primary btn btnPlayBot"
                >
                  <i class="far fa-play-circle"></i> Watch Series
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getPosts from "../../composables/getData";
import { useRoute } from "vue-router";
import { ref } from "@vue/reactivity";
import { computed, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import pricing from "../../components/pricing";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import checkIfPremium from "../../composables/checkPrem";
import getSubs from "../../composables/getSubscrips";
import eventSectionTitle from "../../components/eventSectionTitle";
import freeTrial from "../../components/freeTrialOffer.vue";
export default {
  components: { pricing, sectionTitle, whyFCL, eventSectionTitle, freeTrial },
  setup() {
    const store = useStore();
    const route = useRoute();

    //Get Videos
    const videosLoaded = computed(() => store.state.videoDataLoaded);
    console.log(videosLoaded.value);
    const videoLoad = computed(() => store.getters.getAllVideos);
    console.log(store.state.videoData);
    //filtering parameters
    const genderChosen = ref("boys");
    const searchTerm = ref("");
    const dificultyChosen = ref("all");
    const selectedTagList = ref([]);
    const userData = computed(() => store.state.userData);
    const userSubData = computed(() => store.state.subscriptions);
    //Check for Premium Sub
    const isPremium = computed(() => {
      return store.getters.getIsPremium;
    });
    //Pretty Word Translations
    const positionDict = {
      offense: "Offensive",
      defense: "Defensive",
      faceoff: "Face Off",
      mobility: "Mobility",
      journey: "Journey",
    };
    const vidTypeDict = {
      trainingvideos: "Training Videos",
      instructionvideos: "Instructional Videos",
      filmbreakdowns: "Film Breakdowns Videos",
      webinars: "Webinars",
    };

    const positionDict2 = {
      offense: "Offense",
      defense: "Defense",
      faceoff: "Faceoff",
      mobility: "Mobility",
      journey: "Journey",
    };
    const vidTypeDict2 = {
      trainingvideos: "Training",
      instructionvideos: "Instructional",
      filmbreakdowns: "Film Breakdown",
      webinars: "Webinar",
    };

    const vidsReturn = computed(() => {
      if (!videosLoaded.value) {
        console.log("Videos Not Loaded");
        return [];
      } else {
        console.log(videoLoad.value);
        console.log("Videos Have Loaded");
        let temp = [];
        let i;
        let j;
        for (i = 0; i < videoLoad.value.length; i++) {
          if (videoLoad.value[i].categories != undefined) {
            if (
              videoLoad.value[i].categories.includes(
                vidTypeDict2[route.params.videoTopic]
              ) &&
              videoLoad.value[i].categories.includes(
                positionDict2[route.params.position]
              )
            ) {
              temp.push(videoLoad.value[i]);
            }
          } else {
            console.log(videoLoad.value[i]);
          }
        }

        temp = temp.filter(function (series) {
          if (genderChosen.value == "boys") {
            if (
              series.genderFocus.includes("Men's") ||
              series.genderFocus.includes("Both")
            ) {
              return true;
            }
          }
          if (genderChosen.value == "girls") {
            if (
              series.genderFocus.includes("Women's") ||
              series.genderFocus.includes("Both")
            ) {
              return true;
            }
          }
          if (genderChosen.value == "Either") {
            return true;
          }
        });
        temp = temp.filter(function (series) {
          if (dificultyChosen.value == "beginner") {
            if (series.beginnerOnly == true) {
              return true;
            }
          }

          if (
            dificultyChosen.value == "all" ||
            dificultyChosen.value == "advanced"
          ) {
            return true;
          }
        });
        if (selectedTagList.value.length > 0) {
          temp = temp.filter(function (series) {
            let k;
            for (k = 0; k < series.tags.length; k++) {
              if (selectedTagList.value.includes(series.tags[k])) {
                return true;
              }
            }
            return false;
          });
        }
        if (searchTerm.value != "") {
          temp = temp.filter(function (series) {
            if (
              series.seriesName
                .toLowerCase()
                .includes(searchTerm.value.toLowerCase())
            ) {
              return true;
            }
          });
        }
        const final = [];
        const dated = [];
        //sort videos into dated and not dated
        temp.forEach((video) => {
          if (typeof video.dateOrder == "undefined") {
            video.new = false;
            final.push(video);
          } else {
            dated.push(video);
          }
          console.log(dated);
        });
        //sort dated videos
        dated.sort(function (a, b) {
          return b.dateOrder - a.dateOrder;
        });
        var fortnightAway = new Date(Date.now() - 12096e5);
        console.log(fortnightAway);
        for (let j = 0; j < dated.length; j++) {
          console.log(dated[j].dateOrder.toDate());
          if (dated[j].dateOrder.toDate() > fortnightAway) {
            dated[j].new = true;
          } else {
            dated[j].new = false;
          }
        }
        return dated.concat(final);
      }
    });
    const tagList = computed(() => {
      const tempp = [];
      let v;
      let b;
      for (v = 0; v < vidsReturn.value.length; v++) {
        for (b = 0; b < vidsReturn.value[v].tags.length; b++) {
          if (!tempp.includes(vidsReturn.value[v].tags[b])) {
            tempp.push(vidsReturn.value[v].tags[b]);
          }
        }
      }
      return tempp.sort();
    });

    // Return the current page names and position
    const curVidType = computed(() => {
      return vidTypeDict[route.params.videoTopic];
    });
    const curPosition = computed(() => {
      console.log(route.params.position);
      return positionDict[route.params.position];
    });

    const addTagList = (tag) => {
      //check if tag is already selected
      if (selectedTagList.value.includes(tag)) {
        selectedTagList.value.indexOf(tag) > -1
          ? selectedTagList.value.splice(selectedTagList.value.indexOf(tag), 1)
          : false;
      } else {
        selectedTagList.value.push(tag);
      }
    };

    //watch for page changes and reset the selection variables

    watch(curVidType, () => {
      searchTerm.value = "";
      selectedTagList.value = [];
    });
    watch(curPosition, () => {
      searchTerm.value = "";
      selectedTagList.value = [];
    });

    //searchTerm
    return {
      curPosition,
      curVidType,
      vidsReturn,
      genderChosen,
      searchTerm,
      dificultyChosen,
      addTagList,
      tagList,
      selectedTagList,
      isPremium,
      videosLoaded,
    };
  },
};
</script>

<style>
.padBot {
  padding-bottom: 15px;
}
.kbc2 {
  min-height: 250px;
}

.badgeClick {
  margin-left: 5px;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 12px !important;
}

.btnPlayBot {
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 20px;
  right: 0;
}
</style>