<template>
      <br><br><br><br><br>About FCL Online<br><br><br><br>
      <p>{{curRoute}}</p>

</template>

<script>
import { ref } from '@vue/reactivity'
import {useRouter} from 'vue-router'
export default {
     

}
</script>

<style>

</style>