import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getPosts8 = (k) => {
  const posts8 = ref([]);
  const error8 = ref(null);

  const load8 = async () => {
    try {
      let res = await projectFirestore.collection(k).get();

      posts8.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      console.log(err.message);
      error8.value = err.message;
    }
  };
  console.log(posts8.value);
  return { posts8, error8, load8 };
};

export default getPosts8;
