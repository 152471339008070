import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

const useCollection = (collection) => {
  const error2 = ref(null);
  const isPending = ref(false);

  // add a new document
  const addDoc = async (doc) => {
    error2.value = null;
    isPending.value = true;
    try {
      await projectFirestore.collection(collection).add(doc);

      console.log("Ran use collection");
      isPending.value = false;
    } catch (err) {
      console.log(err.message);
      error2.value = "could not send the message";
      isPending.value = false;
    }
  };

  return { error2, addDoc, isPending };
};

export default useCollection;
