<template>
  <div v-if="pageLoaded">
    <eventSectionTitle :title="courseTitle" :description="courseDescription" />
    <div class="col-lg-10 workout__section">
      <div class="event-detail-sec">
        <div class="player-text">
          <h3>{{ courseData[currentSection].sectionTitle }}</h3>
        </div>
        <br />
        <div class="workout__person__details">
          <p>
            <button class="btn register-slct-btn" @click="changeSection('pre')">
              <b> <i class="fas fa-arrow-alt-circle-left"></i></b> &nbsp;
              Previous
            </button>
          </p>

          <p>
            <button
              class="btn register-slct-btn"
              @click="changeSection('next')"
            >
              Next
              <b> &nbsp;<i class="fas fa-arrow-alt-circle-right"></i></b>
            </button>
          </p>
          <p>Go To Section:</p>
          <p>
            <select
              class="form-select mb-0"
              id="gender"
              v-model="currentSection"
            >
              <option v-for="(sec, index) in courseData" :value="index">
                {{ sec.sectionTitle }}
              </option>
            </select>
          </p>
        </div>
        <br />
        <br />
        <br />

        <br />

        <div class="progress progress-lg rounded mb-0">
          <div
            class="progress-bar bg-success rounded"
            role="progressbar"
            :aria-valuenow="getProgressBar()"
            aria-valuemin="0"
            aria-valuemax="100"
            :style="'width:' + getProgressBar() + '%'"
          ></div>
        </div>

        <div class="workout__track__area">
          <div class="panel-group" id="accordionExample">
            <!-- Day 1 Start -->
            <div class="panel panel-default">
              <div class="workout__track__inner">
                <div
                  v-for="(lesson, index) in courseData[currentSection]
                    .lessonTitles"
                  :key="lesson"
                >
                  <div class="panel-heading">
                    <h3 class="panel-title">
                      <div class="calender__icon">
                        <h2 style="color: white">{{ index + 1 }}</h2>
                      </div>
                      <a
                        @click="showLesson(index)"
                        class="days__title"
                        style="word-break: keep-all !important"
                        data-toggle="collapse"
                        id="headingone"
                        >{{ lesson }}
                        <i
                          v-if="userCourseData[currentSection + 1][index]"
                          class="fa fa-check"
                        ></i
                      ></a>
                    </h3>
                    <div v-if="lessonShow == index">
                      <div
                        v-for="(content, i) in convertContent(
                          courseData[currentSection].lessons[index]
                            .lessonContent
                        )"
                        :key="i"
                      >
                        <div class="vimeoWrap" v-if="!isNaN(content)">
                          <iframe
                            :src="'https://player.vimeo.com/video/' + content"
                            width="640"
                            height="334"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                        </div>
                        <div v-if="isNaN(content)">
                          <span v-html="content"></span>
                        </div>

                        <br />
                        <br />
                      </div>
                      <div
                        class="workout__done__btn"
                        v-if="!userCourseData[currentSection + 1][index]"
                      >
                        <input
                          @click="
                            completeLesson(currentSection + 1, index, true)
                          "
                          value="Mark Complete"
                          class="btn"
                        />
                      </div>
                      <div
                        class="workout__done__btn"
                        v-if="userCourseData[currentSection + 1][index]"
                      >
                        <input
                          @click="
                            completeLesson(currentSection + 1, index, false)
                          "
                          value="Mark Incomplete"
                          class="btn"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  id="collapse1"
                  :class="['panel-collapse', 'in', 'collapse']"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="panel-body">
                    <div
                      class="workout__exercises"
                      v-for="(workout, index) in courseData[currentSection]
                        .lessonTitles"
                      :key="workout"
                    >
                      <!-- Exercise Titles -->
                      <div
                        class="exercise__title__in"
                        @click="curExerciseOpen = curWorkout.day1[index].id"
                      >
                        {{ workout }}
                        <i
                          class="fa fa-check"
                          v-if="
                            userCourseData[currentSection + 1][index] == true
                          "
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ref } from "@vue/reactivity";
import { computed, watchEffect } from "@vue/runtime-core";
import loadingSpinner from "../../components/loadingSpinner.vue";
import freeTrial from "../../components/freeTrialOffer.vue";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import pricing from "../../components/pricing";
import eventSectionTitle from "../../components/eventSectionTitle";
import updateCoursesViews from "../../composables/updateCourses";

export default {
  components: {
    pricing,
    sectionTitle,
    whyFCL,
    freeTrial,
    loadingSpinner,
    eventSectionTitle,
  },
  setup() {
    const userData = computed(() => store.state.userData);
    const store = useStore();
    store.dispatch("acceptCourses");

    const route = useRoute();
    const courseID = route.params.id;
    const userCourseData = ref({});
    const courseData = ref({});
    const courseTitle = ref({});
    const courseDescription = ref({});
    const currentSection = ref(0);
    const pageLoaded = ref(false);
    const lessonShow = ref(0);
    const { errorUp, updateCD, isPending } = updateCoursesViews();
    const coursesLoaded = computed(
      () => store.state.purchasedCoursesDataLoaded
    );
    const coursesUserData = computed(() => store.state.purchasedCoursesData);
    const getCurrentSection = () => {
      let testSec = 0;
      let temp = [];
      for (let i = 0; i < Object.keys(userCourseData.value).length; i++) {
        temp = JSON.parse(JSON.stringify(userCourseData.value[i + 1]));
        if (!temp.includes(false)) {
          if (i + 1 < Object.keys(userCourseData.value).length) {
            testSec = i;
          } else {
            testSec = Object.keys(userCourseData.value).length - 1;
          }
        } else {
          break;
        }
      }

      pageLoaded.value = true;
      console.log("Current Section: " + testSec);
      currentSection.value = testSec;
    };
    const checkOwner = () => {
      for (let i = 0; i < Object.keys(coursesUserData.value).length; i++) {
        if (coursesUserData.value[i].id == courseID) {
          console.log(coursesUserData.value[i].courseContent);
          courseData.value = coursesUserData.value[i].courseContent;
          userCourseData.value = coursesUserData.value[i].history;
          courseTitle.value = coursesUserData.value[i].courseName;
          courseDescription.value = coursesUserData.value[i].courseDescription;
          getCurrentSection();
        }
      }
      //   coursesUserData.value.forEach((course) => {
      //     if (course.id == courseID) {
      //       console.log(course.value);
      //     }
      //   });
    };
    watchEffect(() => {
      if (coursesLoaded.value) {
        checkOwner();
      }
    });

    const convertContent = (c) => {
      let pos = 0;
      const arrayReturn = [];
      let toSearch = c;
      let temp = "";
      while (pos != -1) {
        pos = toSearch.search("@vimeo=");
        if (pos != -1) {
          if (toSearch.slice(0, pos) != "") {
            arrayReturn.push(toSearch.slice(0, pos));
          }
          temp = toSearch.slice(pos + 8);
          console.log(temp);
          for (let i = 0; i < temp.length; i++) {
            if (temp[i] == '"') {
              arrayReturn.push(temp.slice(0, i));
              console.log(temp.slice(i + 1));
              toSearch = temp.slice(i + 1);
              break;
            }
          }
        } else {
          if (toSearch != "") {
            arrayReturn.push(toSearch);
          }
        }
      }
      if (arrayReturn[0] == " " || arrayReturn[0] == "") {
        arrayReturn.shift();
      }
      if (
        arrayReturn[arrayReturn.length - 1] == " " ||
        arrayReturn[arrayReturn.length - 1] == ""
      ) {
        arrayReturn.pop();
      }

      console.log(arrayReturn);
      return arrayReturn;
    };

    const changeSection = (p) => {
      if (p == "pre") {
        if (currentSection.value > 0) {
          showLesson(0);
          currentSection.value = currentSection.value - 1;
        }
      }
      if (p == "next") {
        if (currentSection.value < Object.keys(courseData.value).length) {
          showLesson(0);
          currentSection.value = currentSection.value + 1;
        }
      }
    };
    const showLesson = (p) => {
      lessonShow.value = p;
    };
    const getProgressBar = () => {
      let total = 0;
      let finished = 0;
      let temp = [];
      for (let i = 0; i < Object.keys(userCourseData.value).length; i++) {
        temp = JSON.parse(JSON.stringify(userCourseData.value[i + 1]));
        total = total + temp.length;
        temp.forEach((element) => {
          if (element) {
            finished = finished + 1;
          }
        });
      }
      console.log(total);
      return (finished / total) * 100;
    };

    const completeLesson = (s, l, status) => {
      userCourseData.value[s][l] = status;
      let temp = { history: userCourseData.value };
      updateCD(userData.value.id, courseID, temp).then(() => {
        if (errorUp.value != null) {
          alert(errorUp.value);
        }
      });
    };
    return {
      courseData,
      userCourseData,
      courseTitle,
      courseDescription,
      currentSection,
      pageLoaded,
      changeSection,
      lessonShow,
      showLesson,
      convertContent,
      getProgressBar,
      completeLesson,
    };
  },
};
</script>

<style scoped>
.player-section {
  min-height: 322px;
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.player-text {
  max-width: 1000px;
  margin: 0 auto;
}

.player-text h5 {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.player-text h3 {
  font-family: "hemi";
  font-size: 64px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #db3a34;
  margin: 0 0 8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.event-detail-sec {
  width: 100%;
  background: #fff;
  padding: 15px;
  float: left;
  margin: 10px 0 0;
}

.workout__section {
  margin: 0 auto;
  text-align: left;
  float: none;
}

.workout__section .event-detail-sec {
  padding-left: 70px;
  padding-right: 70px;
  min-height: 700px;
}

.progress .progress-bar-success {
  background-color: #326fb5;
}

.workout__section .progress {
  max-width: 95%;
  margin: 0px auto 28px !important;
}

.workout__track__area {
  width: 100%;
  float: left;
  padding: 0 0 0 30px;
  margin: 10px 0 0 0;
  position: relative;
}

.workout__track__area .panel {
  border: none;
  border-radius: 0;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  float: left;
  width: 100%;
}

.workout__track__inner .panel-heading {
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0 0 20px;
  float: right;
  width: 100%;
}

.workout__track__inner .panel-title {
  border-radius: 5px;
  background: #326fb5;
  padding: 10px 15px 10px 65px;
  position: relative;
}

.days__title {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  position: relative;
  z-index: 9;
  word-break: break-all;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none !important;
}
.days__title {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  position: relative;
  z-index: 9;
  word-break: break-all;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none !important;
}

.days__title:hover {
  color: #000;
}

.days__title .fa {
  color: #326fb5;
  font-size: 18px;
  margin: 0 0 0 11px;
  position: relative;
  top: -1px;
}

.workout__exercises {
  width: 50%;
  float: left;
  padding: 0;
  margin: 0;
}

.exercise__title__in {
  list-style: none;
  width: 100%;
  background: #fafafa;
  margin: 0 0 10px;
  text-align: left;
  padding: 5px 32px 5px 15px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
  border-right: 10px solid #fff;
  cursor: pointer;
}

.exercise__title__in:hover {
  background: #c1c1c1;
}

.exercise__title__in .fa {
  position: absolute;
  right: 10px;
  top: 6px;
  color: #326fb5;
  font-size: 18px;
}

.reps {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin: 17px 0 20px;
  text-align: left;
  float: left;
  width: 100%;
  display: inline !important;
}
.reps small {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  display: inline !important;
}

.reps .fa {
  font-size: 21px;
  position: relative;
  top: 2px;
  margin: 0 4px 0 0;
  color: #326fb5;
}

.exercise__video {
  max-width: 445px;
  overflow: hidden;
  float: left;
  max-height: 260px;
  margin: 0 0 30px;
}

.exercise__video {
  max-width: 445px;
  overflow: hidden;
  float: left;
  max-height: 260px;
  margin: 0 0 10px;
}

.workout__track__inner p {
  width: 100%;
  float: left;
  margin: 0 0 20px;
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
}

.workout__done__btn {
  float: center;
  text-align: center;
  width: 100%;
  margin: 10px 0 20px;
}

.workout__done__btn .btn {
  padding: 6px 30px !important;
  color: #e60909;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  background-color: rgba(196, 181, 131, 0);
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  border-radius: 100px 100px 100px 100px;
  border-color: #e60909;
  text-align: center;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 260px;
  letter-spacing: 1px;
}

.workout__done__btn .btn:hover {
  border-color: #e60909;
  color: #ffffff;
  background-color: #e60909;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.workout__track__inner .panel-body {
  border: none !important;
  padding: 0 0 30px;
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.workout__track__area .panel-group {
  width: 100%;
  float: left;
}

.workout__track__inner .panel-collapse {
  width: 100%;
  float: left;
}

.calender__icon {
  background: #db3a34;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  color: #fff;
  line-height: 39px;
  position: absolute;
  left: 10px;
  top: 6px;
}

.event-detail-sec .player-text h3 {
  text-align: center;
  font-size: 47px;
  margin: 20px 0 0px;
  display: inline-block;
  width: 100%;
  text-shadow: none;
}

.workout__person__details {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 31px;
}

.workout__person__details p {
  font-size: 16px;
  margin: 0 0 5px;
  font-weight: 500;
  width: 24%;
  text-align: center;
  padding: 0 5px;
}
.workout__person__details i {
}
.exercise__detail__inner {
  display: none;
}

.exercise__detail__inner.active {
  display: block;
}

@media (max-width: 990px) {
  .workout__section .event-detail-sec {
    padding-left: 40px;
    padding-right: 40px;
  }

  .player-text h3 {
    font-size: 54px;
  }

  .workout__exercises li {
    width: 100%;
    border-right: 0px solid #fff;
  }

  .workout__person__details p {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .workout__track__area {
    padding: 0 0 0 20px;
  }

  .workout__section .event-detail-sec {
    padding-left: 25px;
    padding-right: 25px;
  }

  .days__title {
    font-size: 21px;
  }

  .workout__track__inner .panel-title {
    padding: 5px 5px 5px 50px;
  }

  .calender__icon {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    left: 10px;
    top: 6px;
  }

  .workout__track__area {
    padding: 0 0 0 7px;
  }

  .workout__exercises {
    width: 100%;
  }

  .exercise__title__in {
    border-right: none;
  }

  .workout__person__details p {
    width: 100%;
    margin: 0 0 2px;
    padding: 0;
    font-size: 15px;
  }

  .event-detail-sec .player-text h3 {
    font-size: 34px;
  }
  .days__title span {
    float: right;
    position: relative;
    top: 6px;
  }

  @media (max-width: 767px) {
    .workout__person__details p {
      width: 50%;
      padding: 0 15px 0 0;
    }

    .days__title span {
      top: 4px;
    }
  }
}
</style>