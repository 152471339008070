<template>
  <!-- Section -->
  <section class="vh-lg-100 bg-image3 d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-center form-bg-image">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div
            class="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500"
          >
            <h1 class="h3">Forgot your password?</h1>
            <p class="mb-4">
              Just type in your email and we will send you a code to reset your
              password!
            </p>
            <div v-if="disIntMessage" class="alert alert-warning" role="alert">
              {{ intMessage }}
            </div>
            <form @submit.prevent="handleSubmit">
              <!-- Form -->
              <div class="mb-4">
                <label for="email">Your Email</label>
                <div class="input-group">
                  <input
                    type="email"
                    v-model="email"
                    class="form-control"
                    id="email"
                    required
                    autofocus
                  />
                </div>
              </div>
              <!-- End of Form -->
              <button type="submit" class="btn btn-block btn-primary">
                Recover password
              </button>
            </form>
            <div class="d-flex justify-content-center align-items-center mt-4">
              <span class="font-weight-normal">
                Go back to the
                <router-link to="/login" class="font-weight-bold"
                  >login page</router-link
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
import { projectAuth } from "../../firebase/config";
export default {
  setup() {
    const email = ref("");
    const disIntMessage = ref(false);
    let intMessage = ref("");
    const handleSubmit = async () => {
      disIntMessage.value = false;
      await projectAuth
        .sendPasswordResetEmail(email.value)
        .then(function () {
          disIntMessage.value = true;
          intMessage.value = "Password reset email sent to " + email.value;
          console.log("Password Email Sent");
        })
        .catch(function (err) {
          disIntMessage.value = true;
          intMessage.value = "Error sending password reset email";
        });
    };
    return { email, disIntMessage, intMessage, handleSubmit };
  },
};
</script>

<style>
</style>