<template>
  <section class="banner-section">
    <div class="banenr-container">
      <div class="baner-text">
        <h1>Welcome to FCL Online</h1>
        <p>
          We are excited to have launched our new
          <span> FCL Online Platform.</span> If you logged in over the past few
          weeks, you got a sneak peek for free. Now that we're up and running,
          please sign up today and start improving your game!
        </p>
        <p>
          FCL Online is your one-stop resource for every aspect of lacrosse
          improvement. With over 100 videos breaking down techniques, schemes,
          concepts and drills in lacrosse, FCL Online is perfect for anyone in
          the lacrosse world - education for beginners through experts
        </p>
      </div>
    </div>
  </section>
  <div class="clearfix"></div>
</template>

<script>
export default {};
</script>

<style>
.banner-section {
  min-height: 322px;
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../assets/img/cbb.jpg");
}

.banenr-container {
  max-width: 1050px;
  margin: 0 auto;
}

.baner-text h1 {
  font-family: "hemi";
  font-size: 78px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #ffffff;
  margin-bottom: 20px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.baner-text p {
  font-family: "redwingMedium";
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.baner-text p span {
  color: #f31b1b;
  font-weight: 800;
}

.clearfix {
  clear: both;
}

@media (max-width: 600px) {
  .baner-text h1 {
    font-size: 44px;
  }

  .baner-text p {
    font-size: 17px;
    line-height: 26px;
  }
}
</style>