<template>
  <eventSectionTitle title="Welcome to the new home of FCL Online." />
  <br />

  <div class="vimeoWrap">
    <iframe
      :src="'https://player.vimeo.com/video/577631293'"
      width="640"
      height="334"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
  <br />
  <div class="alert alert-info" role="alert">
    <p>
      Since Day 1, we've been diligently adding to our platform every week. We
      love getting questions and feedback from the FCL community and turning
      that into instructional content. It's our promise to the FCL family that
      we will continue rolling out new videos and new features constantly. You
      asked for a workouts feature and we've addressed that in a BIG way:
    </p>
    <p>
      <strong
        >NEW FEATURE: Personal Online Training (Weekly workouts curated by
        Deemer Class and Matt Dunn). The workout portal will be updated weekly
        and is built perfectly with mobile in mind so that you can access it out
        on the field and track your progress! Your guided lacrosse journey
        begins here.</strong
      >
    </p>
    <p>
      Each week you will get access to the same 3 day lacrosse workouts that
      Deemer and Matt use each week to prepare for professional-level lacrosse.
      You can now train like the best players in the world and set yourself on a
      path to become one of them. Start working like the best and track your
      progress all on FCL Online
    </p>
  </div>
</template>

<script>
import eventSectionTitle from "../components/eventSectionTitle";

export default {
  components: { eventSectionTitle },
  setup() {},
};
</script>

<style>
.routerUnder {
  text-decoration: underline;
}
</style>