import { ref } from "vue";
import { projectFirestore } from "../firebase/config";

const updateWorkoutViews = () => {
  const errorUp = ref(null);
  const isPending = ref(false);

  // add a new document
  const updateWD = async (userID, workoutId, doc) => {
    errorUp.value = null;
    isPending.value = true;
    try {
      await projectFirestore
        .collection("fclUsers")
        .doc(userID)
        .collection("workoutData")
        .doc(workoutId)
        .set(doc);
      isPending.value = false;
    } catch (err) {
      console.log(err.message);
      errorUp.value = "could not save workout progress:" + err.message;
      isPending.value = false;
    }
  };

  return { errorUp, updateWD, isPending };
};

export default updateWorkoutViews;
