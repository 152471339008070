<template>
  <div class="plan-box-outer">
    <div class="plan-left-title">
      <h4>{{ eventTitle }}</h4>
    </div>
    <div class="plan-right-disciption">
      <div class="plan-detail">
        <div class="plan-detail-top">
          <p>
            {{ description }}
          </p>
        </div>
        <div class="plan-timing-list">
          <ul>
            <li class="light-grey">
              <p class="playermiddle-text">
                <span><i class="far fa-calendar"></i></span> {{ startDate }}
              </p>
            </li>
            <li class="light-grey">
              <p class="playermiddle-text">
                <span><i class="far fa-clock"></i></span> {{ time }}
              </p>
            </li>
            <li>
              <p class="playermiddle-text">
                <span><i class="fas fa-venus-mars"></i></span>
                {{ gender }} Clinic
              </p>
            </li>
            <li>
              <p class="playermiddle-text">
                <span><i class="far fa-dot-circle"></i></span> Position:
                {{ position }}
              </p>
            </li>
            <li class="light-grey">
              <p class="playermiddle-text">
                <span><i class="far fa-compass"></i></span>
                {{ address }}
              </p>
            </li>
            <li class="light-grey">
              <p class="playermiddle-text">
                <span><i class="fas fa-bullhorn"></i></span>
              </p>
              <p
                class="playermiddle-text"
                v-for="coach in coaches"
                :key="coach.name"
                style="display: inline"
              >
                {{ coach }}
              </p>
            </li>
            <li>
              <!-- <p class="playermiddle-text">
                <span><i class="fas fa-user-friends"></i></span> Ages:
              </p> -->
              &nbsp; &nbsp; &nbsp; <strong>Ages: </strong>
              <p v-for="age in ages" :key="age.name" class="inLine">
                {{ age }} &nbsp;
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="plan-price-btn">
        <div class="player-price-in">
          <p class="player-curency">$</p>
          <p class="player-pack-price">{{ price }}</p>
          <p class="player-pack-timing">PER PLAYER</p>
        </div>
        <div
          class="register-pack-btn"
          v-if="!currentRegister || eventPopCap > currentRegister"
        >
          <router-link :to="'/events/' + id" class="btn register-slct-btn"
            >Register Now</router-link
          >
        </div>
        <div class="register-pack-btn" v-if="eventPopCap <= currentRegister">
          <button disabled :to="'/events/' + id" class="btn register-slct-btn">
            Sold Out
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "eventTitle",
    "price",
    "description",
    "startDate",
    "endDate",
    "address",
    "coaches",
    "position",
    "gender",
    "time",
    "ages",
    "id",
    "eventPopCap",
    "currentRegister",
  ],
  setup() {},
};
</script>

<style scoped>
.player-section {
  min-height: 322px;
  text-align: center;
  float: left;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 30px 30px;
}

.player-text {
  max-width: 1000px;
  margin: 0 auto;
}

.player-text h5 {
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 20px;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 6px;
}

.player-text h3 {
  font-family: "Hemi Head Bold Italic";
  font-size: 64px;
  font-weight: 900;
  text-transform: uppercase;
  font-style: normal;
  line-height: 1em;
  color: #db3a34;
  margin: 0 0 8px;
  text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
}

.player-text p {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin: 0 0 20px;
  position: relative;
}

.clearfix {
  clear: both;
}

.plan-box-outer {
  width: 100%;
  float: left;
  background: #fff;
  display: flex;
  margin: 10px 0;
}

.plan-left-title {
  width: 260px;
  background: rgba(0, 0, 0, 1);
  padding: 20px 10px;
  display: flex;
  flex-flow: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/img/cbb.jpg");
}

.plan-left-title h4 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 2px;
  position: relative;
  z-index: 9;
  /* word-break: break-all !important; */
}

.plan-right-disciption {
  width: 100%;
  display: flex;
}

.plan-detail {
  padding: 15px 15px;
}

.plan-detail-top p {
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.plan-detail-top {
  width: 100%;
  float: left;
  margin: 0 0 18px;
}

.plan-timing-list {
  width: 100%;
  float: left;
}

.plan-timing-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.plan-timing-list ul li {
  list-style: none;
  margin: 0;
  padding: 10px 25px 10px 0;
  width: 50%;
  text-align: left;
}

.plan-timing-list ul li .playermiddle-text {
  width: 100%;
  float: left;
  margin: 0;
  list-style: none;
  text-align: left;
  padding: 0px 5px 0px 37px;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
}

.plan-timing-list ul li .playermiddle-text span {
  position: absolute;
  font-size: 17px;
  top: -1px;
  left: 12px;
  color: #000000;
}

.light-grey {
  background: #fafafa;
}

.plan-price-btn {
  background: #db3a34;
  margin: 0;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 3px 15px;
  text-align: unset;
  flex-flow: column;
  width: 360px;
}

.player-price-in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.player-curency {
  font-family: "Montserrat", sans-serif;
  align-self: flex-start;
  font-size: 17px;
  color: #f7f7f7;
  font-weight: 700;
  margin: 0;
}

.player-pack-price {
  font-family: "Montserrat", sans-serif;
  color: #f7f7f7;
  font-size: 44px;
  font-weight: 700;
  margin: 0;
  line-height: 40px;
  padding: 0 0 0 6px;
}

.player-pack-timing {
  color: #f7f7f7;
  font-family: "Montserrat", sans-serif;
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 22px 0 0 6px;
  padding: 0;
}

.register-pack-btn {
  width: 100%;
  float: left;
  padding: 25px 0 0;
}

.register-slct-btn {
  padding: 10px 15px !important;
  background-color: #326fb5 !important;
  color: #fff !important;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8rem !important;
  background-color: rgba(196, 181, 131, 0);

  border-radius: 100px 100px 100px 100px !important;
  text-align: center;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  min-width: 155px;
}

.register-slct-btn:hover {
  border-color: #fff !important;

  color: #ffffff !important;
  background-color: #db3a34 !important;
  transform: scale(1.1) !important;
  -webkit-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
}

.left-search-sec {
  width: 100%;
  background: #fff;
  margin: 10px 0 0;
  height: 98%;
  padding: 20px 15px;
}

.search_bar-players {
  width: 100%;
  float: left;
  position: relative;
}

.search_bar-players .form-control {
  border: 1px solid #c4c4c4;
  background: #fafafa;
  border-radius: 0;
  padding: 9px 14px;
  font-size: 13px;
}

.search_bar-players .btn {
  position: absolute;
  right: 0;
  top: 0;
  background: #db3a34;
  border-radius: 0;
  color: #fff;
}

.filter-title {
  width: 100%;
  float: left;
  text-align: left;
  margin: 30px 0 5px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 0 10px;
}

.filter-sub-title {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  margin: 18px 0 10px;
  width: 100%;
  float: left;
}

.filter-search-list {
  width: 100%;
  float: left;
}

.filter-search-list ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.filter-search-list ul li {
  list-style: none;
  margin: 0;
  padding: 0 0 8px 0;
  width: 100%;
  float: left;
}

.filter-search-list ul li .checkbox {
  width: 100%;
  float: left;
  text-align: left;
}

.filter-search-list ul li label {
  position: relative;
  width: 100%;
  float: left;
  margin: 0;
  text-align: left;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  padding: 0 0 0 20px;
}

.filter-search-list ul li .checkbox input {
  margin: 0;
  position: absolute;
  left: 0;
  top: 3.8px;
}

@media (max-width: 1200px) {
  .plan-left-title h4 {
    font-size: 17px;
  }

  .player-pack-timing {
    margin: 0px 0 0 6px;
  }

  .register-slct-btn {
    min-width: 120px;
  }

  .plan-detail-top p {
    font-size: 12px;
    line-height: 15px;
  }

  .plan-timing-list ul li .playermiddle-text {
    font-size: 11px;
    line-height: 21px;
  }

  .plan-timing-list ul li {
    padding: 10px 8px 10px 0;
  }
}

@media (max-width: 992px) {
  .left-search-sec {
    height: auto;
    float: left;
  }
}

@media (max-width: 767px) {
  .plan-box-outer {
    display: inline-block;
  }

  .plan-left-title {
    width: 100%;
  }

  .plan-right-disciption {
    width: 100%;
    display: inline-block;
  }

  .plan-price-btn {
    width: 100%;
    padding: 10px 10px;
  }

  .register-pack-btn {
    padding: 10px 0 0;
  }

  .register-slct-btn {
    min-width: 140px;
  }
}

.inLine {
  margin: 0;
  color: #000000;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
  position: relative;
  display: inline !important;
}

@media (max-width: 450px) {
  .plan-timing-list ul li {
    width: 100%;
  }

  .light-grey {
    background: transparent;
  }

  .plan-timing-list ul li:nth-child(2n + 1) {
    background: #fafafa;
  }
}
</style>