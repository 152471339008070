<template>

          <div id="app">

            <Ecwid :storeId="66725921" :catID="123641315" />
            
          </div>

 
</template>

<script>
import Ecwid from "../components/ecwid.vue";
export default {
  components: {
    Ecwid,
  },
};
</script>

<style>
</style>

