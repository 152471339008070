<template>
  <div id="hubspotForm" v-once></div>
</template>

<script>
export default {
  setup() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "9260438",
          formId: "b3a85f73-9c4b-4a59-bae8-882dc76c3263",
          target: "#hubspotForm",
        });
      }
    });
  },
};
</script>

<style>
</style>


