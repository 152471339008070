import { ref } from "@vue/reactivity";
import { projectFirestore } from "../firebase/config";

const getPosts = (k) => {
  const posts = ref([]);
  const error = ref(null);

  const load = async () => {
    try {
      let res = null;
      if (k == "workouts") {
        res = await projectFirestore
          .collection(k)
          .orderBy("dateOrder", "desc")
          .get();
      } else {
        res = await projectFirestore.collection(k).get();
      }

      posts.value = res.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
    } catch (err) {
      error.value = err.message;
    }
  };

  return { posts, error, load };
};

export default getPosts;
