<template>
  <eventSectionTitle
    title="Welcome to the New Home of FCL Live"
    description="FCL Live is where we will begin to host live conversations on a range of topics, diving deep into the nuances of certain areas of the game. These 30-60 minute live shows will feature presentations, film breakdowns and dialogue between the FCL Staff and some of the best lacrosse minds in the game. Please tune in and join the conversation with live chat and Q&A as we study some of the biggest keys of the game"
  />
  <div v-if="!isPremium">
    <freeTrial />
  </div>
  <div class="table-responsive py-4" v-if="eventsLoaded">
    <div class="row pb-5 mb-4 fourCoachdiv">
      <div
        class="col-lg-3 col-md-6 mb-4 mb-lg-0 padBot"
        v-for="event in eventLoad"
        :key="eventLoad.id"
      >
        <!-- Card-->
        <div class="card shadow-sm border-0 rounded">
          <div class="card-body kbc2 p-0">
            <div class="p-4">
              <h5 class="mb-0">
                <router-link
                  :to="'/fcllive/event/' + event.id"
                  tag="h5"
                  class="mb-0"
                >
                  {{ event.eventName }}
                </router-link>
              </h5>
              <p class="small text-muted">
                Tags:
                <span v-for="(tag, index) in event.tags" :key="tag">
                  {{ tag }}<span v-if="index < event.tags.length - 1">, </span>
                </span>
              </p>

              <p>{{ event.eventDescription }}</p>

              <div class="plan-right-disciption">
                <div class="plan-detail">
                  <div class="plan-detail-top">
                    <p>
                      {{ description }}
                    </p>
                  </div>
                  <div class="plan-timing-list">
                    <ul>
                      <li class="light-grey">
                        <p class="playermiddle-text">
                          <span><i class="far fa-calendar"></i></span>
                          {{ event.eventTime.toDate().getMonth() + 1 }} /
                          {{ event.eventTime.toDate().getDate() }} /
                          {{ event.eventTime.toDate().getUTCFullYear() }} at
                          {{ formatAMPM(event.eventTime.toDate()) }} EST
                        </p>
                      </li>
                      <li>
                        <p class="playermiddle-text">
                          <span><i class="far fa-clock"></i></span>
                          {{ event.length }} Minutes
                        </p>
                      </li>
                      <li class="light-grey">
                        <p class="playermiddle-text">
                          <span><i class="fas fa-bullhorn"></i></span>
                          {{ event.fclHost }}
                        </p>
                      </li>
                      <li>
                        <p class="playermiddle-text" v-if="!event.isPremium">
                          <span><i class="fas fa-dollar-sign"></i></span>

                          Free Access
                        </p>
                        <p class="playermiddle-text" v-if="event.isPremium">
                          <span
                            ><i
                              class="fas fa-dollar-sign"
                              style="color: red"
                            ></i
                          ></span>

                          FCL Online Subscribers Only
                        </p>
                      </li>

                      <li class="light-grey">
                        &nbsp; &nbsp; &nbsp; <strong>Guest Speakers: </strong>
                        <p
                          class="inLine"
                          v-if="event.guestSpeakers.length == 0"
                        >
                          None
                        </p>
                        <p
                          v-for="(coach, index) in event.guestSpeakers"
                          :key="coach"
                          class="inLine"
                        >
                          {{ coach }} &nbsp
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <ul class="social mb-0 list-inline mt-3">
                <li class="list-inline-item m-0"></li>
              </ul>
              <router-link
                :to="'/fcllive/event/' + event.id"
                tag="button"
                class="btn-primary btn btnPlayBot"
              >
                View Event
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Premium Walls
import freeTrial from "../../components/freeTrialOffer.vue";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import pricing from "../../components/pricing";
import eventSectionTitle from "../../components/eventSectionTitle";
import eventCard from "../../components/liveEventCard.vue";
import { computed, ref, watch, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
export default {
  components: {
    pricing,
    sectionTitle,
    whyFCL,
    freeTrial,
    eventSectionTitle,
    eventCard,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isPremium = computed(() => {
      return store.getters.getIsPremium;
    });

    const eventsLoaded = computed(() => store.state.fclLiveEventDataLoaded);
    const eventLoad = computed(() => store.state.fclLiveEventData);
    const formatAMPM = (date) => {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    };
    return { isPremium, eventsLoaded, eventLoad, formatAMPM };
  },
};
</script>

<style scoped>
.plan-timing-list ul li {
  width: 100%;
}
</style>