<template>
  <div v-if="!isPremium">
    <sectionTitle />
    <whyFCL />
    <freeTrial />
    <pricing />
  </div>

  <!-- Paid Subscriber Begin -->
  <div v-if="isPremium">
    <eventSectionTitle title="TRAIN With THE BEST IN THE WORLD" />
    <br />
    <!-- Paid Subscriber Begin -->
    <div class="col-12 mb-4">
      <!-- event details start -->
      <div class="card shadow-sm">
        <div class="card-body">
          <div class="row mb-4">
            <!-- Left Hand Column -->
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4">
                <label class="my-1 mr-2" for="workoutPosition">Position</label>
                <select
                  class="form-select"
                  id="workoutPosition"
                  aria-label="Default select example"
                  v-model="positionChosen"
                >
                  <option value="Both">Both</option>
                  <option value="Offense">Offense</option>
                  <!-- <option value="advanced">Advanced Only</option> -->
                  <option value="Defense">Defense</option>
                </select>
              </div>
              <div class="mb-4">
                <div class="form-check form-switch">
                  <label class="form-check-label" for="flexSwitchCheckChecked"
                    >Show Only Workouts I have Started</label
                  >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    v-model="showStartedOnly"
                  />
                  <br />
                  <label class="form-check-label" for="flexSwitchCheckChecked2"
                    >Don't Show Workouts I have completed</label
                  >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked2"
                    v-model="dontShowCompleted"
                    checked
                  />
                </div>
              </div>
            </div>
            <!-- Center  Column -->
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4">
                <label for="dateStart">Posted After:</label>

                <div class="input-group">
                  <input
                    data-datepicker=""
                    v-model="startDate"
                    class="form-control"
                    id="dateStart"
                    type="date"
                    placeholder="dd/mm/yyyy"
                  />
                </div>
              </div>
            </div>
            <!-- Right Hand Column -->
            <div class="col-lg-4 col-sm-6">
              <div class="mb-4">
                <label for="dateEnd">Posted Before:</label>

                <div class="input-group">
                  <input
                    data-datepicker=""
                    class="form-control"
                    id="dateEnd"
                    type="date"
                    placeholder="dd/mm/yyyy"
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive py-4" v-if="workoutsLoaded">
      <div class="row pb-5 mb-4 fourCoachdiv">
        <div
          class="col-lg-3 col-md-6 mb-4 mb-lg-0 padBot"
          v-for="workout in workoutsReturn"
          :key="workout.id"
        >
          <!-- Card-->
          <div class="card shadow-sm border-0 rounded">
            <div class="card-body kbc2 p-0">
              <div class="p-4">
                <h5 class="mb-0">
                  <router-link
                    :to="'/workouts/' + workout.id"
                    tag="h5"
                    class="mb-0"
                  >
                    <i class="fas fa-dumbbell"></i> {{ workout.workoutName }}
                  </router-link>
                </h5>
                <p class="small text-muted">
                  Position:
                  <span>{{ workout.workoutPosition }}</span>
                </p>
                <img
                  v-if="workout.workoutPosition == 'Defense'"
                  src="../../assets/img/workouts/defense/1.jpg"
                  alt="Matt Dunn FCL Defensive Cordinator"
                />
                <img
                  v-if="workout.workoutPosition == 'Offense'"
                  src="../../assets/img/workouts/offense/1.jpg"
                  alt="Matt Dunn FCL Defensive Cordinator"
                />
                <br />
                <br />
                <ul class="social mb-0 list-inline mt-3">
                  <li class="list-inline-item m-0"></li>
                </ul>
                <router-link
                  :to="'/workouts/' + workout.id"
                  tag="button"
                  class="btn-primary btn btnPlayBot"
                >
                  <i class="fas fa-dumbbell"></i> Begin Workout
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventSectionTitle from "../../components/eventSectionTitle";

//Premium Walls
import freeTrial from "../../components/freeTrialOffer.vue";
import sectionTitle from "../../components/paywallSectionTitle";
import whyFCL from "../../components/whyfclOnline.vue";
import pricing from "../../components/pricing";
import { computed, ref, watch, watchEffect } from "@vue/runtime-core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import checkIfPremium from "../../composables/checkPrem";

export default {
  components: { pricing, sectionTitle, whyFCL, eventSectionTitle, freeTrial },

  setup() {
    const store = useStore();
    const route = useRoute();
    const userData = computed(() => store.state.userData);
    const userSubData = computed(() => store.state.subscriptions);
    //get workout data

    const workoutsLoaded = computed(() => store.state.workoutDataLoaded);
    const workoutLoad = computed(() => store.getters.getAllWorkouts);
    //This Users Workout Data
    const userWDData = computed(() => {
      if (store.state.userWorkoutHistoryLoaded) {
        return store.state.userWorkoutHistory;
      } else {
        return null;
      }
    });

    //Filtering Variables
    const positionChosen = ref("Both");
    const startDate = ref("");
    const endDate = ref("");
    const showStartedOnly = ref(false);
    const dontShowCompleted = ref(true);
    const workoutsReturn = computed(() => {
      if (!workoutsLoaded.value) {
        return [];
      } else {
        let temp = [];
        //position filtering START
        if (positionChosen.value == "Both" || positionChosen.value == "") {
          temp = workoutLoad.value;
        } else {
          workoutLoad.value.forEach((workout) => {
            if (positionChosen.value == workout.workoutPosition) {
              temp.push(workout);
            }
          });
        }
        //position filtering END
        //Date filerteding START
        if (startDate.value != "") {
          temp = temp.filter(function (workout) {
            let postDate = new Date(workout.dateOrder.seconds * 1000); // date object

            if (postDate >= Date.parse(startDate.value)) {
              return true;
            } else {
              return false;
            }
          });
        }
        if (endDate.value != "") {
          temp = temp.filter(function (workout) {
            let postDate = new Date(workout.dateOrder.seconds * 1000); // date object

            if (postDate <= Date.parse(endDate.value)) {
              return true;
            } else {
              return false;
            }
          });
        }
        //Date filtering END

        //workout status START
        if (store.state.userWorkoutHistoryLoaded) {
          if (dontShowCompleted.value == true) {
            if (userWDData.value.length > 0) {
              temp = temp.filter(function (workout) {
                let found = false;
                userWDData.value.forEach((workout2) => {
                  if (workout.id == workout2.id) {
                    if (
                      !workout2.day1.includes(false) &&
                      !workout2.day2.includes(false) &&
                      !workout2.day3.includes(false)
                    ) {
                      found = true;
                    }
                  }
                });
                if (found) {
                  return false;
                } else {
                  return true;
                }
              });
            }
          }
          if (showStartedOnly.value == true) {
            if (userWDData.value.length > 0) {
              temp = temp.filter(function (workout) {
                let found = false;
                userWDData.value.forEach((workout2) => {
                  if (workout.id == workout2.id) {
                    found = true;
                  }
                });
                if (found) {
                  return true;
                } else {
                  return false;
                }
              });
            } else {
              temp = [];
            }
          }
        }
        return temp;
      }
    });

    //Check for Premium Sub
    const isPremium = computed(() => {
      return store.getters.getIsPremium;
    });

    return {
      isPremium,
      positionChosen,
      workoutsLoaded,
      workoutsReturn,
      startDate,
      endDate,
      dontShowCompleted,
      showStartedOnly,
    };
  },
};
</script>

<style>
</style>